import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import CustomCheckbox from "components/Checkbox";
import "./budgetCapModal.scss";
import CustomButton from "components/Button";
const BudgetCapModal = ({
  open,
  onClose,
  minimumBudgetValue,
  apiUrl,
  title,
}) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="dialogTitle">{title}</span>
        <span>
          {" "}
          <IconWrapper onClick={onClose} icon={close} />{" "}
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="budget-info">
          Minimum budget required: ${minimumBudgetValue}
        </div>
        <TextInput
          sx={{ width: "140px", marginBottom: "16px" }}
          placeholder="Enter value"
        />
        {/*<div className="checkboxContainer">*/}
        {/*  <CustomCheckbox title={"Selected Record"} />*/}
        {/*  <CustomCheckbox title={"All 8 records selected"} />*/}
        {/*</div>*/}
        <div className="budgetSubTitle">MONTHLY BUDGET</div>
        <div className="monthsGrid">
          {months.map((month) => {
            return (
              <div
                className="months"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "24px",
                }}
              >
                <div className="monthLabel">{month}</div>
                <TextInput sx={{ width: "70px" }} />
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "flex-start",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      >
        <CustomButton title="Apply" variant={"filled"} onClick={() => {}} />
        <CustomButton title="Cancel" variant={"outlined"} onClick={() => {}} />
      </DialogActions>
    </Dialog>
  );
};
export default BudgetCapModal;
