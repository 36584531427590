import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './GroupsHotelsList.scss';
import HotelsTable from '../Utils/Table/HotelsTable';
import ActionMenu from './ActionMenu';
import UngroupSelectionModal from '../Utils/Modal/ActionModal/UngroupSelectionModal';
import { exportToCsv } from '../Utils/CsvExport';
import { Button, CircularProgress } from '@mui/material';
import Modal from '../Utils/Modal/ActionModal/Modal';
import DeleteConfirmationModal from '../Utils/Modal/ActionModal/DeleteConfirmationModal';
import UploadCSVModal from "../Utils/Modal/UploadCSVModal/UploadCSVModal";

const GroupsHotelsList = ({
    selectedGroup,
    groupHotels,
    handleUngroupSelection,
    updateGroupName,
    loading,
    error,
    onConfirmDelete,
    deleteError,
    updateGroupError,
    onUploadCsv,
    csvError,
    csvLoading
}) => {
    const [filteredHotels, setFilteredHotels] = useState(groupHotels);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [showModal, setShowModal] = useState(false); // Modal for editing group name
    const [showUngroupModal, setShowUngroupModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
    const [groupName, setGroupName] = useState(selectedGroup?.name);

    useEffect(() => {
        setFilteredHotels(groupHotels);
        setGroupName(selectedGroup?.name);
        setAnchorEl(null);
        setSelectedHotels([]);
    }, [groupHotels, selectedGroup]);

    const handleGroupSettings = () => setShowModal(true);

    const handleCheckboxChange = (hotelId) => {
        setSelectedHotels((prevSelected) =>
            prevSelected.includes(hotelId)
                ? prevSelected.filter(id => id !== hotelId)
                : [...prevSelected, hotelId]
        );
    };

    const handleExportCsv = () => {
        const csvData = filteredHotels.map(hotel => [
            hotel.id,
            hotel.name,
            hotel.country,
            hotel.groupName || 'Ungrouped',
        ]);
        exportToCsv(`${selectedGroup?.name || 'group'}_hotels.csv`, [
            ['Hotel ID', 'Hotel Name', 'Country', 'Group Name'],
            ...csvData,
        ]);
    };

    const handleUngroupSelectionAction = () => setShowUngroupModal(true);

    const handleUngroupConfirm = async () => {
        await handleUngroupSelection(selectedHotels);
        setShowUngroupModal(false); // Explicitly close modal after success
    };

    const handleDeleteGroup = () => {
        setShowDeleteModal(true);  // Open delete modal
    };

    return (
        <div className="groups-hotels-list">
            <div className="header">
                <h3>
                    Currently Editing: <span>{selectedGroup?.name || 'No Group Selected'}</span>
                </h3>

                <div className="button-wrapper">
                    {/* Ungroup Selection Button - Placed Left of Action Menu */}
                    <Button
                        variant="outlined"
                        onClick={handleUngroupSelectionAction}
                        disabled={selectedHotels.length === 0} // Disable when no hotels selected
                        className={`${selectedHotels.length === 0 ? 'disabledUnGroupBtn' : 'activeUnGroupBtn'}`}
                    >
                        Remove From Group
                    </Button>

                    {selectedGroup && (
                        <IconButton aria-label="more" onClick={(event) => setAnchorEl(event.currentTarget)}>
                            <MoreVertIcon />
                        </IconButton>
                    )}
                </div>
            </div>


            <ActionMenu
                anchorEl={anchorEl}
                handleCloseMenu={() => setAnchorEl(null)}
                handleGroupSettings={handleGroupSettings}
                handleExportCsv={handleExportCsv}
                handleDeleteGroup={handleDeleteGroup}
                handleUploadCsv={() => setShowUploadCsvModal(true)}
            />

            {/* Hotels table */}
            <HotelsTable
                filteredHotels={filteredHotels}
                selectedHotels={selectedHotels}
                handleCheckboxChange={handleCheckboxChange}
                showGroupName={false}
                isThirdComponent={false}
            />

            {/* Ungroup Modal */}
            <UngroupSelectionModal
                open={showUngroupModal}
                onClose={() => setShowUngroupModal(false)}
                onConfirm={handleUngroupConfirm} // Close modal after confirming
                loading={loading}
                error={error}
            />

            <DeleteConfirmationModal
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)} // Close modal
                onConfirm={async () => {
                    const success = await onConfirmDelete(); // Call delete from parent
                    if (success) setShowDeleteModal(false); // Close on success
                }}
                groupName={selectedGroup?.name}
                loading={loading}
                error={deleteError} // Pass delete error
            />

            <UploadCSVModal
                open={showUploadCsvModal}
                onClose={() => setShowUploadCsvModal(false)}
                onUpload={onUploadCsv}
                loading={csvLoading}
                error={csvError}
            />

            {/* Edit Group Name Modal */}
            {showModal && (
                <Modal onClose={() => setShowModal(false)}>
                    <form className="group-form" onSubmit={async (e) => {
                        e.preventDefault();
                        const success = await updateGroupName(selectedGroup.id, groupName);

                        // If the update was successful, close the modal; otherwise, keep it open
                        if (success) {
                            setShowModal(false);
                        }
                    }}>
                        <h3>Edit Group Name</h3>
                        <label htmlFor="groupName">Group Name</label>
                        <input
                            type="text"
                            id="groupName"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                        />

                        {/* Display the error if there is one */}
                        {updateGroupError && <p style={{ color: 'red' }}>{updateGroupError}</p>}

                        <div className="modal-actions">
                            {/* Show loader while waiting for the update */}
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <>
                                    <Button variant="outlined" onClick={() => setShowModal(false)} className="cancelBtn">
                                        Cancel
                                    </Button>
                                    <Button variant="outlined" type="submit" className="activeUnGroupBtn" >
                                        Update
                                    </Button>
                                </>
                            )}
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default GroupsHotelsList;
