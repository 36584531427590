import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { APP_GHA_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import MarketsFilterMenu from "components/MarketFilterMenu";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";
import { ghaBiddingRulesFormFields } from "./options";

const GHABidddingRules = () => {
  const token = sessionStorage.getItem("token") || "";
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState([]);
  const [updateBidDesktopPayload, setUpdateBidDesktopPayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });
  const [updateBidMobilePayload, setUpdateBidMobilePayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });
  const [updateBidTabletPayload, setUpdateBidTabletPayload] = useState({
    id_account: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const {
    hotel_list = [],
    group_list = [],
    market_list = [],
    bidding_rule_list = [],
    can_use_group,
  } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: "-- Select a hotel --",
      options: hotel_list,
    },

    {
      id: "market_id",
      label: "Market",
      type: "select",
      placeholder: "-- Select a value --",
      options: market_list,
    },
  ];

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      setUpdateBidDesktopPayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      setUpdateBidMobilePayload((prev) => ({
        ...prev,
        id_account: account_id,
      }));
      axios
        .get(
          `${APP_GHA_BIDDING_API_ENDPOINT}/api/google-hotel-ads/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id]);

  const handleChangeFieldValue = useCallback((field, value, device) => {
    if (device === "desktop") {
      setUpdateBidDesktopPayload((prev) => ({ ...prev, [field]: value }));
    } else if (device === "mobile") {
      setUpdateBidMobilePayload((prev) => ({ ...prev, [field]: value }));
    } else {
      setUpdateBidTabletPayload((prev) => ({ ...prev, [field]: value }));
    }
  }, []);

  const sendUpdateRequest = (payload) => {
    axios
      .put(
        `${APP_GHA_BIDDING_API_ENDPOINT}/api/google-hotel-ads-meta/update-bids/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShowToast(true);
      });
  };

  const handleSave = useCallback(() => {
    const finalDesktopPayload = { ...updateBidDesktopPayload };
    const finalMobilePayload = { ...updateBidMobilePayload };
    const finalTabletPayload = { ...updateBidTabletPayload };

    if (selectedHotelOrGroupType === "hotels") {
      delete finalDesktopPayload?.group_id;
      delete finalMobilePayload?.group_id;
      delete finalTabletPayload?.group_id;
    }
    if (selectedHotelOrGroupType === "groups") {
      delete finalDesktopPayload?.hotel_id;
      delete finalMobilePayload?.hotel_id;
      delete finalTabletPayload?.hotel_id;
    }

    if (
      finalDesktopPayload?.bidding_strategy?.multiplier_identifier &&
      finalDesktopPayload?.bidding_strategy?.multiplier_value !== undefined
    ) {
      sendUpdateRequest(finalDesktopPayload);
    }

    if (
      finalMobilePayload?.bidding_strategy?.multiplier_identifier &&
      finalMobilePayload?.bidding_strategy?.multiplier_value !== undefined
    ) {
      sendUpdateRequest(finalMobilePayload);
    }

    if (
      finalTabletPayload?.bidding_strategy?.multiplier_identifier &&
      finalTabletPayload?.bidding_strategy?.multiplier_value !== undefined
    ) {
      sendUpdateRequest(finalTabletPayload);
    }
  }, [updateBidDesktopPayload, updateBidMobilePayload, updateBidTabletPayload]);

  const biddingOptions = useMemo(() => {
    if (bidding_rule_list?.length) {
      return bidding_rule_list.map((rule) => ({
        id: rule.bidding_rule_id,
        label: rule.bidding_rule_name,
        value: rule.bidding_rule_name,
      }));
    }

    return [];
  }, [bidding_rule_list]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "market_id") {
                return (
                  <div className="form-grid-item">
                    {market_list?.length ? (
                      <MarketsFilterMenu
                        allMarkets={market_list}
                        onApply={(selected) => {
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "desktop"
                          );
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "mobile"
                          );
                          handleChangeFieldValue(
                            "market_id",
                            selected.map((s) => s.market_id),
                            "tablet"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }

              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          border: "1px solid #C4C4C4",
                          backgroundColor: "white",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list}
                        allGroups={group_list}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "mobile"
                          );
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "desktop"
                          );
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id),
                            "tablet"
                          );
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL" : "GROUP"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="fields-container">
            {ghaBiddingRulesFormFields?.map((field) => {
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={[...field?.options, ...biddingOptions] || []}
                    hotelMenuOpen={openHotelMenu}
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    onChange={(val) => {
                      let device_identifier;
                      if (field.label.toLowerCase().includes("desktop")) {
                        device_identifier = "desktop";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidDesktopPayload?.bidding_strategy,
                            bidding_type: "DEVICE",
                            multiplier_identifier: "DESKTOP",
                            multiplier_value: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                      if (field.label.toLowerCase().includes("mobile")) {
                        device_identifier = "mobile";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidMobilePayload?.bidding_strategy,
                            bidding_type: "DEVICE",
                            multiplier_identifier: "MOBILE",
                            multiplier_value: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                      if (field.label.toLowerCase().includes("tablet")) {
                        device_identifier = "tablet";

                        handleChangeFieldValue(
                          "bidding_strategy",
                          {
                            ...updateBidTabletPayload?.bidding_strategy,
                            bidding_type: "DEVICE",
                            multiplier_identifier: "TABLET",
                            multiplier_value: val
                              ? biddingOptions.find((opt) => opt.value === val)
                                  ?.id ?? 0
                              : undefined,
                          },
                          device_identifier
                        );
                      }
                    }}
                    labelKey={"label"}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {updateBidDesktopPayload?.market_id?.length &&
          updateBidDesktopPayload?.[
            selectedHotelOrGroupType === "hotels" ? "hotel_id" : "group_id"
          ]?.length &&
          (updateBidDesktopPayload?.bidding_strategy?.multiplier_value !==
            undefined ||
            updateBidMobilePayload?.bidding_strategy?.multiplier_value !==
              undefined ||
            updateBidTabletPayload?.bidding_strategy?.multiplier_value !==
              undefined) && (
            <div className="save">
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#12794F",
                  borderRadius: "20px",
                  height: "28px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textTransform: "none",
                  color: "white",
                }}
                title="Save"
                onClick={handleSave}
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          )}
      </div>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Bidding Rules were saved
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GHABidddingRules;
