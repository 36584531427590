export const platformOptions = [
  {
    id: "tripadvisor",
    label: "Tripadvisor",
    isSelected: true,
  },
  {
    id: "googlehotelads",
    label: "Google Hotel Ads",
    isSelected: true,
  },
  {
    id: "bing",
    label: "Bing",
    isSelected: true,
  },
  {
    id: "trivago",
    label: "Trivago",
    isSelected: true,
  },
  {
    id: "kayak",
    label: "Kayak",
    isSelected: true,
  },
  {
    id: "skyScanner",
    label: "Sky Scanner",
    isSelected: true,
  },
  {
    id: "wego",
    label: "Wego",
    isSelected: true,
  },
];
export const platformDesignationOptions = [
  {
    id: "googleHotelAdsPropertyPromotionAds",
    label: "Google Hotel Ads - Property Promotion Ads",
    isSelected: true,
  },
  {
    id: "googleHotelAdsFBL",
    label: "Google Hotel Ads - FBL",
    isSelected: true,
  },
  {
    id: "tripadvisorSponsoredPlacement",
    label: "Tripadvisor - sponsored Placement ",
    isSelected: true,
  },
  {
    id: "trivagoSponsoredListing",
    label: "Trivago Sponsored Listing",
    isSelected: true,
  },
];
export const hotelOptions = [
  {
    id: "hotel1",
    label: "Hotel Florida",
    isSelected: true,
  },
  {
    id: "hotel2",
    label: "Hotel Petit Beaumarchais",
    isSelected: true,
  },
  {
    id: "hotel3",
    label: "Hotel Domaine du Fontenitelle ",
    isSelected: true,
  },
  {
    id: "hotel4",
    label: "Hotel London West Hollywood",
    isSelected: true,
  },
  {
    id: "hotel5",
    label: "Pierre & Vacances Holiday Village",
    isSelected: true,
  },
  {
    id: "hotel6",
    label: "Hotel London West Hollywood",
    isSelected: true,
  },
  {
    id: "hotel7",
    label: "Hotel London West Hollywood",
    isSelected: true,
  },
  {
    id: "hotel8",
    label: "Hotel Royal Palace",
    isSelected: true,
  },
  {
    id: "hotel9",
    label: "Hotel Grand Central",
    isSelected: false,
  },
  {
    id: "hotel10",
    label: "Hotel Luxe",
    isSelected: true,
  },
  {
    id: "hotel11",
    label: "Hotel Riviera",
    isSelected: false,
  },
  {
    id: "hotel12",
    label: "Hotel Emerald Bay",
    isSelected: true,
  },
  {
    id: "hotel13",
    label: "Hotel Grand Plaza",
    isSelected: false,
  },
  {
    id: "hotel14",
    label: "Hotel Coastal View",
    isSelected: true,
  },
  {
    id: "hotel15",
    label: "Hotel Panorama",
    isSelected: true,
  },
  {
    id: "hotel16",
    label: "Hotel Sunset Ridge",
    isSelected: true,
  },
  {
    id: "hotel17",
    label: "Hotel Alpine Lodge",
    isSelected: false,
  },
  {
    id: "hotel18",
    label: "Hotel Seaside Retreat",
    isSelected: true,
  },
  {
    id: "hotel19",
    label: "Hotel Cityscape",
    isSelected: true,
  },
  {
    id: "hotel20",
    label: "Hotel Mountain Haven",
    isSelected: false,
  },
];

export const groupOptions = [
  {
    id: "openHotels",
    label: "Open hotels",
    isSelected: true,
  },
  {
    id: "closedHotels",
    label: "Closed hotels",
    isSelected: true,
  },
];
export const segmentMeuOptions = {
  skyScanner: [
    {
      id: "MARKET",
      label: "Markets",
      isSelected: true,
    },
    {
      id: "DEVICE",
      label: "Device",
      isSelected: true,
    },
    {
      id: "HOTELS",
      label: "Hotels",
      isSelected: true,
    },
    {
      id: "GROUPS",
      label: "Groups",
      isSelected: true,
    },
    {
      id: "DATE",
      label: "Date",
      isSelected: true,
    },
  ],
};
