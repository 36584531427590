import { CloseOutlined } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import CustomCheckbox from "components/Checkbox";
import { useState } from "react";

const ColumnSelectionMenu = ({ open, handleClose, onApply, columns }) => {
  const [selected, setSelected] = useState(columns);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="m"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "10px",
            // height: 350,
          },
        }}
      >
        <div>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
              paddingInline: "14px",
            }}
          >
            <div>&nbsp;</div>
            <div
              style={{ justifySelf: "flex-end" }}
              onClick={() => {
                handleClose();
              }}
            >
              <CloseOutlined sx={{ color: "#BAB7B7", fontSize: "14px" }} />
            </div>
          </DialogTitle>
          <DialogContent sx={{ paddingInline: "14px", paddingBlock: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <div style={{ display: "flex" }}>
                <div>
                  <span style={{ fontSize: "12px" }}>Performance</span>
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      height: "250px",
                      marginBlock: 0,
                      padding: 0,
                    }}
                  >
                    {selected
                      ?.filter((item) => item.accessorKey !== "channel")
                      ?.map((item) => {
                        return (
                          <li
                            style={{
                              borderLeft: "1px solid #CADEDD",
                              paddingLeft: "10px",
                              height: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelected(
                                  selected?.map((i) => {
                                    return {
                                      ...i,
                                      isSelected:
                                        i?.accessorKey === item.accessorKey
                                          ? !i?.isSelected
                                          : i?.isSelected,
                                    };
                                  })
                                );
                              }}
                            >
                              <span>
                                <CustomCheckbox
                                  size={"small"}
                                  checked={item?.isSelected}
                                  title={item?.header}
                                />
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div>
                  <span style={{ fontSize: "12px" }}>Conversion</span>
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      height: "250px",
                      marginBlock: 0,
                      padding: 0,
                    }}
                  >
                    {selected
                      ?.filter((item) => item.accessorKey !== "channel")
                      ?.map((item) => {
                        return (
                          <li
                            style={{
                              borderLeft: "1px solid #CADEDD",
                              paddingLeft: "10px",
                              height: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelected(
                                  selected?.map((i) => {
                                    return {
                                      ...i,
                                      isSelected:
                                        i?.accessorKey === item.accessorKey
                                          ? !i?.isSelected
                                          : i?.isSelected,
                                    };
                                  })
                                );
                              }}
                            >
                              <span>
                                <CustomCheckbox
                                  size={"small"}
                                  checked={item?.isSelected}
                                  title={item?.header}
                                />
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div>
                  <span style={{ fontSize: "12px" }}>3rd Category</span>
                  <ul
                    style={{
                      listStyleType: "none",
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      height: "250px",
                      marginBlock: 0,
                      padding: 0,
                    }}
                  >
                    {selected
                      ?.filter((item) => item.accessorKey !== "channel")
                      ?.map((item) => {
                        return (
                          <li
                            style={{
                              borderLeft: "1px solid #CADEDD",
                              paddingLeft: "10px",
                              height: "25px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelected(
                                  selected?.map((i) => {
                                    return {
                                      ...i,
                                      isSelected:
                                        i?.accessorKey === item.accessorKey
                                          ? !i?.isSelected
                                          : i?.isSelected,
                                    };
                                  })
                                );
                              }}
                            >
                              <span>
                                <CustomCheckbox
                                  size={"small"}
                                  checked={item?.isSelected}
                                  title={item?.header}
                                />
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              padding: "14px",
              paddingBlock: "15px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "black",
                backgroundColor: "#61D5A9",
                borderRadius: "20px",
                fontSize: "10px",
                width: "100px",
                paddingInline: "8px",
              }}
              onClick={() => {
                onApply(selected);
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};
export default ColumnSelectionMenu;
