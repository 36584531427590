import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ReportsTable from "../../features/report-center/Listing";
import DefaultLayout from "../../layout/defaultLayout";
import SlidingPanel from '../../features/report-center/SlidingPanel';
import { fetchService } from '../../features/report-center/Services/fetchService';
import { Box, Paper, Grid } from '@mui/material';
import GlobalReportForm from "../../features/report-center/Forms/GlobalPerformance";
import GoogleFBLForm from "../../features/report-center/Forms/GoogleFBL";
import PerformancePmax from "../../features/report-center/Forms/PerformancePmax";
import SpFunnelTripAdvisor from "../../features/report-center/Forms/SpFunnelTripAdvisor";
import PriceCompetitiveness from "../../features/report-center/Forms/PriceCompetitiveness";
import PerformanceMarketPmax from "../../features/report-center/Forms/PerformanceMarketPmax";
import Billing from "../../features/report-center/Forms/Billing";
import Reservations from "../../features/report-center/Forms/Reservations";
import PerformanceGoogleMissedOpportunity from "../../features/report-center/Forms/PerformanceGoogleMissedOpportunity";
import {APP_REPORT_CENTER_API_ENDPOINT} from "../../constants/api_urls";
import PageTitles from "../../components/PageTitles";
import CustomButtonWithIcon from "components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";
import SearchTableInput from "components/SearchTableInput/SearchTableInput";
import MetaSearch from 'pages/metasearch';


const ReportCenter = () => {
  const [reports, setReports] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isPannelOpen, setIsPannelOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [skipStep1, setSkipStep1] = useState(false); 
  const { idaccount } = useParams();

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reportResponse = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/reports/list?account=${idaccount}`);
        if (reportResponse.success) {
            setReports(reportResponse.data);
        } else {
          const errorMessage = reportResponse.errors.length > 0
            ? reportResponse.errors[0].message
            : 'Unknown error occurred';
          setError(errorMessage);
        }
        
      } catch (err) {
        setError('Failed to load reports');
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);


   // Handle report selection for editing
   const handleEditReport = (report) => {
    setSelectedReport(report);
    setShowForm(true); 
    setIsPannelOpen(false); 
  };

  // Handle creating a new report
  const handleCreateNewReport = () => {
    setSelectedReport(null);
    setSkipStep1(false); 
    setShowForm(true);
    setIsPannelOpen(true);
  };
   
      
      const reportComponents = {
        PERFORMANCE_GLOBAL: <GlobalReportForm setShowForm={setShowForm} />,
        PERFORMANCE_GOOGLE_FBL: <GoogleFBLForm setShowForm={setShowForm} />,
        PERFORMANCE_METASEARCH: <MetaSearch setShowForm={setShowForm} />,
        PERFORMANCE_PMAX: <PerformancePmax setShowForm={setShowForm} />,
        SP_FUNNEL_TRIPADVISOR: <SpFunnelTripAdvisor setShowForm={setShowForm} />,
        PRICE_COMPETITIVENESS: <PriceCompetitiveness setShowForm={setShowForm} />,
        PERFORMANCE_MARKET_PMAX: <PerformanceMarketPmax setShowForm={setShowForm} />,
        BILLING: <Billing setShowForm={setShowForm} />,
        RESERVATIONS: <Reservations setShowForm={setShowForm} />,
        PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: <PerformanceGoogleMissedOpportunity setShowForm={setShowForm} />,
      };


  return (
    <DefaultLayout page="report-center">
    <Grid container xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: 2,
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PageTitles pageTitle="Metasearch" pageSubTitle="Report Center" />
          </div>
        </div>
      </Box>
    </Grid>
  
    <Box sx={{ padding: 3 }}>
      {/* Header with Search and New Report button */}
      <Grid container xs={12} spacing={2}>
        {/* New Report Button on the top right */}
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <CustomButtonWithIcon
            color="#b0acac"
            hoverColor="#61D5A9"
            width="120px"
            height="30px"
            titleColor="#373F41"
            borderRadius="20px"
            iconBorder="0.5px solid #373F41"
            textStyle={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '14.52px',
              textAlign: 'center',
            }}
            onClick={handleCreateNewReport}
          >
            New Report
          </CustomButtonWithIcon>
        </Grid>
  
        {/* Search Input below New Report Button with grey background */}
        <Grid item xs={12}>
          <Box  sx={{ backgroundColor: '#EBEBEB', padding: "0.5rem" }}>
            <SearchTableInput placeholder="Search" />
          </Box>
        </Grid>
      </Grid>
  
      {/* Sliding Panel */}
      <SlidingPanel
        open={isPannelOpen}
        onClose={() => setIsPannelOpen(false)}
        skipStep1={false}
      />
  
      {/* Render selected report component */}
      {showForm && selectedReport && reportComponents[selectedReport]}
  
      {/* Report Listing Section */}
      <Paper elevation={3}>
        <ReportsTable reports={reports} onEdit={handleEditReport} />
      </Paper>
    </Box>
  </DefaultLayout>
  
  );
};

export default ReportCenter;