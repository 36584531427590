import React, { useState } from "react";
import {
    Box,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Grid,
    Button
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import GOOGLE_SEARCH_ADS_LOCATION_CRITERION from "../../../constants/search-ads-criterion/googleSearchAdsLocationCriterion";

const TargetLocations = ({ selectedCountries, setSelectedCountries }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedContinents, setSelectedContinents] = useState([]);

    const handleCountryChange = (locationId) => {
        setSelectedCountries((prev) =>
            prev.includes(locationId)
                ? prev.filter((id) => id !== locationId)
                : [...prev, locationId]
        );
    };

    const handleContinentChange = (continent, countries) => {
        const countryIds = countries.map((country) => country.location_criterion_id);
        const allSelected = countryIds.every((id) => selectedCountries.includes(id));

        if (allSelected) {
            setSelectedCountries((prev) => prev.filter((id) => !countryIds.includes(id)));
            setSelectedContinents((prev) => prev.filter((c) => c !== continent));
        } else {
            setSelectedCountries((prev) => [...prev, ...countryIds.filter((id) => !prev.includes(id))]);
            setSelectedContinents((prev) => [...prev, continent]);
        }
    };

    const handleSelectAll = () => {
        const allCountries = Object.values(GOOGLE_SEARCH_ADS_LOCATION_CRITERION)
            .flat()
            .map((country) => country.location_criterion_id);
        const allContinents = Object.keys(GOOGLE_SEARCH_ADS_LOCATION_CRITERION);

        if (selectedCountries.length === allCountries.length) {
            setSelectedCountries([]);
            setSelectedContinents([]);
            setIsSelectAll(false);
        } else {
            setSelectedCountries(allCountries);
            setSelectedContinents(allContinents);
            setIsSelectAll(true);
        }
    };

    const handleDeselectAll = () => {
        setSelectedCountries([]);
        setSelectedContinents([]);
        setIsSelectAll(false);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);
        setIsExpanded(!!value);
    };

    const clearSearch = () => {
        setSearchQuery("");
        setIsExpanded(false);
    };

    const toggleExpandAllContinents = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box className="targetLocations">
            <Box display="flex" alignItems="center" className="sectionTitle selectActions" justifyContent="space-between">
                <Typography>Select Your Points of Sale</Typography>
                <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <SearchIcon fontSize="small" style={{ color: "gray", marginRight: "8px" }} />
                        <TextField
                            variant="standard"
                            placeholder="Search Country"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={clearSearch}>
                                            <ClearIcon fontSize="small" style={{ color: "gray" }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className="countrySearchField"
                        />
                    </Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isSelectAll}
                                onChange={handleSelectAll}
                            />
                        }
                        label="Select All"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCountries.length === 0 && selectedContinents.length === 0}
                                onChange={handleDeselectAll}
                            />
                        }
                        label="Deselect All"
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems="center" p={2}>
                <Grid container spacing={2} wrap="nowrap">
                    {Object.entries(GOOGLE_SEARCH_ADS_LOCATION_CRITERION).map(([continent, countries]) => (
                        <Grid item xs={6} sm={6} md={6} key={continent}>
                            <Box className="countryGroup">
                                <FormControlLabel
                                    className="continentLabel"
                                    control={
                                        <Checkbox
                                            checked={selectedContinents.includes(continent)}
                                            onChange={() => handleContinentChange(continent, countries)}
                                        />
                                    }
                                    label={continent}
                                />
                                <FormGroup>
                                    {(isExpanded ? countries : countries.slice(0, 5))
                                        .filter((country) =>
                                            country.location_canonical_name.toLowerCase().includes(searchQuery)
                                        )
                                        .map(({ location_criterion_id, location_canonical_name }) => (
                                            <FormControlLabel
                                                key={location_criterion_id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedCountries.includes(location_criterion_id)}
                                                        onChange={() => handleCountryChange(location_criterion_id)}
                                                    />
                                                }
                                                label={location_canonical_name}
                                            />
                                        ))}
                                </FormGroup>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {!searchQuery && (
                <Box display="flex" justifyContent="left" mt={1}>
                    <Button
                        onClick={toggleExpandAllContinents}
                        className="loadMoreButton"
                        startIcon={isExpanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                    >
                        {isExpanded ? "Show Less" : "Load More"}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default TargetLocations;
