import { Box, Chip, Typography } from "@mui/material";
import { DatePicker } from "components/DatePicker";
import BiddingOffline from "features/metasearch/biddingOffline";

import DefaultLayout from "layout/defaultLayout";
import { useRef, useState } from "react";

const BiddingOfflinePage = () => {
  const hotelButtonRef = useRef(null);
  const platformButtonRef = useRef(null);
  const [setOpenPlatformModal] = useState(false);
  const [setOpenHotelModal] = useState(false);
  return (
    <DefaultLayout>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingInline: 2,
          marginBottom: "20px",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="quency-cf-normal heading">
              {" "}
              Meta Search{" "}
            </Typography>
            <div
              style={{
                borderLeft: "1px solid #BAB7B7",
                color: "#BAB7B7",
                paddingLeft: "5px",
                marginLeft: "5px",
                fontSize: "12px",
              }}
            >
              Bidding offline
            </div>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <DatePicker />
        </div>
      </Box>{" "}
      <div style={{ paddingInline: "20px" }}>
        <BiddingOffline />
      </div>
    </DefaultLayout>
  );
};
export default BiddingOfflinePage;
