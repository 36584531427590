export const tripAdvisorMetaBiddingRulesFormFields = [
  {
    id: "bidding_rule_id",
    label: "Bidding Rules (Desktop)",
    type: "select",
    placeholder: "-- SELECT A BIDDING RULE --",
    options: [
      {
        id: "noBiddingRule",
        label: "No bidding rule",
        value: "noBiddingRule",
      },
    ],
  },
  {
    id: "bidding_rule_id",
    label: "Bidding Rules (Mobile)",
    type: "select",
    placeholder: "-- SELECT A BIDDING RULE --",
    options: [
      {
        id: "noBiddingRule",
        label: "No bidding rule",
        value: "noBiddingRule",
      },
    ],
  },
];
