import "./metasearch.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DefaultLayout from "../../layout/defaultLayout";
import {
  Box,
  Button,
  Chip,
  Menu,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import {
  groupOptions,
  hotelOptions,
  platformOptions,
  platformDesignationOptions,
} from "mock/filterOptions";
import CustomCheckbox from "components/Checkbox";
import ChartContainer from "features/metasearch/chartContainer";
import TableContainer from "features/metasearch/TableContainer";
import EditBidContainer from "features/metasearch/editBidContainer";
import { DatePicker } from "components/DatePicker/index";
import { useDispatch, useSelector } from "react-redux";
import {
  updateGroupId,
  updateHoteId,
  updateLevel,
  updatePlatform,
} from "store/slices/metaSearch/metaSearch";
import axios from "axios";
import HotelsFilterMenu from "components/hotelFilterMenu";
const MetaSearch = () => {
  const token = sessionStorage.getItem("token") || "";
  const platform = useSelector((state) => state?.metaSearch?.platform);
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const hotelButtonRef = useRef(null);
  const platformButtonRef = useRef(null);
  const [openPlatformModal, setOpenPlatformModal] = useState(false);
  const [openHotelModal, setOpenHotelModal] = useState(false);
  const [hotelFilters, setHotelFilters] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [platformFilters, setPlatformFilters] = useState([]);
  const scrollContainerRef = useRef(null);
  const [isChartCollapsed, setIsChartCollapsed] = useState(false);
  const [openEditBidding, setOpenEditBidding] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const dispatch = useDispatch();

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      axios
        .get(
          `https://dmp-hotels-api.wihphotels.dev/api/hotels/list?id_account=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setHotelList(res?.data?.data || []);
        });

      axios
        .get(
          `https://dmp-hotels-api.wihphotels.dev/api/group/list?id_account=${account_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setGroupList(res?.data?.group_list || []);
        });
    }
  }, [account_id]);

  useEffect(() => {
    dispatch(
      updateLevel(selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP")
    );
  }, []);

  const FilterMenu = ({
    open,
    options,
    onClose,
    filterType,
    onApply,
    anchorEl,
    optionsDesignation,
  }) => {
    //Selectors
    const [selected, setSelected] = useState(options);
    const [selectedDesignation, setSelectedDesignation] =
      useState(optionsDesignation);

    const isSelectAllChecked = useMemo(() => {
      const selectedCount = selected.filter((item) => item.isSelected).length;

      return selectedCount === options.length;
    }, [selected]);
    const isDesignatedAllChecked = useMemo(() => {
      const selectedCount = selectedDesignation.filter(
        (item) => item.isSelected
      ).length;

      return selectedCount === optionsDesignation.length;
    }, [selectedDesignation]);
    const toggleSelectAll = (type) => {
      if (type === "metasearch") {
        const selectedCount = selected.filter((item) => item.isSelected).length;
        if (selectedCount === options.length) {
          setSelected(
            selected.map((item) => {
              return { ...item, isSelected: false };
            })
          );
        } else {
          setSelected(
            selected.map((item) => {
              return { ...item, isSelected: true };
            })
          );
        }
      } else {
        const selectedCount = selectedDesignation.filter(
          (item) => item.isSelected
        ).length;
        if (selectedCount === optionsDesignation.length) {
          setSelectedDesignation(
            selectedDesignation.map((item) => {
              return { ...item, isSelected: false };
            })
          );
        } else {
          setSelectedDesignation(
            selectedDesignation.map((item) => {
              return { ...item, isSelected: true };
            })
          );
        }
      }
    };

    return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={{
          width: "100%",
          "& .MuiPaper-root": {
            borderRadius: "10px",
          },
        }}
      >
        <div style={{ paddingInline: "10px" }}>
          <div
            style={{
              color: "var(--Grey, #C4C4C4)",
              fontFamily: "Inter",
              fontSize: "10px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{filterType}</span>
            <span onClick={onClose}>
              <CloseOutlined sx={{ fontSize: "16px" }} />
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <ul
              style={{
                listStyleType: "none",
                marginBlock: 0,
                padding: 0,
                paddingLeft: "4px",
                overflow: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <span>
                  <CustomCheckbox
                    size={"small"}
                    onChange={() => {
                      toggleSelectAll("metasearch");
                    }}
                    checked={isSelectAllChecked}
                    title={"Metasearch"}
                    labelStyles={{
                      color: "#c4c4c4",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                </span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginRight: "0px",
                    marginLeft: "5px",
                  }}
                >
                  {selected?.map((item) => (
                    <li
                      key={item.id}
                      style={{
                        borderLeft: "1px solid #CADEDD",
                        paddingLeft: "10px",

                        paddingBlock: "5px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelected(
                            selected.map((i) => ({
                              ...i,
                              isSelected:
                                i.id === item.id ? !i.isSelected : i.isSelected,
                            }))
                          );
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span
                            style={{
                              flex: "0 0 50%",
                              textAlign: "right",
                              marginRight: "16px",
                            }}
                          >
                            <CustomCheckbox
                              size={"small"}
                              checked={item.isSelected}
                              title={item.label}
                              labelStyles={{
                                color: "#000",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                              }}
                            />
                          </span>
                        </Box>
                      </div>
                    </li>
                  ))}
                </Box>
              </Box>
            </ul>

            <ul>
              <Box sx={{ flex: 1, display: "flex" }}>
                <CustomCheckbox
                  size={"small"}
                  title={" Designation Ads"}
                  onChange={() => {
                    toggleSelectAll("DesignationAds");
                  }}
                  checked={isDesignatedAllChecked}
                  labelStyles={{
                    color: "gray",
                    fontFamily: "Inter",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "5px",
                }}
              >
                {selectedDesignation?.map((item) => (
                  <li
                    key={item.id}
                    style={{
                      borderLeft: "1px solid #CADEDD",
                      paddingLeft: "10px",
                      paddingBlock: "5px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedDesignation(
                          selectedDesignation.map((i) => ({
                            ...i,
                            isSelected:
                              i.id === item.id ? !i.isSelected : i.isSelected,
                          }))
                        );
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            flex: "0 0 50%",
                            textAlign: "right",
                          }}
                        >
                          <CustomCheckbox
                            size={"small"}
                            checked={item.isSelected}
                            title={item.label}
                            sx={{
                              color: "#000",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          />
                        </span>
                      </Box>
                    </div>
                  </li>
                ))}
              </Box>
            </ul>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "14px",
            paddingBlock: "8px",
          }}
        >
          <span
            style={{
              flex: "0 0 50%",

              marginRight: "16px",

              fontSize: "10px",
            }}
            className="sublabel"
            onClick={() => {
              setSelected(
                selected.map((item) => {
                  return { ...item, isSelected: false };
                })
              );
              setSelectedDesignation(
                selectedDesignation.map((item) => {
                  return { ...item, isSelected: false };
                })
              );
            }}
          >
            Unselect All
          </span>
          <Button
            variant="outlined"
            sx={{
              color: "black",
              backgroundColor: "#61D5A9",
              borderRadius: "20px",
              fontSize: "10px",
              width: "100px",
              paddingInline: "8px",

              fontFamily: "inter",
              height: "20px",
            }}
            onClick={() => {
              onApply([
                ...selected?.filter((item) => item.isSelected),
                ...selectedDesignation.filter((item) => item.isSelected),
              ]);
              onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </Menu>
    );
  };

  useEffect(() => {
    const { id } = platform?.[0] || {};

    switch (id) {
      case "sky":
        break;

      default:
        break;
    }
  }, [platform]);

  console.log(hotelList, "HotelLis");

  return (
    <div className="metasearch">
      <DefaultLayout page="metaSearch">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingInline: 2,
          }}
        >
          <div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Typography className="quency-cf-normal heading">
                {" "}
                Meta Search{" "}
              </Typography>
              <div
                style={{
                  borderLeft: "1px solid #BAB7B7",
                  color: "#BAB7B7",
                  paddingLeft: "15px",
                  marginLeft: "15px",
                  fontSize: "12px",
                }}
              >
                Campaign Manager
              </div>
            </div>
            <div>
              <div
                style={{
                  color: "#587A78",
                  marginBottom: "10px",
                  fontSize: "10px",
                }}
              >
                Filter
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#000000",
                  fontWeight: "400",
                }}
              >
                <div
                  onClick={() => {
                    setOpenHotelModal(true);
                  }}
                  ref={hotelButtonRef}
                >
                  <Chip
                    label={
                      hotelFilters.length === 0
                        ? "HOTELS"
                        : hotelFilters.length === 1
                        ? hotelFilters[0].label
                        : (selectedHotelOrGroupType === "hotels" &&
                            hotelFilters.length === hotelOptions.length) ||
                          (selectedHotelOrGroupType === "groups" &&
                            hotelFilters.length === groupOptions.length)
                        ? selectedHotelOrGroupType === "hotels"
                          ? "ALL HOTELS"
                          : "ALL GROUPS"
                        : `${hotelFilters.length} ${
                            selectedHotelOrGroupType === "hotels"
                              ? "hotels"
                              : "groups"
                          } selected`
                    }
                    sx={{
                      backgroundColor:
                        hotelFilters.length > 0 ? "#61D5A9" : "inherit",
                      fontFamily: "inter",
                      fontSize: "10px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "20px",
                      border:
                        hotelFilters.length > 0 ? "none" : "1px solid grey",
                    }}
                  />
                </div>

                <div
                  onClick={() => {
                    setOpenPlatformModal(true);
                  }}
                  ref={platformButtonRef}
                >
                  <Chip
                    label={
                      platformFilters.length === 0
                        ? "PLATFORMS"
                        : platformFilters.length === 1
                        ? platformFilters[0].label
                        : platformFilters.length === platformOptions.length
                        ? "ALL PLATFORMS"
                        : `${platformFilters.length} platforms selected`
                    }
                    sx={{
                      backgroundColor:
                        platformFilters.length > 0 ? "#61D5A9" : "inherit",
                      fontFamily: "inter",
                      fontSize: "10px",
                      fontWeight: "400",
                      marginRight: "4px",
                      height: "20px",
                      border:
                        platformFilters.length > 0 ? "none" : "1px solid grey",
                    }}
                  />
                </div>
                {openPlatformModal && (
                  <FilterMenu
                    anchorEl={platformButtonRef.current}
                    open={openPlatformModal}
                    options={platformOptions?.map((po) =>
                      platformFilters.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    optionsDesignation={platformDesignationOptions?.map((po) =>
                      platformFilters.find((pf) => pf.id === po.id)
                        ? { ...po, isSelected: true }
                        : { ...po, isSelected: false }
                    )}
                    filterType={"Platform"}
                    selectAllTitle={"Platforms"}
                    onApply={(selected) => {
                      setOpenEditBidding(false);
                      setPlatformFilters(selected);
                      dispatch(updatePlatform(selected));
                    }}
                    onClose={() => {
                      setOpenPlatformModal(false);
                    }}
                  />
                )}
                {openHotelModal && (
                  <HotelsFilterMenu
                    allHotels={hotelList?.map((ho) =>
                      hotelFilters.find((hf) => hf.id === ho.id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    anchorEl={hotelButtonRef.current}
                    open={openHotelModal}
                    allGroups={groupList?.map((ho) =>
                      hotelFilters.find((hf) => hf.id === ho.id)
                        ? { ...ho, isSelected: true }
                        : { ...ho, isSelected: false }
                    )}
                    isSelectAllRequired
                    onApply={(selected) => {
                      setHotelFilters(selected);
                      if (selectedHotelOrGroupType === "hotels") {
                        dispatch(
                          updateHoteId(
                            selected.map((item) => item?.["hotel_id"])
                          )
                        );
                      } else {
                        dispatch(
                          updateGroupId(selected.map((item) => item?.["id"]))
                        );
                      }
                    }}
                    onClose={() => {
                      setOpenHotelModal(false);
                    }}
                    setSelectedHotelOrGroupType={(type) => {
                      setSelectedHotelOrGroupType(type);
                      dispatch(
                        updateLevel(type === "hotels" ? "HOTEL" : "GROUP")
                      );
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <DatePicker />
          </div>
        </Box>
        <Box sx={{}} ref={scrollContainerRef}>
          <div
            style={{
              display: isChartCollapsed || openEditBidding ? "none" : "block",
            }}
          >
            <ChartContainer backgroundColor="transparent" />
          </div>
          <div
            style={{
              display: openEditBidding ? "block" : "none",
            }}
          >
            <EditBidContainer setOpenEditBidding={setOpenEditBidding} />
          </div>
          <TableContainer
            isChartCollapsed={isChartCollapsed}
            setIsChartCollapsed={setIsChartCollapsed}
            onEditClick={() => {
              if (platform?.length > 1) {
                setToastMessage("Please select single platform");
                setToastType("error");
                setShowToast(true);
                return;
              }
              setOpenEditBidding(!openEditBidding);
            }}
          />
        </Box>
      </DefaultLayout>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default MetaSearch;
