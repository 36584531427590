import React, {useState} from "react";
import CampaignAccordion from "../googleSearchAdsFormComponents/Campaign/CampaignAccordion";
import ObjectiveAccordion from "../googleSearchAdsFormComponents/Objective/ObjectiveAccordion";
import KeywordsAccordion from "../googleSearchAdsFormComponents/Keywords/KeywordsAccordion";
import AdsCreativeAccordion from "../googleSearchAdsFormComponents/AdsCreative/AdsCreativeAccordion";
import CampaignSettingsAccordion from "../googleSearchAdsFormComponents/CampaignSettings/CampaignSettingsAccordion";
import {validateCampaignSettings, validateKeywords} from "../googleSearchAdsFormComponents/Utils/validators";

const GoogleSearchAdsCampaignCreator = ({
    expandedPanel,
    setExpandedPanel,
    selectedObjective,
    setSelectedObjective,
    visitedPanels,
    handleNext,
    selectedCountries,
    setSelectedCountries,
    selectedLanguage,
    setSelectedLanguage,
    targetValue,
    setTargetValue,
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    maxCpcLimit,
    setMaxCpcLimit,
    impressionShare,
    setImpressionShare,
    adLocation,
    setAdLocation,
    monthlyBudget,
    setMonthlyBudget,
    keywords,
    setKeywords,
    negativeKeywords,
    setNegativeKeywords,
    headlines,
    setHeadlines,
    descriptions,
    setDescriptions,
    siteLinks,
    setSiteLinks,
    callOuts,
    setCallOuts,
    structuredSnippets,
    setStructuredSnippets,
    finalURL,
    setFinalURL,
    businessName,
    setBusinessName,
    calls,
    setCalls,
    displayPaths,
    setDisplayPaths,
}) => {
    const [errors, setErrors] = useState({});

    const handleNextWithValidation = (currentPanel) => {
        let validationResults = {};

        if (currentPanel === "settings") {
            validationResults = validateCampaignSettings({
                campaignName,
                biddingStrategy,
                targetCpa,
                targetRoas,
                maxCpcLimit,
                impressionShare,
                adLocation,
                targetValue
            });
        } else if (currentPanel === "keywords") {
            validationResults = validateKeywords({ keywords, negativeKeywords });
        }

        setErrors(validationResults);
        handleNext(currentPanel);
    };

    return (
        <>
            <CampaignAccordion
                title="1. Select Your Objective"
                expanded={expandedPanel === "objective"}
                onChange={() => setExpandedPanel("objective")}
                onNext={() => handleNext("objective")}
                disabled={!visitedPanels.includes("objective")}
                visited={visitedPanels.includes("objective")}
            >
                <ObjectiveAccordion selectedObjective={selectedObjective} setSelectedObjective={setSelectedObjective} />
            </CampaignAccordion>

            <CampaignAccordion
                title="2. Campaign Settings"
                expanded={expandedPanel === "settings"}
                onChange={() => setExpandedPanel("settings")}
                onNext={() => handleNextWithValidation("settings")}
                disabled={!visitedPanels.includes("settings")}
                visited={visitedPanels.includes("settings")}
            >
                <CampaignSettingsAccordion
                    biddingStrategy={biddingStrategy}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas}
                    setTargetRoas={setTargetRoas}
                    maxCpcLimit={maxCpcLimit}
                    setMaxCpcLimit={setMaxCpcLimit}
                    impressionShare={impressionShare}
                    setImpressionShare={setImpressionShare}
                    adLocation={adLocation}
                    setAdLocation={setAdLocation}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    monthlyBudget={monthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    errors={errors}
                />
            </CampaignAccordion>

            <CampaignAccordion
                title="3. Keywords"
                expanded={expandedPanel === "keywords"}
                onChange={() => setExpandedPanel("keywords")}
                onNext={() => handleNextWithValidation("keywords")}
                disabled={!visitedPanels.includes("keywords")}
                visited={visitedPanels.includes("keywords")}
            >
                <KeywordsAccordion
                    keywords={keywords}
                    setKeywords={setKeywords}
                    negativeKeywords={negativeKeywords}
                    setNegativeKeywords={setNegativeKeywords}
                    errors={errors}
                />
            </CampaignAccordion>

            <CampaignAccordion
                title="4. Ads Creative"
                expanded={expandedPanel === "adsCreative"}
                onChange={() => setExpandedPanel("adsCreative")}
                onNext={() => handleNext("adsCreative")}
                disabled={!visitedPanels.includes("adsCreative")}
                visited={visitedPanels.includes("adsCreative")}
                isCreateButton={true}
            >
                <AdsCreativeAccordion
                    headlines={headlines}
                    setHeadlines={setHeadlines}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    siteLinks={siteLinks}
                    setSiteLinks={setSiteLinks}
                    callOuts={callOuts}
                    setCallOuts={setCallOuts}
                    structuredSnippets={structuredSnippets}
                    setStructuredSnippets={setStructuredSnippets}
                    finalURL={finalURL}
                    setFinalURL={setFinalURL}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    calls={calls}
                    setCalls={setCalls}
                    displayPaths={displayPaths}
                    setDisplayPaths={setDisplayPaths}
                />
            </CampaignAccordion>
        </>
    );
};

export default GoogleSearchAdsCampaignCreator;
