import axios from "axios";
import { APP_GROUP_EDITOR_API_ENDPOINT } from "../../constants/api_urls";

export const fetchHotelsAndGroups = async (idAccount, token) => {
    try {
        const [hotelsResponse, groupsResponse] = await Promise.all([
            axios.get(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/hotels?account_id=${idAccount}`, {
                headers: { Authorization: `Bearer ${token}` },
            }),
            axios.get(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups?account_id=${idAccount}`, {
                headers: { Authorization: `Bearer ${token}` },
            }),
        ]);

        return {
            hotels: hotelsResponse.data,
            groups: groupsResponse.data,
        };
    } catch (error) {
        console.error("Error fetching hotels or groups:", error);
        throw error;
    }
};

export const createGroup = async (groupName, idAccount, token) => {
    try {
        const response = await axios.post(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups`, {
            name: groupName,
            account_id: parseInt(idAccount, 10),
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            if (errors.includes("already exists")) {
                throw new Error("Group already exists.");
            }
        }
        throw new Error("Failed to create group.");
    }
};

export const deleteGroup = async (idAccount, groupId, token) => {
    try {
        await axios.delete(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/${idAccount}/${groupId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return true;
    } catch (error) {
        throw new Error("Failed to delete group.");
    }
};

export const moveHotelsToGroup = async (selectedGroup, selectedHotelsInThird, token, copyBids) => {
    const payload = {
        group_id: selectedGroup.id,
        hotel_ids: selectedHotelsInThird,
        copy_bids: copyBids,
    };

    try {
        await axios.post(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/hotels`, payload, {
            headers: { Authorization: `Bearer ${token}` },
        });
    } catch (error) {
        throw new Error("Failed to move hotels to group.");
    }
};

export const ungroupHotels = async (selectedGroup, selectedHotels, token) => {
    const payload = {
        group_id: selectedGroup.id,
        hotel_ids: selectedHotels,
        copy_bids: false,
    };

    try {
        await axios.delete(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/hotels`, {
            headers: { Authorization: `Bearer ${token}` },
            data: payload,
        });
    } catch (error) {
        throw new Error("Failed to ungroup hotels.");
    }
};

export const updateGroupName = async (groupId, newGroupName, token) => {
    try {
        await axios.put(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups`, {
            name: newGroupName,
            group_id: groupId,
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return true;
    } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
            const errors = error.response.data.errors;
            if (errors.includes("already exists")) {
                throw new Error("Group already exists.");
            }
        }
        throw new Error("Failed to create group.");
    }
};

export const uploadCsv = async (addedHotels, removedHotels, selectedGroup, token, selectedOption) => {
    try {
        if (addedHotels.length > 0) {
            const addPayload = {
                group_id: selectedGroup.id,
                hotel_ids: addedHotels,
                copy_bids: selectedOption === 'useGroupBids',
            };
            await axios.post(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/hotels`, addPayload, {
                headers: { Authorization: `Bearer ${token}` },
            });
        }

        if (removedHotels.length > 0) {
            const removePayload = {
                group_id: selectedGroup.id,
                hotel_ids: removedHotels,
                copy_bids: false,
            };
            await axios.delete(`${APP_GROUP_EDITOR_API_ENDPOINT}/api/groups/hotels`, {
                headers: { Authorization: `Bearer ${token}` },
                data: removePayload,
            });
        }

        return true;
    } catch (error) {
        throw new Error("Failed to upload CSV.");
    }
};
