export const kayakMultiplierFormFields = [
  {
    id: "device_identifier",
    label: "Device",
    type: "select",
    placeholder: "Select a device",
    options: [
      {
        id: "mobile",
        label: "Mobile",
        value: "mobile",
      },
      {
        id: "desktop",
        label: "Desktop",
        value: "desktop",
      },
    ],
  },
  {
    id: "numberOfNights",
    label: "Number of nights",
    type: "range",
    placeholder: "Please enter a value",
  },
  {
    id: "empty",
    label: "",
    type: "empty",
    placeholder: "",
  },
  {
    id: "multiplierValue",
    label: "Multiplier Value",
    type: "textField",
    isAdder: true,
    placeholder: "Please enter a value",
  },
  {
    id: "days",
    label: "Days",
    type: "select",
    placeholder: "Select a day",
    options: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8-14",
      "15-19",
      "30+",
    ].map((val) => ({ id: val, label: val, value: val })),
  },
  {
    id: "bidMultiplier",
    label: "Bid Multiplier",
    type: "textField",
    isAdder: true,
    placeholder: "Please enter a value",
  },
  {
    id: "STAY_EXCLUDE_SATURDAY_NIGHT",
    label: "Stay exclude Saturday night",
    type: "textField",
    placeholder: "Please enter a value",
    numberUnit: "",
  },
  {
    id: "STAY_INCLUDE_SATURDAY_NIGHT",
    label: "Stay include Saturday night",
    type: "textField",
    placeholder: "Please enter a value",
    numberUnit: "",
  },
];

export const kayakButtons = [
  {
    id: "lengthOfStay",
    label: "Length of stay",
    value: "LENGTH_OF_STAY",
  },
  {
    id: "weekendStay",
    label: "Weekend Stay",
    value: "WEEK_END_STAY",
  },
  {
    id: "daysToArrival",
    label: "Days to arrival",
    value: "DAYS_TO_ARRIVAL",
  },
];

export const allFields = {
  lengthOfStay: [
    "device_identifier",
    "numberOfNights",
    "empty",
    "multiplierValue",
  ],

  daysToArrival: ["device_identifier", "empty", "days", "bidMultiplier"],
  weekendStay: [
    "device_identifier",
    "empty",
    "STAY_INCLUDE_SATURDAY_NIGHT",
    "STAY_EXCLUDE_SATURDAY_NIGHT",
  ],
};
