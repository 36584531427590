// HotelsAndGroupsSelection.jsx
import React, { useState, useEffect } from 'react';
import HotelListModal from "../Modal/HotelsListModal";
import './HotelsAndGroupsSelection.scss';
import { useParams } from "react-router-dom";
import axios from "axios";
import BiddingRuleManager from "../BiddingRule/BiddingRuleManager";
import SlidingPanel from "../SlidingPanel";
import '../BiddingSelectionForm/BiddingSelectionForm.scss';
import Icons from "components/DmpIcons/icons";
import {APP_BIDDING_CENTER_API_ENDPOINT} from "../../../constants/api_urls";

const HotelsAndGroupsSelection = ({ position, rule, onClose, setIsSlidingPanelOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]);
    const { idaccount } = useParams();

    const { top, left } = position;

    const token = sessionStorage.getItem('token');

    const handleCloseModal = () => {
        setIsModalOpen(false);
        onClose();
    };

    const hotelColumns = [
        { accessorKey: 'id', header: 'ID' },
        { accessorKey: 'name', header: 'Hotel Name' },
    ];

    const groupColumns = [
        { accessorKey: 'id', header: 'ID' },
        { accessorKey: 'name', header: 'Group Name' },
    ];

    const handleLinkedHotelsClick = () => {
        setIsModalOpen(true);
        fetchData(); // Charger les deux tables
    };

    const toggleEditForm = () => {
        const newState = !isEditFormOpen;
        setIsEditFormOpen(newState);
        setIsSlidingPanelOpen(newState);
    };

    const fetchData = async () => {
        try {
            const [hotelsResponse, groupsResponse] = await Promise.all([
                axios.get(
                    `${APP_BIDDING_CENTER_API_ENDPOINT}/api/get-linked-hotels?id=${rule.id}&id_account=${idaccount}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                ),
                axios.get(
                    `${APP_BIDDING_CENTER_API_ENDPOINT}/api/get-linked-groups?id=${rule.id}&id_account=${idaccount}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    }
                )
            ]);
            setHotels(hotelsResponse.data);
            setGroups(groupsResponse.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        }
    };

    return (
        <>
            <div
                className="hotels-and-groups-selection"
                style={{
                    position: 'fixed',
                    top: top,
                    left: left,
                    zIndex: 1000
                }}
            >
                <ul>
                    <li
                        onClick={toggleEditForm}
                        style={{ cursor: 'pointer' }}
                    >
                        Edit
                    </li>
                    <li
                        onClick={handleLinkedHotelsClick}
                        style={{ cursor: 'pointer' }}
                    >
                        Linked Hotels
                    </li>
                </ul>
            </div>
            <SlidingPanel
                isOpen={isEditFormOpen}
                onClose={handleCloseModal}
            >
                <div className="bidding-panel-container">
                    <div className="platform-container" style={{ width: '100%' }}>
                        <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <div
                                onClick={handleCloseModal}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '28px', // 24px (image) + 5px padding de chaque côté
                                    height: '28px',
                                    borderRadius: '50%',
                                    border: '1px solid #000',
                                    padding: '5px',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    boxSizing: 'border-box' // Assure que les dimensions incluent le padding et la bordure
                                }}
                            >
                                <img
                                    src={Icons.ActionsIconsSVG.CloseIconSVG}
                                    alt="Close"
                                />
                            </div>
                            <h2 style={{
                                fontSize: '20px',
                                textAlign: 'left',
                                fontWeight: 400,
                                fontFamily: 'Quincy CF, serif',
                            }}>
                                Edit Bidding Rule
                            </h2>
                        </div>
                        <div className="form-container">
                            <div className="platform-container">
                                <div className="collapsible-form">
                                    <div className="bidding-center-collapsible-header">
                                        <h2 className="platform-title">1. Edit Bidding Rule</h2>
                                        <span className="arrow-icon">
                                            <img src={Icons.ActionsIconsSVG.UpArrowIconSVG} alt="Arrow Up" /> : <img src={Icons.ActionsIconsSVG.DownArrowIconSVG} alt="Arrow Down" />
                                        </span>
                                    </div>
                                    <BiddingRuleManager
                                        biddingRuleData={rule}
                                        action="rule-action"
                                        actionType="edit"
                                        onClose={handleCloseModal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SlidingPanel>
            {isModalOpen && (
                <HotelListModal
                    open={isModalOpen}
                    handleClose={handleCloseModal}
                    hotels={hotels}
                    groups={groups}
                    hotelColumns={hotelColumns}
                    groupColumns={groupColumns}
                />
            )}
        </>
    );
};

export default HotelsAndGroupsSelection;
