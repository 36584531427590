import React from "react";
import { Typography, Box, IconButton, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import InfoIcon from "@mui/icons-material/Info";

const CallsSection = ({ calls, addCall, handleInputChange, handleRemove }) => {
    return (
        <Grid item md={6} xs={6} spacing={2}>
            <Box className="calls-section" mt={4}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className="header-box">
                    <Typography variant="subtitle1">Calls</Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        <IconButton onClick={addCall} color="primary" className="add-button">
                            <AddIcon />
                        </IconButton>
                        <IconButton className="outlined-info-button">
                            <InfoIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box className="content-box" style={{ marginLeft: "15px" }}>
                    {calls.map((call) => (
                        <Box key={call.id} display="flex" alignItems="center" gap={2} mt={2}>
                            <PhoneInput
                                className="phone-input-field"
                                placeholder="Enter phone number"
                                value={call.phoneNumber}
                                onChange={(value) => handleInputChange(call.id, 'phoneNumber', value)}
                                international
                            />
                            <IconButton
                                onClick={() => handleRemove(call.id)}
                                color="primary"
                                className="remove-button"
                                style={{ visibility: call.irremovable ? 'hidden' : 'initial' }}

                            >
                                <RemoveIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Grid>
    );
};

export default CallsSection;
