import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { MaterialReactTable } from 'material-react-table';
import AlertTitle from 'features/alert-center/AlertTitle/index';
import AlertSelectionForm from 'features/alert-center/AlertSelectionForm/index';
import './AlertTable.scss';
import AlertManager from "../AlertForm/AlertManager";
import AlertActionMenu from 'features/alert-center/AlertActionMenu';
import SlidingPanel from "../AlertForm/SlidingPanel/index";
import { formatAlertData } from '../Functions';
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";
import DmpIcons from "components/DmpIcons/icons";
import SearchTableInput from "../../../components/SearchTableInput/SearchTableInput";

const AlertTable = ({ idaccount, columns, jwtToken, menuVisible, menuPosition, alertData, onCloseMenu, menuRef }) => {

    const [alerts, setAlerts] = useState([]);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const [isAlertFormVisible, setIsAlertFormVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(menuVisible);
    const [isEditPanelOpen, setIsEditPanelOpen] = useState(false);
    const [isFormVisible, setIsFormVisible] = useState(true);

    const handleTogglePanel = () => {
        setIsEditPanelOpen((prev) => !prev);
    };

    const handleFormVisibility = () => {
        setIsFormVisible((prev) => !prev);
    };

    const handleCloseMenu = () => {
        setIsMenuVisible(false);
    };

    const handleAlertTypeSelect = (alertType) => {
        setSelectedAlert(alertType);
        setIsAlertFormVisible(false);
    };

    const handleShowAlertForm = () => setIsAlertFormVisible(true);
    const handleHideAlertForm = () => setIsAlertFormVisible(false);
    const toggleAlertForm = () => setIsAlertFormVisible((prev) => !prev);

    const handleEditAlert = (alertData) => {
        setSelectedAlert(alertData);
        setIsEditPanelOpen(true);
        setIsMenuVisible(false);
    };

    const fetchAlerts = async () => {
        try {
            const response = await fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center/list/${idaccount}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const data = await response.json();
            const formattedData = formatAlertData(data);

            setAlerts(formattedData);
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, [idaccount]);

    return (
        <div className="container">
            <div className="main-content">
                <div className="content">
                    <AlertTitle onShowAlertForm={toggleAlertForm} />
                    <div className="table-container">
                        <MaterialReactTable
                            initialState={{
                                density: 'compact',
                                pagination: {
                                    pageIndex: 0,
                                    pageSize: 7,
                                },
                            }}
                            columns={columns}
                            data={alerts}
                            enableFullScreenToggle={false}
                            enableGlobalFilter={true}
                            enableDensityToggle={false}
                            enableHiding={false}
                            enableStickyHeader={true}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            renderGlobalFilter={() => null}
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <SearchTableInput
                                        placeholder={"Search"}
                                        onChange={(e) => table.setGlobalFilter(e.target.value)}
                                        />
                                </Box>
                            )}
                            enableToolbarInternalActions={false}
                            localization={{
                                noRecordsToDisplay: 'Aucun enregistrement disponible',
                            }}
                            muiTableContainerProps={{ sx: { maxHeight: '450px' } }}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [],
                            }}
                            muiTopToolbarProps={{
                                sx: {
                                    background: '#EBEBEB',
                                    minHeight: '30px',
                                    padding: '0 8px',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    '& .MuiToolbar-root': {
                                        minHeight: '46px',
                                        padding: '0',
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                    },
                                    '& .MuiButtonBase-root': {
                                        minWidth: '36px',
                                        minHeight: '36px',
                                        padding: '0',
                                    },
                                    '& .MuiIconButton-root': {
                                        padding: '8px',
                                    },
                                },
                            }}
                            muiTablePaperProps={{
                                sx: {
                                    boxShadow: 'none',
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    padding: '4px 8px',
                                    fontSize: '10px',
                                    fontWeight: 400,
                                },
                            }}
                            muiTableHeadCellProps={{
                                sx: {
                                    padding: '4px 8px',
                                    fontSize: '0.75rem',
                                },
                            }}
                            muiTableBodyRowProps={({ row }) => ({
                                sx: {
                                    height: '30px',
                                    color: row.original.line_disabled ? 'gray' : '#000000',
                                },
                            })}
                            muiTableBodyCellProps={({ cell }) => ({
                                sx: {
                                    fontSize: '12px',
                                    color: cell.row.original.line_disabled ? 'gray' : '#000000',
                                },
                            })}
                            sx={{
                                '& .MuiTableCell-root': {
                                    fontSize: '0.65rem',
                                    padding: '4px 8px',
                                },
                                '& .MuiTableRow-root': {
                                    height: '40px',
                                },
                            }}
                        />


                        <AlertActionMenu
                            show={menuVisible}
                            position={menuPosition}
                            alertData={alertData}
                            onEdit={handleEditAlert}
                            onDelete={fetchAlerts}
                            onClose={onCloseMenu}
                            menuRef={menuRef}
                        />
                    </div>
                </div>
            </div>
            {isAlertFormVisible && (
                <div className="dropdown-menu">
                    <AlertSelectionForm
                        onAlertTypeSelect={handleAlertTypeSelect}
                        onCancel={handleHideAlertForm}
                    />
                </div>
            )}
            <SlidingPanel isOpen={isEditPanelOpen} onClose={handleTogglePanel}>
                <div className="alert-panel-container">
                    <div className="alert-form-container">
                        <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <div
                                onClick={handleTogglePanel}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '50%',
                                    border: '1px solid #000',
                                    padding: '5px',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    boxSizing: 'border-box'
                                }}
                            >
                                <img
                                    src={DmpIcons.ActionsIconsSVG.ClosePanelIconSVG}
                                    alt="Add"
                                />
                            </div>
                            <h2 style={{
                                fontSize: '20px',
                                textAlign: 'left',
                                fontWeight: 400,
                                fontFamily: 'Quincy CF, serif',
                            }}>
                                Edit - {selectedAlert ? selectedAlert.name : ''}
                            </h2>
                        </div>
                        <div className="platform-container">
                            <div className="collapsible-form">
                                <div className="collapsible-header" onClick={handleFormVisibility}>
                                    <div className="header" style={{ display: 'flex', justifyContent: 'flex-start'}}>
                                        <h2 className="platform-title">1. Update Existing Fields</h2>
                                    </div>
                                    <span className="arrow-icon">{isFormVisible ? '▲' : '▼'}</span>
                                </div>
                                {isFormVisible && (
                                    <AlertManager onClose={handleTogglePanel} alertData={selectedAlert} actionType={"edit"} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </SlidingPanel>
        </div>
    );
};

export default AlertTable;
