import React, { useState } from "react";
import { Box, Typography, Snackbar, Alert } from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import "./SearchAdsCampaign.scss";
import GoogleSearchAdsCampaignCreator from "../../features/google-search-ads-campaign/GoogleSearchAdsCampaignCreator";
import { initialMonthlyBudget } from "./utils/initialState";
import {
    validateSettings,
    validateSettingsKeyword,
    validateAdsCreative
} from "./utils/validators";

const SearchAdsCampaignCreator = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [expandedPanel, setExpandedPanel] = useState("objective");
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [visitedPanels, setVisitedPanels] = useState(["objective"]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [targetValue, setTargetValue] = useState("");
    const [biddingStrategy, setBiddingStrategy] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [targetCpa, setTargetCpa] = useState("");
    const [targetRoas, setTargetRoas] = useState("");
    const [maxCpcLimit, setMaxCpcLimit] = useState("");
    const [impressionShare, setImpressionShare] = useState("");
    const [adLocation, setAdLocation] = useState("");
    const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
    const [keywords, setKeywords] = useState([]);
    const [negativeKeywords, setNegativeKeywords] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [siteLinks, setSiteLinks] = useState([]);
    const [callOuts, setCallOuts] = useState([]);
    const [structuredSnippets, setStructuredSnippets] = useState([]);
    const [finalURL, setFinalURL] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [calls, setCalls] = useState([{ id: 'default-call', phoneNumber: '', irremovable: true }]);
    const [displayPaths, setDisplayPaths] = useState([
        { id: 'default-path-1', text: '', irremovable: true },
        { id: 'default-path-2', text: '', irremovable: true }
    ]);


    const handleNext = (currentPanel) => {
        if (currentPanel === "objective") {
            if (!selectedObjective) {
                setMessage("Please select an objective before proceeding.");
                setSnackbarOpen(true);
                return;
            }
            setExpandedPanel("settings");
            setVisitedPanels((prev) => [...prev, "settings"]);
        } else if (currentPanel === "settings") {
            if (!validateSettings({
                selectedCountries, selectedLanguage, targetValue, campaignName, biddingStrategy,
                targetCpa, targetRoas, maxCpcLimit, impressionShare, adLocation, setMessage, setSnackbarOpen
            })) return;

            setExpandedPanel("keywords");
            setVisitedPanels((prev) => [...prev, "keywords"]);
        } else if (currentPanel === "keywords") {
            if (!validateSettingsKeyword({
                keywords, negativeKeywords, setMessage, setSnackbarOpen
            })) return;

            setExpandedPanel("adsCreative");
            setVisitedPanels((prev) => [...prev, "adsCreative"]);
        } else if (currentPanel === "adsCreative") {
            if (!validateAdsCreative({
                finalURL, businessName, headlines, descriptions, siteLinks, callOuts, structuredSnippets,
                calls, displayPaths, setMessage, setSnackbarOpen
            })) return;

            handleCreateAction();
        }
    };

    const handleCreateAction = () => {
        if (
            validateSettings({
                selectedCountries, selectedLanguage, targetValue, campaignName, biddingStrategy,
                targetCpa, targetRoas, maxCpcLimit, impressionShare, adLocation, setMessage, setSnackbarOpen
            }) &&
            validateSettingsKeyword({
                keywords, negativeKeywords, setMessage, setSnackbarOpen
            }) &&
            validateAdsCreative({
                finalURL, businessName, headlines, descriptions, siteLinks, callOuts, structuredSnippets,
                calls, displayPaths, setMessage, setSnackbarOpen
            })
        ) {
            setMessage("Campaign created successfully!");
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    return (
        <DefaultLayout>
            <Box className="searchAdsContainer">
                <Box className="headerContainer">
                    <div className="headingContainer">
                        <Typography className="quency-cf-normal heading">Search Ads</Typography>
                        <div className="campaignManager">Campaign Creator</div>
                    </div>
                </Box>

                <GoogleSearchAdsCampaignCreator
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                    selectedObjective={selectedObjective}
                    setSelectedObjective={setSelectedObjective}
                    visitedPanels={visitedPanels}
                    handleNext={handleNext}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    biddingStrategy={biddingStrategy}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas}
                    setTargetRoas={setTargetRoas}
                    maxCpcLimit={maxCpcLimit}
                    setMaxCpcLimit={setMaxCpcLimit}
                    impressionShare={impressionShare}
                    setImpressionShare={setImpressionShare}
                    adLocation={adLocation}
                    setAdLocation={setAdLocation}
                    monthlyBudget={monthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    negativeKeywords={negativeKeywords}
                    setNegativeKeywords={setNegativeKeywords}
                    headlines={headlines}
                    setHeadlines={setHeadlines}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    siteLinks={siteLinks}
                    setSiteLinks={setSiteLinks}
                    callOuts={callOuts}
                    setCallOuts={setCallOuts}
                    structuredSnippets={structuredSnippets}
                    setStructuredSnippets={setStructuredSnippets}
                    finalURL={finalURL}
                    setFinalURL={setFinalURL}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    calls={calls}
                    setCalls={setCalls}
                    displayPaths={displayPaths}
                    setDisplayPaths={setDisplayPaths}
                />

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </DefaultLayout>
    );
};

export default SearchAdsCampaignCreator;
