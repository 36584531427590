import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import ResetPassword from "./components/Login/ResetPassword/ResetPassword";
import Homepage from "./pages/homepage";
import MetaSearch from "./pages/metasearch";
import LostPassword from "./components/Login/LostPassword";
import AfterEmailSent from "./components/Login/ResetPassword/AfterEmailSent";
import BiddingCenter from "pages/biddingCenter/BiddingCenterPage";
import Groups from "pages/groups";
import BiddingOfflinePage from "pages/bidddingOffline";
import Reservations from "./pages/reservations";
import ProductSignUpPage from "pages/productSignUpPage";
import AlertCenterPage from "pages/alertCenter/AlertCenterPage";
import SubUsersManagement from "./pages/subUsersManagement";
import SearchAdsPage from "./pages/searchadspage";
import ReportCenter from "./pages/reportCenter";
import SamplePage from "./pages/samplePages";
import SearchAdsCampaign from "./pages/searchAdsCampaign/searchAdsCampaign";
import SearchAdsCampaignCreator from "./pages/searchAdsCampaign/searchAdsCampaignCreator";

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/email-sent" element={<AfterEmailSent />} />
                    <Route path="/home" element={<Homepage />} />
                    <Route path="/metasearch" element={<MetaSearch />} />
                    <Route path="/groups/:idAccount" element={<Groups />} />
                    <Route path="/biddingOffline" element={<BiddingOfflinePage />} />
                    <Route path="/reservations/:idaccount" element={<Reservations />} />
                    <Route path="/searchads" element={<SearchAdsPage />} />
                    <Route path="/productSignUpPage" element={<ProductSignUpPage />} />
                    <Route path="/lost-password" element={<LostPassword />} />
                    <Route path="/alert-center/:idaccount" element={<AlertCenterPage />} />
                    <Route path="/bidding-center/:idaccount" element={<BiddingCenter />} />
                    <Route path="/sub-users-management" element={<SubUsersManagement />} />
                    <Route path="/report-center/:idaccount" element={<ReportCenter />} />
                    <Route path="/sample-page" element={<SamplePage />} />
                    <Route path="/search-ads-campaign" element={<SearchAdsCampaign />} />
                    <Route path="/search-ads-campaign/create" element={<SearchAdsCampaignCreator />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
