import React, {useEffect, useState} from 'react';
import {Builder, Query, Utils as QbUtils} from '@react-awesome-query-builder/ui';
import { MuiConfig, MuiWidgets } from '@react-awesome-query-builder/mui';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import { sqlToJsonLogic } from '../../Functions/index';
import '../AlertForm.scss';
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useCallback, useRef } from 'react'
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import trashcanIcon from '../../../../assets/images/AlertCenter/trashcan.svg';
import Icons from "components/DmpIcons/icons";
import CustomFormControl from "../../Dropdown/CustomFormControl";
import {FormControlLabel, FormGroup} from "@mui/material";

const AlertBaseForm = ({ apiEndpoint, alertType, jwtToken, submitCallback, alertData, availableSegments, onClose }) => {
    const [alertName, setAlertName] = useState(alertData?.name || '');
    const [segmentList, setSegmentList] = useState(alertData?.segmentList || []);
    const [alertRecipients, setAlertalertRecipients] = useState(alertData?.recipients || []);
    const [additionnalColumns, setAdditionnalColumns] = useState(alertData?.additionnalColumns || '');
    const [alertPeriod, setAlertPeriod] = useState(alertData?.alertPeriod || 1);
    const [alertCondition, setAlertCondition] = useState(alertData?.alertCondition || null);
    const [tree, setTree] = useState(null);
    const [optionsForAdditionalColumns, setOptionsForAdditionalColumns] = useState([]);
    const tagifyRef = useRef(null);
    const { MuiTextFieldWidget, MuiSelectWidget } = MuiWidgets;

    const circleStyle = {
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        border: '0.5px solid #BAB7B7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });


    const [config, setConfig] = useState({
        ...MuiConfig,
        settings: {
            ...MuiConfig.settings,
            renderIcon: (icon) => {
                console.log("Icons:", icon);

                switch (icon.type) {
                    case 'addRule':
                        return (
                            <div style={circleStyle}>
                                <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Rule" style={{ width: '8px', height: '8px' }} />
                            </div>
                        );
                    case 'addGroup':
                        return (
                            <div style={circleStyle}>
                                <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Rule" style={{ width: '8px', height: '8px' }} />
                            </div>
                        );
                    case 'delGroup':
                        return <img src={trashcanIcon} alt="Delete Group" style={{width: '30px', height: '30px'}}/>;
                    case 'delRule':
                        return <img src={trashcanIcon} alt="Delete Rule" style={{width: '30px', height: '30px'}}/>;
                    default:
                        return <span>{icon.type}</span>;
                }
            },

        },
    });


    useEffect(() => {
        if (alertData) {
            setAlertName(alertData.name || '');
            setSegmentList(alertData.segmentList || []);
            setAlertalertRecipients(alertData.recipients || []);
            setAlertCondition(alertData.alertCondition || null);
            setAdditionnalColumns(alertData.additionnalColumns || '');
            setAlertPeriod(alertData.alertPeriod || '');
        }
    }, [alertData]);

    const handleSegmentChange = (e) => {
        const value = e.target.value;
        if (segmentList.includes(value)) {
            setSegmentList(segmentList.filter(v => v !== value));
        } else {
            setSegmentList([...segmentList, value]);
        }
    };

    useEffect(() => {
        console.log("alertData : " + JSON.stringify(alertData))
        if (alertCondition && config.fields && Object.keys(config.fields).length > 0) {
            const generatedTree = QbUtils.loadFromJsonLogic(sqlToJsonLogic(alertCondition), config);
            setTree(generatedTree);
        } else {
            console.log("tree initialization")
            const emptyTree = QbUtils.checkTree(QbUtils.loadTree({id: QbUtils.uuid(), type: 'group'}), config)
            console.log("emptyTree : " + emptyTree)
            setTree(emptyTree);
        }
    }, [alertData, config]);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                const fields = generateFieldsFromMetrics(result.metric_name || []);
                setConfig(prevConfig => ({
                    ...prevConfig,
                    fields: fields,
                    settings: {
                        ...prevConfig.settings,
                        showNot: false,
                        canReorder: false,
                        groupOperators: ['AND', 'OR'],
                    },
                }));

                setOptionsForAdditionalColumns(result.metric_name || []);
            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchMetrics();
    }, [apiEndpoint, jwtToken]);
    ;


    const generateFieldsFromMetrics = (metrics) => {
        const fields = {};
        metrics.forEach((metric) => {
            const { label, name } = metric;
            fields[name] = {
                label: label,
                type: 'number',
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
                operators: ['equal', 'less', 'greater', 'less_or_equal', 'greater_or_equal', 'increase', 'decrease'],
                widgets: {
                    number: {
                        operators: ['equal', 'less', 'greater', 'less_or_equal', 'greater_or_equal', 'increase', 'decrease'],
                    }
                }
            };
        });
        console.log('Fields:', fields);
        return fields;
    };
    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value);
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value);
        setAlertalertRecipients(updatedRecipients);
    }, []);

    const handleSubmit = async () => {
        console.log("submit triggered")
        const sqlFormat = QbUtils.sqlFormat(tree, config);
        const body = {
            alert_name: alertName,
            segment_list: segmentList,
            alert_recipients: alertRecipients,
            additionnal_columns: (additionnalColumns && additionnalColumns.length > 0)
                ? additionnalColumns.map(column => column.toLowerCase())
                : [],
            alert_condition: sqlFormat,
            alert_period: alertPeriod
        };
        console.log(body)
        if(!body.alert_name || !body.alert_period || body.alert_recipients.length === 0 || !body.alert_condition){
            setSnackbarMessage("Please fill all required fields (*)");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Please fill all required fields (*)");
        }else {
            console.log('Alert data: ', JSON.stringify(body));
            submitCallback(body);
        }
    };


    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        console.log("Updated Tree:", JSON.stringify(QbUtils.getTree(immutableTree), null, 2));
    };

    return (
        <div className="alert-form">
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className="top-side">
                <TextField
                    placeholder="Alert Name:"
                    variant="standard"
                    value={alertName}
                    required
                    onChange={(e) => setAlertName(e.target.value)}
                    sx={{
                        '& ::placeholder': { fontSize: '12px' } ,
                        width: "55%"
                        }}
                />
            </div>

            <div className="bottom-top-side">
                <div className="alert-period-container">
                <label>ALERT PERIOD</label>
                    <CustomFormControl
                        value={alertPeriod}
                        onChange={(e) => setAlertPeriod(e.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}>Select a number</span>
                                );
                            }
                            return selected;
                        }}
                        required
                    >
                        <MenuItem disabled value="">
                            <span style={{
                                fontSize: "0.75rem",
                                color: "#bab7b7",
                                marginLeft: "4px"
                            }}>Select a number</span>
                        </MenuItem>
                        {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                            <MenuItem key={num} value={num}>
                                {num}
                            </MenuItem>
                        ))}
                    </CustomFormControl>


                </div>
                {availableSegments && availableSegments.length > 0 && (
                    <div className="segment-list-container">
                        <div className="segment-list-label">
                            <label>VIEW BY</label>
                        </div>
                        <div className="segment-list-checkboxes">
                            <FormGroup>
                                {availableSegments.includes('DEVICE') && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    paddingBlock: "2px",
                                                    color: "#BAB7B7",
                                                    "&.Mui-checked": {
                                                        color: "#61D5A9",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        height: "14px",
                                                        width: "14px",
                                                    },
                                                }}
                                                onChange={handleSegmentChange}
                                                checked={segmentList?.includes("DEVICE") || false}
                                                value={"DEVICE"}
                                            />
                                        }
                                        sx={{
                                            alignItems: "flex-start",
                                            "& .MuiFormControlLabel-label": {
                                                color: "black",
                                                fontSize: "12px",
                                            },
                                        }}
                                        label="Device"
                                        className="custom-checkbox-label"
                                    />
                                )}
                                {availableSegments.includes('MARKET') && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    paddingBlock: "2px",
                                                    color: "#BAB7B7",
                                                    "&.Mui-checked": {
                                                        color: "#61D5A9",
                                                    },
                                                    "& .MuiSvgIcon-root": {
                                                        height: "14px",
                                                        width: "14px",
                                                    },
                                                }}
                                                onChange={handleSegmentChange}
                                                checked={segmentList?.includes("MARKET") || false}
                                                value={"MARKET"}
                                            />
                                        }
                                        sx={{
                                            alignItems: "flex-start",
                                            "& .MuiFormControlLabel-label": {
                                                color: "black",
                                                fontSize: "12px",
                                            },
                                        }}
                                        label="Market"
                                        className="custom-checkbox-label"
                                    />
                                )}
                                {/* Ajoutez d'autres segments de la même manière */}
                            </FormGroup>
                        </div>
                    </div>

                )}

                <div className="additional-columns-container">
                    <label>ADDITIONAL COLUMNS</label>
                    <CustomFormControl
                        multiple
                        value={additionnalColumns || []}
                        onChange={(event) => setAdditionnalColumns(event.target.value)}
                        displayEmpty
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return (
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}>Choose Columns</span>
                                );
                            }
                            // Map selected names back to labels
                            return selected.map((value) => {
                                const option = optionsForAdditionalColumns.find(opt => opt.name === value);
                                return option ? option.label : value;
                            }).join(', ');
                        }}
                        required
                        size="small"
                        sx={{ m: 1, width: "280px" }}
                    >
                        <MenuItem disabled value="">
            <span style={{
                fontSize: "0.75rem",
                color: "#bab7b7",
                marginLeft: "4px"
            }}>Choose Columns</span>
                        </MenuItem>
                        {optionsForAdditionalColumns.map((option) => (
                            <MenuItem key={option.name} value={option.name}>
                                <Checkbox checked={additionnalColumns.includes(option.name)} />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </CustomFormControl>
                </div>
            </div>

            <div className="query-builder-container">
                <label>METRIC ALERT RULES *</label>
                <div className="query-builder">
                {tree && (
                    <Query
                        {...config}
                        value={tree || null}
                        onChange={onChange}
                        renderBuilder={(props) => <Builder {...props} />}
                    />
                )}
                </div>
            </div>


            <div className="bottom-side">
                <label>RECIPIENTS</label>
                <Tags
                    ref={tagifyRef}
                    whitelist={alertRecipients}
                    placeholder='Recipients:'
                    settings={{
                        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    }}
                    onChange={handleRecipientsChange}
                    defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                />
            </div>
            <div className="button-container">
                <Button
                    title="Create Alert"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        background: "#12794F",
                        color: "white",
                        borderRadius: "12px",
                        height: "24px"
                    }}
                />
                <Button title="Cancel"
                        variant="contained"
                        onClick={onClose}
                        sx={{
                            background: "#BAB7B7",
                            color: "black",
                            borderRadius: "12px",
                            height: "24px"
                        }}
                />
            </div>
        </div>
    );
};

export default AlertBaseForm;
