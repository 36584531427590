import React, { useState } from 'react';
import { TableContainer,Paper,TablePagination,TableSortLabel, Table, TableBody, TableCell, TableHead, TableRow, IconButton,  Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import SlidingPanel from './../SlidingPanel';
import { fetchService } from '../Services/fetchService';
import { REPORT_TYPE_LABELS, DELIVERY_FREQUENCY_LABELS, STATUS_LABELS } from '../Constant/reportMappings';
import { Snackbar, Alert } from '@mui/material';
import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";
import DmpIcons from "../../../components/DmpIcons/icons";
import './styles.scss';

const Listing = ({ reports , onEdit }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedReport, setSelectedReport] = useState(null); 
  const [showForm, setShowForm] = useState(false); 
  const [reportType, setReportType] = useState('');
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeReportId, setActiveReportId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const handleSettingsClick = (event, reportId) => {
    setAnchorEl(event.currentTarget);
    setActiveReportId(reportId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveReportId(null);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchReports = async (API_URL, reportId) => {
    
    try {
      const reportResponse = await fetchService(`${API_URL}?account=1470&report_id=${reportId}`);
      if (reportResponse.success) {
        setSelectedReport(reportResponse.data[0]);
      } else {
        const errorMessage = reportResponse.errors.length > 0
          ? reportResponse.errors[0].message
          : 'Unknown error occurred';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to load reports');
    }
  }

  const handleEdit = (report) => {
    const reportTypeUrls = {
      PERFORMANCE_GLOBAL: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-global/details",
      PERFORMANCE_GOOGLE_FBL: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-google-fbl/details",
      PERFORMANCE_METASEARCH:APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-metasearch/details",
      PERFORMANCE_PMAX: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-pmax/details",
      SP_FUNNEL_TRIPADVISOR: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/details",
      PRICE_COMPETITIVENESS: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/price-competitiveness/details",
      PERFORMANCE_MARKET_PMAX: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-market-google-pmax/details",
      BILLING: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/details",
      RESERVATIONS: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/reservation/details",
      PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/performance-google-missed-opportunity/details",
    };
    
    const detailURL = reportTypeUrls[report.report_type]; 
    if (detailURL) {
      fetchReports(detailURL, report.id);
      setReportType(report.report_type);
      setShowForm(true);
    } else {
      console.error(`Unknown report type: ${report.report_type}`);
    }
  };

  const handleChangeStatus = async (reportId, status) => {
    try {
      if (status == "DELETED") {
        setSelectedReportId(reportId);
        setConfirmOpen(true);
      }
      const reportResponse = await fetchService(`${process.env.REACT_APP_UPDATE_STATUS_URL}?account=1470&report_id=${reportId}&status=${status}`, "PATCH");
      if (reportResponse.success) {
        setSnackbarMessage(`Status of report ID ${reportId} has been updated to ${status}`);
        setSnackbarOpen(true);
      } else {
        const errorMessage = reportResponse.errors.length > 0
          ? reportResponse.errors[0].message
          : 'Unknown error occurred';
        setError(errorMessage);
      }
    } catch (err) {
      setError('Failed to load reports');
    }
  }

  const handleDeleteConfirm = (reportId) => {
    setSelectedReportId(reportId);
    setConfirmOpen(true);
  };

  const handleConfirmClose = (confirmed) => {
    if (confirmed) {
      handleChangeStatus(selectedReportId, "DELETED");
    }
    setConfirmOpen(false);
    setSelectedReportId(null);
  };

  

  // Sorting logic
  const sortedReports = reports.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  // Pagination logic
  const paginatedReports = sortedReports.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <>
      <TableContainer class="report-listing-container" component={Paper}>
        <Table className="report-listing">
          <TableHead>
            <TableRow>
              {['ID', 'Name', 'Action', 'Report Type', 'Metasearch', 'Created Date', 'Frequency', 'Status', 'User'].map((header) => (
                <TableCell key={header}>
                  <TableSortLabel
                    active={orderBy === header.toLowerCase()}
                    direction={orderBy === header.toLowerCase() ? order : 'asc'}
                    onClick={() => handleRequestSort(header.toLowerCase())}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { paginatedReports.length > 0 ? (
              paginatedReports.map((report) => {
                const formattedDate = new Date(report.date_created).toLocaleDateString('en-GB');
                const isRunning = report.status === 'RUNNING';
                const isPaused = report.status === 'PAUSED';
                return (
                  <TableRow key={report.id}>
                    <TableCell>{report.id}</TableCell>
                    <TableCell>{report.name}</TableCell>
                    <TableCell>
                      <IconButton aria-label="settings" onClick={(event) => handleSettingsClick(event, report.id)}>
                        <img src={DmpIcons.ActionsIconsSVG.CogsIconSVG}  alt="Setting" />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && activeReportId === report.id}
                        onClose={handleMenuClose}
                        className="listing-menu-options"
                      >
                        <label variant="body2">Actions</label>
                        <MenuItem onClick={() => { handleEdit(report); handleMenuClose(); }}>
                          Edit
                        </MenuItem>
                        {isRunning ? (
                          <MenuItem onClick={() => { handleChangeStatus(report.id, "PAUSED"); handleMenuClose(); }}>
                            Pause
                          </MenuItem>
                        ) : (
                          <MenuItem onClick={() => { handleChangeStatus(report.id, "RUNNING"); handleMenuClose(); }}>
                            Resume
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => { handleDeleteConfirm(report.id); }}>
                          Delete 
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell>{REPORT_TYPE_LABELS[report.report_type] || report.report_type}</TableCell>
                    <TableCell>{report.meta_search}</TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{DELIVERY_FREQUENCY_LABELS[report.delivery_frequency] || report.delivery_frequency}</TableCell>
                    <TableCell>{STATUS_LABELS[report.status] || report.status}</TableCell>
                    <TableCell>{report.owner_name}</TableCell>
                  </TableRow>
               );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={reports.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            padding: '8px 16px',
            '& .MuiButtonBase-root.MuiPaginationItem-root': {
              backgroundColor: '#F6F6F6',
              border: '1px solid #acabab',
              color: '#545454',
              padding: '6px 12px',
              margin: '0 4px',
              borderRadius: '3px',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#61D5A9',
              color: '#F6F6F6',
              fontWeight: 'bold',
            },
            '& .MuiInputBase-root': {
              backgroundColor: '#F6F6F6',
              border: '1px solid #acabab',
              padding: '4px 8px',
              borderRadius: '3px',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            },
            '& .MuiTypography-root': {
              fontSize: '12px',
              color: '#545454',
              marginRight: '8px',
            },
          }}
        />
        <Dialog open={confirmOpen} onClose={() => handleConfirmClose(false)} >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this report? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmClose(true)} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      </TableContainer>
      <SlidingPanel open={showForm} onClose={() => setShowForm(false)} skipStep1={false} report_type={reportType} reportData={selectedReport} isEdit= {true}  />
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Listing;
