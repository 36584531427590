import * as React from "react";
import Export from "./export";
import CustomTabs from "components/Tabs";
import Import from "./import";
import History from "./history";

export default function BiddingOffline() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CustomTabs
      onChange={handleChange}
      value={value}
      options={[
        {
          id: 0,
          label: "Export",
          content: <Export />,
        },
        {
          id: 1,
          label: "Import",
          content: <Import />,
        },
        {
          id: 2,
          label: "History",
          content: <History />,
        },
      ]}
    />
  );
}
