import {
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
} from "@mui/icons-material";
import {
  FilledInput,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import up from "assets/images/iconincrease.svg";
import down from "assets/images/icondecrease.svg";
const TextInput = ({
  value,
  onChange,
  type,
  sx,
  placeholder,
  disabled,
  numberUnit = "%",
  min = -100,
  max = 100,
  ...props
}) => {
  return (
    <OutlinedInput
      disabled={disabled}
      // label="Size"
      // type={type}
      sx={{
        border: "1px solid #C4C4C4",
        backgroundColor: "white",
        borderRadius: "4px",

        "& .MuiInputBase-input.MuiOutlinedInput-input": {
          padding: "0px",
          paddingBottom: "3px",
          paddingTop: "3px",
          paddingLeft: "4px",
        },
        "&.MuiInputBase-root.Mui-disabled": {
          background: "lightgray",
        },
        // "& input[type=number]::-webkit-inner-spin-button": {
        //   WebkitAppearance: "none",

        //   background:
        //     "#FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center",
        //   width: "1em",
        //   opacity: 1,
        // },
        // "& input[type=number]::-webkit-outer-spin-button": {
        //   opacity: 1,
        // },
        input: {
          "&::placeholder": {
            // <----- Add this.
            fontSize: "12px",
          },
        },
        ...sx,
      }}
      id="filled-size-small"
      variant="outlined"
      value={value}
      type={type === "number" ? "number" : "text"}
      inputProps={{
        itemType: "numeric",
        max,
        min,
      }}
      endAdornment={
        <>
          <span style={{ fontSize: "14px", color: "#BAB7B7" }}>
            {numberUnit}
          </span>
          <span>{props.endAdornment}</span>
        </>
      }
      onChange={onChange}
      size="small"
      placeholder={placeholder}
      {...props}
    />
  );
};
export default TextInput;
