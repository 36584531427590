import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as TripadvisorLogo } from '../../../assets/images/BiddingCenter/tripadvisorLogo.svg';
import { ReactComponent as TrivagoLogo } from '../../../assets/images/BiddingCenter/trivagoLogo.svg';
import { ReactComponent as GoogleHotelAdsLogo } from '../../../assets/images/BiddingCenter/googleHotelAdsLogo.svg';
import './biddingList.scss';
import SettingsIcon from '../../../assets/images/BiddingCenter/settingsGray.svg';
import HotelsAndGroupsSelection from "../HotelsAndGroupsSelection/HotelsAndGroupsSelection";
import Icons from "../../../components/DmpIcons/icons";

const BiddingList = ({ biddingRules }) => {
    const [expandedRule, setExpandedRule] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [selectedRule, setSelectedRule] = useState(null);
    const [isHotelsSelectionOpen, setIsHotelsSelectionOpen] = useState(false);
    const [isSlidingPanelOpen, setIsSlidingPanelOpen] = useState(false);

    const menuRef = useRef(null);
    const settingsIconRefs = useRef({});

    const handleSettingsIconClick = (event, rule) => {
        event.stopPropagation();

        if (selectedRule?.id === rule.id && isHotelsSelectionOpen) {
            // Fermer le menu
            setIsHotelsSelectionOpen(false);
            setSelectedRule(null);
            return;
        }

        const rect = event.currentTarget.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const selectionWidth = 120;
        let left = rect.left;

        if (left + selectionWidth > viewportWidth) {
            left = viewportWidth - selectionWidth - 10;
        }

        setPosition({ top: rect.bottom, left });
        setSelectedRule(rule);
        setIsHotelsSelectionOpen(true);
    };

    const toggleExpand = (ruleId) => {
        setExpandedRule(expandedRule === ruleId ? null : ruleId);
    };

    const getActionPhrase = (bidChangeType) => {
        switch (bidChangeType) {
            case 0: return 'Increase by';
            case 1: return 'Decrease by';
            case 2: return 'Increase by';
            case 3: return 'Decrease by';
            default: return '';
        }
    };

    const generateBiddingUpdate = (steps, bidChangeType, bidChangeValue) => {
        if (!steps || steps.length === 0) return [];
        const action = getActionPhrase(bidChangeType);
        return steps.map(step => {
            const value = bidChangeValue;
            const percentageSymbol = (bidChangeType === 0 || bidChangeType === 1) ? '%' : '';
            return `${action} ${value}${percentageSymbol} from ${step.step_start} to ${step.step_end}`;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const isClickInsideMenu =
                menuRef.current && menuRef.current.contains(event.target);
            const isClickOnSettingsIcon = Object.values(settingsIconRefs.current).some(
                (ref) => ref && ref.contains(event.target)
            );

            if (!isClickInsideMenu && !isClickOnSettingsIcon && !isSlidingPanelOpen) {
                setIsHotelsSelectionOpen(false);
                setSelectedRule(null);
            }
        };

        if (isHotelsSelectionOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isHotelsSelectionOpen, isSlidingPanelOpen]);

    const renderLogo = (biddingRuleType) => {
        switch (biddingRuleType) {
            case "TRIVAGO_BIDDING_RULE":
                return <TrivagoLogo alt="Trivago logo" />;
            case "TRIPADVISOR_BIDDING_RULE":
                return <TripadvisorLogo alt="Tripadvisor logo" />;
            case "GOOGLE_BIDDING_RULE":
                return <GoogleHotelAdsLogo alt="Google Hotel Ads logo" />;
            default:
                return null;
        }
    };

    return (
        <div className="bidding-list">
            {biddingRules.map((rule, index) => {
                const biddingUpdateTriggered = generateBiddingUpdate(
                    rule.steps_rule_triggered,
                    rule.bid_change_type,
                    rule.bid_change
                );

                const biddingUpdateNotTriggered = generateBiddingUpdate(
                    rule.steps_rule_not_triggered,
                    rule.bid_change_type_inv,
                    rule.bid_change_inv
                );

                // Détermine si la section Triggered doit être affichée
                const showTriggeredSection = rule.bid_change !== undefined && rule.bid_change !== null;

                // Détermine si la section Not Triggered doit être affichée
                const showNotTriggeredSection = rule.bid_change_inv !== undefined && rule.bid_change_inv !== null;

                return (
                    <div key={index} className="bidding-rule">
                        <div className="rule-header" onClick={() => toggleExpand(rule.id)}>
                            <div className="source-logo">
                                {renderLogo(rule.bidding_rule_type)}
                            </div>
                            <div className="rule-title">
                                <h3>BIDDING RULE #{rule.id} | {rule.bidding_rule_name}</h3>
                            </div>
                            <div className="expand-icon">
                                {expandedRule === rule.id ? (
                                    <img src={Icons.ActionsIconsSVG.UpArrowIconSVG} alt="arrow up icon" />
                                ) : (
                                    <img src={Icons.ActionsIconsSVG.DownArrowIconSVG} alt="arrow down icon" />
                                )}
                            </div>
                            <div
                                className="settings-icon"
                                ref={el => settingsIconRefs.current[rule.id] = el}
                            >
                                <img
                                    src={SettingsIcon}
                                    alt="Settings"
                                    onClick={(event) => handleSettingsIconClick(event, rule)}
                                />
                            </div>
                        </div>

                        {expandedRule === rule.id && (
                            <div className="rule-details-container">
                                <div className="separator"></div>
                                <div className="rule-details">
                                    <div className="details-first-section">
                                        {/* Contenu éventuel */}
                                    </div>
                                    <div className="details-second-section">
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Metric Comparison:</div>
                                            <div className="bidding-rule-section-content">Last {rule.metrics_compare} days</div>
                                        </div>
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Metric Frequency:</div>
                                            <div className="bidding-rule-section-content">Every {rule.bidding_frequency_daily} days</div>
                                        </div>

                                        {/* Section Bidding Update with Rules Triggered */}
                                        {showTriggeredSection && (
                                            <div className="bidding-update-section">
                                                <div className="bidding-rule-section-title">Bidding Update with Rules Triggered:</div>
                                                <div className="bidding-rule-section-content">
                                                    <div className="base-value">Base value: {rule.bid_change}</div>
                                                    {biddingUpdateTriggered.length > 0 && (
                                                        biddingUpdateTriggered.map((phrase, idx) => (
                                                            <div key={`triggered-${idx}`}>{phrase}</div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="details-third-section">
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Bidding Conditions:</div>
                                            <div className="bidding-rule-section-content">{rule.rules}</div>
                                        </div>
                                        <div className="bidding-rule-section">
                                            <div className="bidding-rule-section-title">Min/Max Value:</div>
                                            <div className="bidding-rule-section-content">{rule.bid_min} / {rule.bid_max}</div>
                                        </div>

                                        {/* Section Bidding Update with Rules Not Triggered */}
                                        {showNotTriggeredSection && (
                                            <div className="bidding-update-section">
                                                <div className="bidding-rule-section-title">Bidding Update with Rules Not Triggered:</div>
                                                <div className="bidding-rule-section-content">
                                                    <div className="base-value">Base value: {rule.bid_change_inv}</div>
                                                    {biddingUpdateNotTriggered.length > 0 && (
                                                        biddingUpdateNotTriggered.map((phrase, idx) => (
                                                            <div key={`nottriggered-${idx}`}>{phrase}</div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="details-fourth-section">
                                        <div className="recipients-container">
                                            <div className="recipients-title">
                                                <div className="bidding-rule-section-title">Recipients:</div>
                                            </div>
                                            <div className="recipients-list">
                                                <div className="bidding-rule-section-content">
                                                    {rule.alert_recipient[0]
                                                        .split(', ')
                                                        .map((email, index) => (
                                                            <div key={index}>{email}</div>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}

            {isHotelsSelectionOpen && selectedRule && (
                <div ref={menuRef}>
                    <HotelsAndGroupsSelection
                        position={position}
                        rule={selectedRule}
                        onClose={() => setIsHotelsSelectionOpen(false)}
                        setIsSlidingPanelOpen={setIsSlidingPanelOpen}
                    />
                </div>
            )}
        </div>
    );
};

export default BiddingList;
