import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DefaultLayout from "layout/defaultLayout";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EditCampaignPanel from "../../features/google-search-ads-campaign/EditCampaignPanel";
import "./SearchAdsCampaign.scss";

const SearchAdsCampaign = () => {
    const navigate = useNavigate();
    const [isPanelOpen, setPanelOpen] = useState(false);

    const handleCreateCampaign = () => {
        navigate("/search-ads-campaign/create");
        sessionStorage.clear();
    };

    const togglePanel = () => {
        setPanelOpen((prev) => !prev);
    };

    return (
        <DefaultLayout page={null}>
            <Box className="searchAdsContainer">
                <Box className="headerContainer">
                    <div className="headingContainer">
                        <Typography className="quency-cf-normal heading">
                            Search Ads
                        </Typography>
                        <div className="campaignManager">
                            Campaign Manager
                        </div>
                    </div>
                    <Button
                        onClick={togglePanel}
                        variant="contained"
                        className="editCampaignButton"
                    >
                        <AddIcon fontSize="small" />
                        Edit Campaign
                    </Button>
                    <Button
                        onClick={handleCreateCampaign}
                        variant="contained"
                        className="newCampaignButton"
                    >
                        <AddIcon fontSize="small" />
                        New Campaign
                    </Button>
                </Box>
            </Box>
            <EditCampaignPanel isOpen={isPanelOpen} onClose={togglePanel} />
        </DefaultLayout>
    );
};

export default SearchAdsCampaign;
