import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import './Modal.scss';

const DeleteConfirmationModal = ({ open, onClose, onConfirm, alertName, loading, error }) => {
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                // Empêcher la fermeture du modal sur backdrop click ou escape key
                if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
                    console.log('Modal close prevented:', reason);
                } else {
                    onClose();
                }
            }}
        >
            <DialogTitle>Delete Alert</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this alert?
                </DialogContentText>

                {/* Message d'erreur en rouge, le cas échéant */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </DialogContent>
            <DialogActions>
                {/* Afficher le loader à la place des boutons en attendant la réponse */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={(event) => {
                                onClose();
                            }}
                            className='cancelBtn'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={(event) => {
                                onConfirm();
                            }}
                            className='deleteBtn'
                        >
                            Delete
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
