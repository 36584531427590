import React from 'react';
import Button from '@mui/material/Button';

const CustomButtonWithIconComponent = ({
         color = 'primary',
         hoverColor,
         width = 'auto',
         height = 'auto',
         borderRadius = '4px',
         title,
         titleColor = 'white',
         icon: Icon,
         iconBorder = '0.5px solid #b0acac',
         iconSize = '14px',
         textStyle = {},
         onClick,
         children,
         ...props
     }) => {
    return (
        <Button
            {...props}
            onClick={onClick}
            title={title}
            sx={{
                backgroundColor: color,
                color: titleColor,
                width: width,
                height: height,
                borderRadius: borderRadius,
                textTransform: 'none',
                transition: 'background-color 0.3s',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                '&:hover': {
                    backgroundColor: hoverColor || color,
                },
            }}
        >
            {Icon && (
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: iconBorder,
                        borderRadius: '50%',
                        width: iconSize,
                        height: iconSize,
                    }}
                >
                    <Icon />
                </span>
            )}
            <span style={textStyle}>{children}</span>
        </Button>
    );
};

export default CustomButtonWithIconComponent;
