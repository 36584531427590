import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { APP_BING_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import { bingBiddingOptionsFormFields } from "./options";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";

const fields = {
  baseBid: "bidding_value",
  bidStrategy: "bidding_type",
};

const Bing = () => {
  const token = sessionStorage.getItem("token") || "";
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState([]);
  const [updateBidPayload, setUpdateBidPayload] = useState({
    account_id: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");
  const { hotel_list = [], group_list = [], can_use_group } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: "-- Select a hotel --",
      options: hotel_list,
    },
  ];

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    if (account_id) {
      setUpdateBidPayload((prev) => ({ ...prev, account_id }));
      axios
        .get(
          `${APP_BING_BIDDING_API_ENDPOINT}/api/bing-hotel-ads/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id]);

  const handleChangeFieldValue = useCallback((field, value) => {
    setUpdateBidPayload((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleSave = useCallback(() => {
    if (!updateBidPayload?.bidding_strategy?.bidding_value) {
      setToastMessage("Inconsistent bid value");
      setToastType("error");
      setShowToast(true);
      return;
    }
    const finalPayload = { ...updateBidPayload };
    if (selectedHotelOrGroupType === "hotels") {
      delete finalPayload?.group_id;
    }
    if (selectedHotelOrGroupType === "groups") {
      delete finalPayload?.hotel_id;
    }
    axios
      .put(
        `${APP_BING_BIDDING_API_ENDPOINT}/api/bing-hotel-ads/update-bids/`,
        finalPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setToastMessage("Updated successfully");
        setToastType("success");
        setShowToast(true);
      })
      .catch((err) => {
        setToastMessage(
          err?.[0]?.errorMessage || err?.message || "Something went wrong"
        );
        setToastType("error");
        setShowToast(true);
      });
  }, [updateBidPayload]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          border: "1px solid #C4C4C4",
                          backgroundColor: "white",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list}
                        allGroups={group_list}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id)
                          );
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL" : "GROUP"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
            <div className="form-grid-item">
              <div className="form-title">THIS APPLIES TO ALL MARKETS</div>
            </div>
          </div>
          <div className="fields-container">
            {bingBiddingOptionsFormFields?.map((field) => {
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={field?.options || []}
                    value={
                      field.id === "baseBid" &&
                      (updateBidPayload?.bidding_strategy?.bidding_value || "")
                    }
                    hotelMenuOpen={openHotelMenu}
                    disabled={
                      field.id === "baseBid" &&
                      !updateBidPayload?.bidding_strategy?.bidding_type
                    }
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    onChange={(val) => {
                      const fieldName = fields[field.id];

                      if (fieldName === "bidding_type") {
                        handleChangeFieldValue("bidding_strategy", {
                          ...updateBidPayload?.bidding_strategy,
                          [fieldName]: val,
                          bidding_value: undefined,
                        });
                      } else {
                        handleChangeFieldValue("bidding_strategy", {
                          ...updateBidPayload?.bidding_strategy,
                          [fieldName]:
                            field.id === "baseBid" ? Number(val) : val,
                        });
                      }
                    }}
                    labelKey={"label"}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {!!updateBidPayload?.hotel_id?.length &&
          !!updateBidPayload?.bidding_strategy?.bidding_type && (
            <div className="save">
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#12794F",
                  borderRadius: "20px",
                  height: "28px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textTransform: "none",
                  color: "white",
                }}
                title="Save"
                onClick={handleSave}
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          )}
      </div>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity={toastType} variant="filled" sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Bing;
