import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./EditCampaignPanel.scss";
import { initialMonthlyBudget } from "../../../pages/searchAdsCampaign/utils/initialState";
import GoogleSearchAdsCampaignEditor from "../GoogleSearchAdsCampaignEditor";

const EditCampaignPanel = ({ isOpen, onClose }) => {
    const [expandedPanel, setExpandedPanel] = useState("settings");
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [visitedPanels, setVisitedPanels] = useState(["settings"]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [targetValue, setTargetValue] = useState("");
    const [biddingStrategy, setBiddingStrategy] = useState("");
    const [campaignName, setCampaignName] = useState("");
    const [targetCpa, setTargetCpa] = useState("");
    const [targetRoas, setTargetRoas] = useState("");
    const [maxCpcLimit, setMaxCpcLimit] = useState("");
    const [impressionShare, setImpressionShare] = useState("");
    const [adLocation, setAdLocation] = useState("");
    const [monthlyBudget, setMonthlyBudget] = useState(initialMonthlyBudget);
    const [keywords, setKeywords] = useState([]);
    const [negativeKeywords, setNegativeKeywords] = useState([]);
    const [headlines, setHeadlines] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [siteLinks, setSiteLinks] = useState([]);
    const [callOuts, setCallOuts] = useState([]);
    const [structuredSnippets, setStructuredSnippets] = useState([]);
    const [finalURL, setFinalURL] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [calls, setCalls] = useState([{ id: 'default-call', phoneNumber: '', irremovable: true }]);
    const [displayPaths, setDisplayPaths] = useState([
        { id: 'default-path-1', text: '', irremovable: true },
        { id: 'default-path-2', text: '', irremovable: true }
    ]);

    const handleNext = (currentPanel) => {
        if (currentPanel === "settings") {
            setExpandedPanel("keywords");
            setVisitedPanels((prev) => [...prev, "keywords"]);
        } else if (currentPanel === "keywords") {
            setExpandedPanel("adsCreative");
            setVisitedPanels((prev) => [...prev, "adsCreative"]);
        } else if (currentPanel === "adsCreative") {
            handleUpdateAction();
        }
    };

    const handleUpdateAction = () => {
        // Update action logic here
    };

    return (
        <Box className={`editCampaignPanel ${isOpen ? "open" : ""}`}>
            <Box className="header">
                <IconButton onClick={onClose} className="closeButton">
                    <CloseIcon fontSize="small" />
                </IconButton>
                <Typography variant="body1" className="title">
                    Edit Campaign
                </Typography>
            </Box>
            <Box className="content">
                <GoogleSearchAdsCampaignEditor
                    expandedPanel={expandedPanel}
                    setExpandedPanel={setExpandedPanel}
                    selectedObjective={selectedObjective}
                    setSelectedObjective={setSelectedObjective}
                    visitedPanels={visitedPanels}
                    handleNext={handleNext}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    targetValue={targetValue}
                    setTargetValue={setTargetValue}
                    biddingStrategy={biddingStrategy}
                    setBiddingStrategy={setBiddingStrategy}
                    campaignName={campaignName}
                    setCampaignName={setCampaignName}
                    targetCpa={targetCpa}
                    setTargetCpa={setTargetCpa}
                    targetRoas={targetRoas}
                    setTargetRoas={setTargetRoas}
                    maxCpcLimit={maxCpcLimit}
                    setMaxCpcLimit={setMaxCpcLimit}
                    impressionShare={impressionShare}
                    setImpressionShare={setImpressionShare}
                    adLocation={adLocation}
                    setAdLocation={setAdLocation}
                    monthlyBudget={monthlyBudget}
                    setMonthlyBudget={setMonthlyBudget}
                    keywords={keywords}
                    setKeywords={setKeywords}
                    negativeKeywords={negativeKeywords}
                    setNegativeKeywords={setNegativeKeywords}
                    headlines={headlines}
                    setHeadlines={setHeadlines}
                    descriptions={descriptions}
                    setDescriptions={setDescriptions}
                    siteLinks={siteLinks}
                    setSiteLinks={setSiteLinks}
                    callOuts={callOuts}
                    setCallOuts={setCallOuts}
                    structuredSnippets={structuredSnippets}
                    setStructuredSnippets={setStructuredSnippets}
                    finalURL={finalURL}
                    setFinalURL={setFinalURL}
                    businessName={businessName}
                    setBusinessName={setBusinessName}
                    calls={calls}
                    setCalls={setCalls}
                    displayPaths={displayPaths}
                    setDisplayPaths={setDisplayPaths}
                    editMode={true}
                />
            </Box>
        </Box>
    );
};

export default EditCampaignPanel;
