import axios from 'axios';
import { REACT_APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT } from '../../constants/api_urls'; // Import API URL constant

class SubUserApi {

    static getToken() {
        return sessionStorage.getItem("token") || "";
    }

    static axiosInstance = axios.create({
        baseURL: REACT_APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${SubUserApi.getToken()}`,
        },
    });

    static getSubUsers = async () => {
        try {
            const response = await SubUserApi.axiosInstance.get('/api/my-sub-users');
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    static getAccessList = async (userId) => {
        try {
            const response = await SubUserApi.axiosInstance.get(`/api/sub-users/access-list/${userId}`);
            return response.data;
        } catch (error) {
            throw error.data.message;
        }
    };

    static createSubUser = async (userData) => {
        try {
            const response = await SubUserApi.axiosInstance.post('/api/users/sub-user', userData);
            return response.data;
        } catch (error) {
            throw error.response.data.error_message;
        }
    };

    static updateSubUser = async (userId, userData) => {
        try {
            const response = await SubUserApi.axiosInstance.put(`/api/sub-users/${userId}`, userData);
            return response.data;
        } catch (error) {
            throw error.response.data.error_message;
        }
    };

    static accessMainUserAccount = async () => {
        try {
            const response = await SubUserApi.axiosInstance.get('/api/my-account-access-list');
            return response.data;
        } catch (error) {
            throw error.data.message;
        }
    };

    static addAccount = async (accountData) => {
        try {
            const response = await SubUserApi.axiosInstance.post('/api/user/access', accountData);
            return response.data;
        } catch (error) {
            throw error.data.message;
        }
    };

    static deleteAccount = async (userId, accountId) => {
        try {
            const response = await SubUserApi.axiosInstance.delete(`/api/user/${userId}/account/${accountId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting account:', error);
            throw error;
        }
    };

    static deleteUser = async (userId) => {
        try {
            const response = await SubUserApi.axiosInstance.delete(`/api/sub-users/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting user:', error);
            throw error;
        }
    };
}

export default SubUserApi;
