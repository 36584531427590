import React, { useState } from 'react';
import { Button } from '@mui/material';
import './HotelsList.scss';
import HotelsTable from '../Utils/Table/HotelsTable';
import MoveSelectionModal from '../Utils/Modal/MoveSelectionModal/MoveSelectionModal';

const HotelsList = ({ mergedHotels, selectedHotelsInThird, setSelectedHotelsInThird, moveHotelsToGroup, selectedGroup, loading, error }) => {
    const [showModal, setShowModal] = useState(false);

    const handleGroupSelection = () => {
        setShowModal(true);
    };

    const handleCheckboxChange = (hotelId) => {
        setSelectedHotelsInThird((prevSelected) =>
            prevSelected.includes(hotelId)
                ? prevSelected.filter(id => id !== hotelId)
                : [...prevSelected, hotelId]
        );
    };

    const handleConfirmMove = async (selectedOption) => {
        try {
            await moveHotelsToGroup(selectedOption);
            setShowModal(false);
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="hotels-list">
            <div className="header">
                <h3>
                    Available Hotels
                    <span className="hotel-count">({mergedHotels.length})</span>
                </h3>

                {/* Group Selection Button */}
                <Button
                    variant="outlined" // Use outlined to get a border
                    onClick={handleGroupSelection}
                    disabled={selectedHotelsInThird.length === 0}
                    className={`${selectedHotelsInThird.length === 0 ? 'disabledGroupBtn' : 'activeGroupBtn'}`}
                >
                    Add To Group
                </Button>
            </div>

            <div className="hotels-table-wrapper">
                <HotelsTable
                    filteredHotels={mergedHotels}
                    selectedHotels={selectedHotelsInThird}
                    handleCheckboxChange={handleCheckboxChange}
                    showGroupName={true}
                    isThirdComponent={true}
                />
            </div>

            {/* Move Selection Modal */}
            <MoveSelectionModal
                open={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleConfirmMove}
                groupName={selectedGroup?.name || 'Selected Group'}
                loading={loading}
                error={error}  // Show error from parent
            />
        </div>
    );
};

export default HotelsList;
