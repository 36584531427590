import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ColumnsChangeIcon from "assets/images/ColumnsChangeIcon.svg";
import { Box, Chip, IconButton } from "@mui/material";
import { useRef, useState } from "react";
import ColumnSelectionMenu from "./ColumnSelectionMenu";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CollapseArrow from "assets/images/collapseArrow.svg";
import CheckboxMenu from "components/CheckboxMenu";
import { segmentMeuOptions } from "mock/filterOptions";
import IconWrapper from "components/IconWrapper";
import edit from "assets/images/edit.svg";
import { CloseOutlined } from "@mui/icons-material";
import { updateSegmentList } from "store/slices/metaSearch/metaSearch";
import { useDispatch } from "react-redux";
const Table = ({
  rows,
  columns,
  selectedColumns,
  handleColumnSelectionChange,
  isChartCollapsed,
  setIsChartCollapsed,
  maxHeight,
  onEditClick,
}) => {
  const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);
  const tableContainerRef = useRef();
  const stickyDivRef = useRef();
  const segmentMenuRef = useRef(null);
  const [segmentSelection, setSegmentSelection] = useState([]);
  const [openSegmentMenu, setOpenSegmentMenu] = useState(false);
  const dispatch = useDispatch();
  const table = useMaterialReactTable({
    columns: selectedColumns
      ?.filter((item) => item.isSelected)
      ?.map((column) => {
        return {
          ...column,
        };
      }),
    data: rows,
    getSubRows: (row) => row.subRows,
    initialState: {
      expanded: true,
      // rowPinning: { top: [rows?.[0]?.id] },
      density: "compact",
    },
    muiTableBodyProps: () => {
      return {
        sx: {
          '& .MuiTableRow-root td[data-pinned="true"]:before': {
            boxShadow: "unset",
            opacity: 1,
          },
          "& .MuiTableRow-root td:after": {
            backgroundColor: "white",
          },
        },
      };
    },

    muiTableHeadCellProps: {
      sx: {
        textAlign: "center",
        paddingBlock: "10px",
        "&:hover": {
          "& .MuiTableSortLabel-icon": {
            opacity: "1 !important",
          },
        },
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "flex-end",
        },
        "& .Mui-TableHeadCell-Content-Labels": {
          flexDirection: "row-reverse",

          display: "inline-flex",
          width: "max-width",
        },
        "& .Mui-TableHeadCell-Content-Wrapper": {
          fontSize: "12px",
          fontWeight: "500",
          fontFamily: "inter",
          color: "#545454",
          display: "inline-block",
          whiteSpace: "nowrap",
          lineHeight: "15px",
        },
        "& .MuiTableSortLabel-icon": {
          flex: 1,
          opacity: "0 !important",
        },
      },
    },

    muiTableBodyCellProps: ({ cell }) => {
      return {
        sx: {
          fontSize: cell.row.depth === 0 ? "11px" : "10px",
          fontFamily: "inter",
          fontWeight: cell.row.depth === 0 ? "700" : "400",
          color: "#545454",
          textAlign: "end",
        },
      };
    },

    muiTableHeadProps: {
      ref: stickyDivRef,
      sx: {
        '& .MuiTableCell-root[data-pinned="true"]:before': {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: "white",
        },
      },
    },

    muiTableBodyRowProps: ({ row }) => {
      return {
        hover: false,
      };
    },
    muiExpandAllButtonProps: {
      sx: {
        display: "none",
      },
    },
    muiTopToolbarProps: {
      sx: {
        alignItems: "center",
        "& .MuiBox-root": {
          paddingBlock: "0px !important",
        },
      },
    },
    enableTopToolbar: false,
    muiBottomToolbarProps: {
      sx: {
        display: "none",
      },
    },

    enableColumnActions: false,
    getRowId: (row) => {
      return row.id;
    },
    enableExpanding: true,
    enableExpandAll: true,
    positionExpandColumn: "last",
    enableColumnPinning: true,
    enableRowPinning: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    rowPinningDisplayMode: "top-and-bottom",

    icons: {
      ExpandMoreIcon: () => (
        <ArrowDropDownIcon
          sx={{
            fontSize: 20,
            paddingInline: "0px",
            margin: "0px",
            alignItems: "center",
          }}
        />
      ),
      ViewColumnIcon: () => (
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 30 }}
        >
          {" "}
          <span style={{ fontSize: "10px", color: "black" }}>Columns</span>{" "}
          <img style={{ maxHeight: "27px" }} src={ColumnsChangeIcon} alt="" />{" "}
        </div>
      ),
    },
    defaultColumn: { maxSize: 60 },
    displayColumnDefOptions: {
      "mrt-row-pin": {
        muiTableHeadCellProps: {
          sx: {
            display: "none",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "none",
          },
        },
      },
      "mrt-row-expand": {
        maxSize: 15,
        muiTableBodyCellProps: ({ cell }) => {
          return {
            sx: {
              '& td[data-pinned="true"]:before': {
                //  backgroundColor: cell.row.depth === 0 ? "#f5f5f5" : "#ffff",
              },
              "& button": {
                padding: "4px",
                display:
                  cell.row.depth !== 0 || cell.row.id === "0" ? "none" : "none",
              },
            },
          };
        },
      },
    },

    enableTableFooter: false,
    enablePagination: false,
    muiTableContainerProps: {
      sx: {
        width: "100%",
        height: "100%",
        maxHeight: maxHeight,

        "&::-webkit-scrollbar": {
          width: "3px",
          height: "3px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },

        "&::-webkit-scrollbar-thumb": {
          background: "#acabab",
          borderRadius: "4px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
          height: "10px",
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
  });
  const handleSegmentDelete = (id) => {
    const tempData = [...segmentSelection];
    const index = tempData.findIndex((i) => i.id === id);
    tempData.splice(index, 1);
    if (index !== -1) {
      setSegmentSelection(tempData);
    }
  };

  console.log(
    columns
      ?.filter((col) => col?.type === "SEGMENT")
      ?.map((c) => c?.accessorKey),
    columns
  );

  return (
    <>
      <div
        style={{
          backgroundColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingInline: "10px",
          paddingBlock: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                color: "#545454",
                fontSize: "10px",
                marginBottom: "5px",
              }}
            >
              View By
            </div>
            <div style={{ display: "flex" }}>
              {segmentSelection?.map((item) => {
                return (
                  <div
                    onClick={(e) => {
                      segmentMenuRef.current = e.target;
                      setOpenSegmentMenu((prev) => !prev);
                    }}
                  >
                    <Chip
                      variant="outlined"
                      sx={{
                        // backgroundColor: "#61D5A9",
                        fontFamily: "inter",
                        fontSize: "8px",
                        fontWeight: "400",
                        height: "22px",
                        marginRight: "8px",
                      }}
                      label={item.label}
                      deleteIcon={
                        <CloseOutlined
                          fontSize="small"
                          sx={{
                            height: "14px",
                            width: "14px",
                            marginTop: "4px",
                          }}
                        />
                      }
                      onDelete={() => {
                        handleSegmentDelete(item.id);
                      }}
                    >
                      {item.label}
                    </Chip>
                  </div>
                );
              })}
              <div
                onClick={(e) => {
                  segmentMenuRef.current = e.target;
                  setOpenSegmentMenu(!openSegmentMenu);
                }}
                style={{ display: "flex", alignItems: "center" }}
                ref={segmentMenuRef}
              >
                <AddCircleOutlineOutlinedIcon
                  sx={{ color: "#BAB7B7", fontSize: 16, marginRight: "5px" }}
                />{" "}
                <span style={{ color: "#BAB7B7", fontSize: "10px" }}>
                  Add segment
                </span>
              </div>
            </div>
          </Box>
        </Box>

        {openSegmentMenu && (
          <CheckboxMenu
            options={segmentMeuOptions?.["skyScanner"]?.map((smo) =>
              segmentSelection.find((item) => item.id === smo.id)
                ? { ...smo, isSelected: true }
                : { ...smo, isSelected: false }
            )}
            open={openSegmentMenu}
            onClose={() => {
              setOpenSegmentMenu(false);
            }}
            onApply={(selection) => {
              setSegmentSelection(selection);
              dispatch(updateSegmentList(selection.map((s) => s.id)));
            }}
            anchorEl={segmentMenuRef.current}
            filterType="Segment"
          />
        )}
        <div style={{ display: "flex" }}>
          <IconWrapper icon={edit} onClick={onEditClick} />
          <IconButton
            disableRipple
            onClick={() => {
              setIsColumnMenuOpen(true);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
              }}
            >
              {" "}
              <img
                style={{ maxHeight: "27px" }}
                src={ColumnsChangeIcon}
                alt=""
              />{" "}
            </div>
          </IconButton>
          <IconButton
            disableRipple
            sx={{
              marginRight: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setIsChartCollapsed(!isChartCollapsed);
            }}
          >
            <img
              src={CollapseArrow}
              style={{
                transform: isChartCollapsed ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </div>
      </div>
      <div ref={tableContainerRef}>
        <MaterialReactTable table={table} />
      </div>
      <ColumnSelectionMenu
        open={isColumnMenuOpen}
        columns={columns}
        handleClose={() => {
          setIsColumnMenuOpen(false);
        }}
        onApply={(columns) => {
          handleColumnSelectionChange(columns);
          setIsColumnMenuOpen(false);
        }}
      />
    </>
  );
};
export default Table;
