import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, IconButton, Grid, InputAdornment } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';

const Section = ({ title, items, addItem, handleInputChange, handleRemove, maxLengthInput = null }) => {
    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        setIsAdded(items.length > 0);
    }, [items.length]);

    const handleAddClick = () => {
        addItem();
    };

    return (
        <Grid item md={6} xs={6} spacing={2}>
            <Box className={title.toLowerCase().replace(/ /g, "-")} mt={4}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className="header-box">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Box display="flex" alignItems="center">
                        {isAdded && (
                            <IconButton onClick={addItem} color="primary" className="add-button">
                                <AddIcon />
                            </IconButton>
                        )}
                        <IconButton className="outlined-info-button">
                            <InfoIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box className="content-box" mt={2}>
                    {!isAdded ? (
                        <Box display="flex" alignItems="center" gap={0} onClick={handleAddClick} style={{ cursor: 'pointer', color: '#61D5A9' }}>
                            <IconButton color="primary" className="add-button-text">
                                <AddIcon />
                            </IconButton>
                            <Typography style={{ textTransform: 'uppercase', color: '#61D5A9', fontSize: '14px', fontWeight: 500 }}>
                                Add {title}
                            </Typography>
                        </Box>
                    ) : (
                        items.map((item, index) => (
                            <Box key={item.id} display="flex" alignItems="center" gap={1} className="input-container">
                                <TextField
                                    label={`${title} ${index + 1}`}
                                    className="no-border-focus"
                                    variant="outlined"
                                    value={item.text}
                                    onChange={(e) => handleInputChange(item.id, 'text', e.target.value)}
                                    fullWidth
                                    inputProps={{ maxLength: maxLengthInput || undefined }}
                                    InputProps={{
                                        endAdornment: maxLengthInput && (
                                            <InputAdornment position="end">
                                                {`${item.text.length}/${maxLengthInput}`}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <IconButton
                                    onClick={() => handleRemove(item.id)}
                                    color="primary"
                                    className="remove-button"
                                    style={{ visibility: item.irremovable ? 'hidden' : 'initial' }}
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default Section;
