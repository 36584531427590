export const validateSettings = ({
    selectedCountries,
    selectedLanguage,
    targetValue,
    campaignName,
    biddingStrategy,
    targetCpa,
    targetRoas,
    maxCpcLimit,
    impressionShare,
    adLocation,
    setMessage,
    setSnackbarOpen
}) => {
    if (selectedCountries.length === 0) {
        setMessage("Please select at least one country.");
        setSnackbarOpen(true);
        return false;
    }
    if (!selectedLanguage) {
        setMessage("Please select a language.");
        setSnackbarOpen(true);
        return false;
    }
    if (!targetValue) {
        setMessage("Please enter a target value.");
        setSnackbarOpen(true);
        return false;
    }
    if (!campaignName || !biddingStrategy) {
        setMessage("Please fill out all required fields in Campaign Settings.");
        setSnackbarOpen(true);
        return false;
    }
    if (biddingStrategy === "MAXIMIZE_CONVERSIONS" && !targetCpa) {
        setMessage("Please enter Target CPA for Maximize Conversions.");
        setSnackbarOpen(true);
        return false;
    }
    if (biddingStrategy === "MAXIMIZE_CONVERSION_VALUE" && !targetRoas) {
        setMessage("Please enter Target ROAS for Maximize Conversion Value.");
        setSnackbarOpen(true);
        return false;
    }
    if (biddingStrategy === "MAXIMIZE_CLICKS" && !maxCpcLimit) {
        setMessage("Please enter Maximum CPC Bid Limit for Maximize Clicks.");
        setSnackbarOpen(true);
        return false;
    }
    if (biddingStrategy === "TARGET_IMPRESSION_SHARE" && (!impressionShare || !adLocation || !maxCpcLimit)) {
        setMessage("Please fill out all fields for Target Impression Share.");
        setSnackbarOpen(true);
        return false;
    }
    return true;
};

export const validateSettingsKeyword = ({ keywords, negativeKeywords, setMessage, setSnackbarOpen }) => {
    if (keywords.some((item) => !item.matchType || !item.keyword)) {
        setMessage("Please fill out all fields for each keyword.");
        setSnackbarOpen(true);
        return false;
    }
    if (negativeKeywords.some((item) => !item.matchType || !item.keyword)) {
        setMessage("Please fill out all fields for each negative keyword.");
        setSnackbarOpen(true);
        return false;
    }
    return true;
};

export const validateAdsCreative = ({
       finalURL,
       businessName,
       headlines,
       descriptions,
       siteLinks,
       callOuts,
       structuredSnippets,
       calls,
       displayPaths,
       setMessage,
       setSnackbarOpen
}) => {

    if (!finalURL || !businessName) {
        setMessage("Please fill out the Final URL and Business Name.");
        setSnackbarOpen(true);
        return false;
    }
    if (headlines.some(item => !item.text)) {
        setMessage("Please fill out all headline fields.");
        setSnackbarOpen(true);
        return false;
    }
    if (descriptions.some(item => !item.text)) {
        setMessage("Please fill out all description fields.");
        setSnackbarOpen(true);
        return false;
    }
    if (siteLinks.some(item => !item.sitelinkText || !item.description1 || !item.description2 || !item.finalURL)) {
        setMessage("Please fill out all fields in each Site Link.");
        setSnackbarOpen(true);
        return false;
    }
    if (callOuts.some(item => !item.text)) {
        setMessage("Please fill out all call-out fields.");
        setSnackbarOpen(true);
        return false;
    }
    if (structuredSnippets.some(item => !item.text)) {
        setMessage("Please fill out all structured snippet fields.");
        setSnackbarOpen(true);
        return false;
    }
    if (calls.some(item => !item.phoneNumber)) {
        setMessage("Please fill out all call phone numbers.");
        setSnackbarOpen(true);
        return false;
    }
    if (displayPaths.some(item => !item.path1 || !item.path2)) {
        setMessage("Please fill out both path fields in each display path.");
        setSnackbarOpen(true);
        return false;
    }
    return true;
};
