import React, { useState } from 'react';
import './GroupsLists.scss';
import Modal from '../Utils/Modal/ActionModal/Modal';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Button, CircularProgress } from '@mui/material';

const GroupsLists = ({ groups, setGroups, onSelectGroup, createGroup, createGroupError, loading }) => {
    const [showModal, setShowModal] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleAddGroupClick = () => setShowModal(true);

    // Filter groups based on the search term
    const filteredGroups = groups.filter(group =>
        group.name && group.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleCloseModal = () => {
        setShowModal(false);
        setGroupName('');
        setErrorMessage('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error message

        if (groupName.trim() === '') {
            setErrorMessage('Group name is required');
            return;
        }

        const success = await createGroup(groupName);
        if (success) {
            handleCloseModal(); // Close the modal if the group creation is successful
        } else {
            setErrorMessage(createGroupError || 'Failed to create group. Please try again.');
        }
    };

    const handleGroupClick = (group) => {
        if (onSelectGroup && group) {
            onSelectGroup(group);
        }
    };

    return (
        <div className="groups-list-container">
            <div className="groups-header">
                <h3>Groups</h3>
                <IconButton aria-label="add-group" onClick={handleAddGroupClick}>
                    <AddIcon />
                </IconButton>
            </div>

            <div className="filter-group">
                <input
                    type="text"
                    placeholder="Search Groups"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-groups-input"
                />
            </div>

            <ul className="groups-list">
                {filteredGroups.length === 0 ? (
                    <li>No groups found</li>
                ) : (
                    filteredGroups.map((group, index) => (
                        <li key={group.id || index} onClick={() => handleGroupClick(group)}>
                            {group.name || 'Unnamed Group'}
                        </li>
                    ))
                )}
            </ul>

            {showModal && (
                <Modal onClose={handleCloseModal}>
                    <form className="group-form" onSubmit={handleSubmit}>
                        <h3>Create New Hotel Group</h3>
                        <label htmlFor="groupName">Group Name</label>
                        <input
                            type="text"
                            id="groupName"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                            required
                        />

                        {/* Error message shown in red when there is an error */}
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                        <div className="modal-actions">
                            {/* Show loader while waiting for API call */}
                            {loading ? (
                                <div className="loader-modal">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    <Button variant="outlined" onClick={handleCloseModal} className="cancelSubmitBtn">
                                        Cancel
                                    </Button>
                                    <Button variant="outlined" type="submit" className="activeSubmitBtn">
                                        Create
                                    </Button>
                                </>
                            )}
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default GroupsLists;
