import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import './HotelsTable.scss';

const HotelsTable = ({ filteredHotels, selectedHotels, handleCheckboxChange, showGroupName, isThirdComponent }) => {
    // Define the columns with different sizes for the third component
    const columns = [
        {
            accessorKey: 'id',
            header: 'Hotel ID',
            size: isThirdComponent ? 20 : 50, // Smaller size for the third component
        },
        {
            accessorKey: 'name',
            header: 'Hotel Name',
            size: isThirdComponent ? 50 : 100, // Smaller size for the third component
        },
        {
            accessorKey: 'country',
            header: 'Country',
            size: isThirdComponent ? 30 : 50, // Smaller size for the third component
        },
    ];

    // Conditionally add the "Group Name" column if needed
    if (showGroupName) {
        columns.push({
            accessorKey: 'groupName',
            header: 'Group Name',
            size: isThirdComponent ? 50 : 100, // Adjust the size of the Group Name column
            Cell: ({ cell }) => cell.getValue() || 'Ungrouped',
        });
    }

    // Add the custom checkbox column
    const checkboxColumn = {
        id: 'select',
        header: '',
        enableColumnActions: false,
        enableSorting: false,
        enableFilters: false,
        size: isThirdComponent ? 15 : 20, // Further reduced size for the checkbox column
        Cell: ({ row }) => (
            <input
                type="checkbox"
                checked={selectedHotels.includes(row.original.id)}
                onChange={() => handleCheckboxChange(row.original.id)}
                style={{ marginTop: "5px", marginLeft: '5px', marginRight: '5px' }} // Custom margins for smaller space between columns
            />
        ),
    };

    // Prepend the checkbox column to the beginning
    columns.unshift(checkboxColumn);

    return (
        <div className="custom-hotels-table-container">
            <MaterialReactTable
                columns={columns}
                data={filteredHotels}
                enablePagination={filteredHotels.length > 10}
                enableSorting
                enableColumnFilters
                enableRowSelection={false} // We handle row selection via the checkbox column
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnActions={false}
                enableHiding={false}
                initialState={{ pagination: { pageSize: 10 } }} // Set default page size to 10
                muiTablePaginationProps={{
                    rowsPerPageOptions: [], // Disable rows per page options
                }}
                muiTableContainerProps={{
                    sx: {
                        maxWidth: '100%', // Prevent the table from exceeding the container width
                        width: '100%', // Ensure it takes the full available width
                        overflowX: 'hidden', // Prevent horizontal scrolling
                        overflowY: 'auto', // Allow vertical scrolling if needed
                    },
                }}
                muiTablePaperProps={{
                    sx: {
                        boxShadow: 'none', // Remove outer box shadow
                        border: 'none', // Remove outer border
                        width: '100%', // Ensure it takes the full width of the container
                    },
                }}
                muiTableBodyProps={{
                    sx: {
                        '& tr': {
                            height: '47px', // Set row height to 35px
                        },
                        '& td': {
                            padding: '0 16px', // Adjust padding to control cell spacing
                            lineHeight: '47px', // Set line height to match row height
                            whiteSpace: 'nowrap', // Prevent text wrapping
                            overflow: 'hidden', // Hide overflow content
                            textOverflow: 'ellipsis', // Display ellipsis for overflow text
                        },
                    },
                }}
            />
        </div>
    );
};

export default HotelsTable;
