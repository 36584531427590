  import React, { useMemo, useState, useEffect } from 'react';
  import { useParams } from "react-router-dom";
  import { Grid, TextField, Select, MenuItem, Button, Typography, Checkbox,Snackbar,Alert,  FormControl, FormHelperText,  Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle  } from '@mui/material';
  import { validateReportForm } from '../../validation/reportValidation';
  import { sanitizeString } from '../../../../utils/functions/sanitize';
  import SettingsIcon from '@mui/icons-material/Settings';
  import { fetchService } from '../../Services/fetchService';
  import { MaterialReactTable } from "material-react-table";
  import ERROR_MESSAGES from '../../Constant/errorMessages';
  import TagifyInput from '../../TagifyInput';
  import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
  import '../../reportCenter.scss'

  const Billing = ({ formData,  setShowForm, onClose, isEdit= false }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);  
    const [snackbarMessage, setSnackbarMessage] = useState('');  
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [timezones, setTimezones] = useState([]);
    const [ftp, setftp] = useState([]);
    const [whiteLabelEmail, setWhiteLabelEmail] = useState([]);
    const [errors, setErrors] = useState({});
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    const [hotels, setHotels] = useState([]);
    const [groups, setGroups] = useState([]); 
    const [userinfo, setUserinfo] = useState([]); 
    const [selectedHotels, setSelectedHotels] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]); 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [tempSelectedGroups, setTempSelectedGroups] = useState([]);
    const [tempSelectedHotels, setTempSelectedHotels] = useState([]);
    const [subjectTags, setSubjectTags] = useState('');
    const token = sessionStorage.getItem('token');
    const { idaccount } = useParams();

    const [reportData, setReportData] = useState({
      name: formData?.name || "",
      report_id: formData?.report_id || "",
      account: idaccount,
      level: formData?.level || "",
      level_choices: formData?.level_choices || [],
      delivery_frequency: formData?.delivery_frequency || "",
      day_month: formData?.day_month || "",
      export_method: formData?.export_method || "",
      delevery_method_ftp:formData?.delevery_method_ftp || "",
      folder: formData?.folder || "",
      recipients :formData?.recipients || [],
      subject: formData?.subject || "",
      export_name: formData?.export_name || "",
      export_file_type: formData?.export_file_type || "",
      attached_file: formData?.attached_file || "",
      whitelabel_email : formData?.whitelabel_email || "",
    });

    const handleCreateReport = async () => {
      const sanitizedData = {
        report_id: reportData.id,
        account: parseInt(idaccount, 10),
        name: sanitizeString(reportData.name),
        level: sanitizeString(reportData.level),
        level_choices: reportData.level_choices,
        delivery_frequency: reportData.delivery_frequency,
        day_month: reportData.day_month,
        export_method: reportData.export_method,
        delevery_method_ftp:reportData.delevery_method_ftp,
        folder: reportData.folder,
        recipients :reportData.recipients,
        subject: reportData.subject,
        export_name: reportData.export_name,
        export_file_type: reportData.export_file_type,
        attached_file: reportData.attached_file,
        whitelabel_email : reportData.whitelabel_email,
      };
      
      const validationErrors = validateReportForm(sanitizedData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);  
      }

      try {
        const API_URL = isEdit ? APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/edit" : APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/billing/add";
        const API_METHOD = isEdit ? "PATCH" : "POST";
        const API_RESPONSE = isEdit ? "RESOURCE_UPDATED" : "RESOURCE_CREATED";
        const API_MESSAGE = isEdit ? "updated" : "created";     

        const response = await fetch(API_URL, {
          method: API_METHOD,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sanitizedData),
        });

        const data = await response.json();
        if (data.status_code === 200 && data.data.message === API_RESPONSE) {
          setSnackbarSeverity('success');
          setSnackbarMessage(`Report ${API_MESSAGE} successfully! Report ID: ${data.data.report_id}`);
          setSnackbarOpen(true);
        } else if (data.error && data.error.length > 0) {
          const fieldErrors = {};
          data.error.forEach((err) => {
            const translatedMessage = ERROR_MESSAGES[err.message] || err.message;
            if(err.property_path) {
              fieldErrors[err.property_path] = translatedMessage;
              if (err.property_path === 'level_choices') {
                fieldErrors['level'] = translatedMessage;
              }
            } else {
              fieldErrors["unspecified"] = translatedMessage;
            }
              
          });
          setErrors(fieldErrors);
          setSnackbarOpen(false);
        }
      } catch (error) {
        console.error('Error creating report:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('Something went wrong while creating the report.');
        setSnackbarOpen(true);
      }
    }

    useEffect(() => {
      const handleError = (errMessage) => {
        setErrors(errMessage);
      };
    
      const fetchTimezones = async () => {
        try {
          const response = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/timezone/list`);
          if (response.success) {
            setTimezones(response.data);
          } else {
            handleError('Failed to load timezones');
          }
        } catch (err) {
          handleError('Failed to load timezones');
        }
      };
    
      const fetchFtp = async () => {
        const url =  `${APP_REPORT_CENTER_API_ENDPOINT}/api/ftp/list?account=${idaccount}`
        try {
          const response = await fetchService(url);
          if (response.success) {
            setftp(response.data);
          } else {
            handleError('Failed to load FTP data');
          }
        } catch (err) {
          handleError('Failed to load FTP data');
        }
      };

      const fetchHotels = async () => {
        const url =  `${APP_REPORT_CENTER_API_ENDPOINT}/api/hotel/list?account=${idaccount}`;
        try {
          const response = await fetchService(url);
          if (response.success) {
            setHotels(response.data);
          } else {
            handleError('Failed to load Hotels data');
          }
        } catch (err) {
          handleError('Failed to load Hotels data');
        }
      };

      const fetchGroups = async () => {
        const url = `${APP_REPORT_CENTER_API_ENDPOINT}/api/group/list?account=${idaccount}`;
        try {
          const response = await fetchService(url);
          if (response.success) {
            setGroups(response.data);
          } else {
            handleError('Failed to load Hotels data');
          }
        } catch (err) {
          handleError('Failed to load Hotels data');
        }
      };
    
      const fetchUserInfo = async () => {
        const url =`${APP_REPORT_CENTER_API_ENDPOINT}/api/userinfo`;
        try {
          const response = await fetchService(url);
          if (response.success) {
            setUserinfo(response.data[0]);
          } else {
            handleError('Failed to load Hotels data');
          }
        } catch (err) {
          handleError('Failed to load Hotels data');
        }
      };

      const fetchWhiteLabelEmail = async () => {
        const url = `${APP_REPORT_CENTER_API_ENDPOINT}/api/whitelable-email/list?account=${idaccount}`;
        try {
          const response = await fetchService(url);
          if (response.success) {
            setWhiteLabelEmail(response.data);
          } else {
            handleError('Failed to load Hotels data');
          }
        } catch (err) {
          handleError('Failed to load Hotels data');
        }
      };
    
      fetchTimezones();
      fetchFtp();
      fetchHotels();
      fetchGroups();
      fetchUserInfo();
      fetchWhiteLabelEmail();
    }, []);

    useEffect(() => {
      setReportData({
        ...reportData,
        ...formData,
      });
    }, [formData]);

    useEffect(() => {
      if (reportData.level === "HOTEL") {
        setSelectedGroups([]); 
        setSelectedHotels(reportData.level_choices || []);
      } else if (reportData.level === "GROUP") {
        setSelectedHotels([]);
        setSelectedGroups(reportData.level_choices || []);
      } else {
        setSelectedHotels([]);
        setSelectedGroups([]);
      }
    }, [reportData.level]);

    useEffect(() => {
      if (reportData.level === "HOTEL") {
        setReportData((prevData) => ({
          ...prevData,
          level_choices: selectedHotels,
        }));
      } else if (reportData.level === "GROUP") {
        setReportData((prevData) => ({
          ...prevData,
          level_choices: selectedGroups,
        }));
      }
    }, [selectedHotels, selectedGroups, reportData.level]);

    useEffect(() => {
      if (!reportData.level) {
        setReportData((prevData) => ({ ...prevData, level: 'ACCOUNT' }));  
      }
      if (!reportData.export_method) {
        setReportData((prevData) => ({ ...prevData, export_method: "EMAIL" })); 
      }
      if (!reportData.export_file_type) {
        setReportData((prevData) => ({ ...prevData, export_file_type: "CSV" })); 
      }
      if (!reportData.attached_file) {
        setReportData((prevData) => ({ ...prevData, attached_file: "ZIP_COMPRESSION" })); 
      }
      if (!reportData.delivery_frequency) {
        setReportData((prevData) => ({ ...prevData, delivery_frequency: "ONCE" })); 
      }
      
    }, [reportData.level, setReportData]);

    const handleLevelChange = (newLevel) => {
      setReportData((prevData) => {
        let newLevelChoices = [];
        if (newLevel === 'HOTEL') {
          newLevelChoices = selectedHotels; 
          setSelectedGroups([]); 
        } else if (newLevel === 'GROUP') {
          newLevelChoices = selectedGroups; 
          setSelectedHotels([]); 
        } else {
          setSelectedHotels([]);
          setSelectedGroups([]);
        }
        return {
          ...prevData,
          level: newLevel,
          level_choices: newLevelChoices,
        };
      });
    };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setReportData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleOpenModal = () => {
      if (reportData.level === 'HOTEL') {
        setTempSelectedHotels([...selectedHotels]);
      } else if (reportData.level === 'GROUP') {
        setTempSelectedGroups([...selectedGroups]);
      }
      setIsModalOpen(true);
    };

    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

   const handleToggleSettings = () => {
      setShowAdditionalSettings(!showAdditionalSettings);
    };

    const hotelcolumns = useMemo(
      () => [
        {
          accessorKey: 'select',
          header: 'Select',
          Cell: ({ row }) => (
            <Checkbox
              checked={tempSelectedHotels.includes(row.original.id)}
              onChange={(e) => {
                const value = row.original.id;
                const updatedTempSelectedHotels = e.target.checked
                  ? [...tempSelectedHotels, value]
                  : tempSelectedHotels.filter((item) => item !== value);
  
                setTempSelectedHotels(updatedTempSelectedHotels);
              }}
            />
          ),
        },
        {
          accessorKey: 'name',
          header: 'Hotel Name',
        },
        {
          accessorKey: 'country',
          header: 'Country',
        },
      ],
      [tempSelectedHotels]
    );
  
    const hoteldata = useMemo(
      () =>
        hotels.map((hotel) => ({
          id: hotel.id,
          name: hotel.name,
          country: hotel.country,
        })),
      [hotels]
    );

    const groupcolumns = useMemo(
      () => [
        {
          accessorKey: 'select',
          header: 'Select',
          Cell: ({ row }) => (
            <Checkbox
              checked={tempSelectedGroups.includes(row.original.id)}
              onChange={(e) => {
                const value = row.original.id;
                const updatedTempSelectedGroups = e.target.checked
                  ? [...tempSelectedGroups, value]
                  : tempSelectedGroups.filter((item) => item !== value);
  
                setTempSelectedGroups(updatedTempSelectedGroups);
              }}
            />
          ),
        },
        {
          accessorKey: 'name',
          header: 'Group Name',
        },
      ],
      [tempSelectedGroups]
    );
  
    const groupdata = useMemo(
      () =>
        groups.map((group) => ({
          id: group.id,
          name: group.name,
          country: group.country,
        })),
      [groups]
    );

    useEffect(() => {
      if (isEdit && reportData.subject) {
        setSubjectTags(reportData.subject);
      }
    }, [isEdit, reportData]);

    const handleTagsChange = (newTagsString) => {
      setSubjectTags(newTagsString);
  
      setReportData(prevData => ({
        ...prevData,
        subject: newTagsString,
      }));
    };

    return (
      <div className="report-form-container">
      <Grid item xs={12}>
       <Grid item xs={10} container spacing={4}>
          <Grid item xs={12}>
            <Grid item xs={8}>
              <TextField
                  fullWidth
                  name="name"
                  className="form-input"
                  placeholder='Report Name:'
                  value={reportData.name}
                  onChange={handleInputChange}
                  error={!!errors.name} 
                  helperText={errors.name || ''}
                />
              </Grid>
          </Grid>
          <Grid item xs={4} container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.level}>
                <Typography variant="body2">Report Level</Typography>
                <Select
                  fullWidth
                  value={reportData.level}
                  name="level"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    handleLevelChange(e.target.value);
                  }}
                  error={!!errors.level} 
                >
                  <MenuItem value=""><em>Select Report Level</em></MenuItem>
                  <MenuItem value="ACCOUNT">Account</MenuItem>
                  {userinfo.canUseGroup && (
                    <MenuItem value="GROUP">Group</MenuItem>
                  )}
                  <MenuItem value="HOTEL">Hotel</MenuItem>
                </Select>
                {errors.level && <FormHelperText>{errors.level}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {reportData.level === 'HOTEL' && selectedHotels.length > 0 && (
                <>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Selected Hotels:</Typography>
                </Grid>
                {selectedHotels.map((hotelId) => {
                  const hotel = hotels.find((h) => h.id === hotelId);
                  return <Typography>{hotel?.name}</Typography>;
                })}
                </>
              )}

              {reportData.level === 'GROUP' && selectedGroups.length > 0 && (
                <>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Selected Groups:</Typography>
                </Grid>  
                {selectedGroups.map((groupId) => {
                  const group = groups.find((g) => g.id === groupId);
                  return <Typography>{group?.name}</Typography>;
                })}
                </>
              )}
            </Grid>
            <Grid item xs={12} >
              {(reportData.level === 'HOTEL' || reportData.level === 'GROUP') && (
                <Button variant="contained" color="primary" onClick={handleOpenModal}>
                  Add {reportData.level === 'HOTEL' ? 'Hotel' : 'Group'}
                </Button>
              )}
            </Grid>

            <Dialog open={isModalOpen} onClose={handleCloseModal} maxWidth="md" fullWidth>
              <DialogTitle>Select {reportData.level === 'HOTEL' ? 'Hotels' : 'Groups'}</DialogTitle>
              <DialogContent>
              {reportData.level === 'HOTEL' && hotels.length > 0 ? (
                <MaterialReactTable
                columns={hotelcolumns}
                data={hoteldata}
                enableRowSelection={false}
                enableGlobalFilter={true} 
                positionGlobalFilter="left"
                muiSearchTextFieldProps={{
                  placeholder: 'Search hotels...',
                  variant: 'outlined',
                  sx: { minWidth: '300px' },
                }}
              />
              )  : reportData.level === 'GROUP' && groups.length > 0 ? (
                <MaterialReactTable
                columns={groupcolumns}
                data={groupdata}
                enableRowSelection={false} 
                enableGlobalFilter={true} 
                positionGlobalFilter="left" 
                muiSearchTextFieldProps={{
                  placeholder: 'Search groups...',
                  variant: 'outlined',
                  sx: { minWidth: '300px' }, 
                }}
              />
                    ) : (
                  <Typography>No {reportData.level === 'HOTEL' ? 'hotels' : 'groups'} available</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button variant="contained" color="primary" 
                  onClick={() => {
                    setReportData((prevData) => ({
                      ...prevData,
                      level_choices: reportData.level === 'HOTEL' ? tempSelectedHotels : tempSelectedGroups,
                    }));
                
                    if (reportData.level === 'HOTEL') {
                      setSelectedHotels(tempSelectedHotels);
                    } else if (reportData.level === 'GROUP') {
                      setSelectedGroups(tempSelectedGroups);
                    }
                
                    setIsModalOpen(false);
                  }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        
        {/* Row 2 */}
        <Grid container spacing={2} item xs={12}> 
        
          <Grid item xs={4} container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.export_method}>
                <Typography variant="body2">Choose Delivery Method</Typography>
                <Select
                  fullWidth
                  value={reportData.export_method}
                  name="export_method"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_method: e.target.value });
                  }}
                >
                  <MenuItem value="EMAIL">Email</MenuItem>
                  <MenuItem value="FTP">FTP</MenuItem>
                </Select>
                {errors.export_method && <FormHelperText>{errors.export_method}</FormHelperText>}
              </FormControl>
            </Grid>
            {reportData.export_method === 'FTP' && (
                <>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <Typography variant="body2">Choose FTP</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.delevery_method_ftp || ''}
                      onChange={(e) => setReportData({ ...reportData, delevery_method_ftp: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select FTP
                      </MenuItem>

                      {/* Dynamically render timezones */}
                      {ftp.length > 0 ? (
                        ftp.map((ftp) => (
                          <MenuItem key={ftp.id} value={ftp.id}>
                            {ftp.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No ftp Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2">Folder</Typography>
                      <TextField
                          name="folder"
                          fullWidth
                          className="form-input"
                          value={reportData.folder || ''}
                          onChange={(e) => setReportData({ ...reportData, folder: String(e.target.value) })}
                          displayempty="true"
                        />
                    </FormControl>
                  </Grid>
                </>
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.date_range}>
                <Typography variant="body2">Delivery Frequency</Typography>
                <Select
                  fullWidth
                  value={reportData.delivery_frequency}
                  name="delivery_frequency"
                  className="form-select"
                 onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, delivery_frequency: e.target.value });
                  }}
                  error={!!errors.delivery_frequency}
                >
                  <MenuItem value=""><em>Select Frequency</em></MenuItem>
                  <MenuItem value="ONCE">Once</MenuItem>
                  <MenuItem value="MONTHLY">Monthly</MenuItem>
                </Select>
                {errors.delivery_frequency && <FormHelperText>{errors.delivery_frequency}</FormHelperText>}
              </FormControl>
            </Grid>
              {reportData.delivery_frequency === 'MONTHLY' && (
                  <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="body2">Day of Month</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.day_month || ''}
                      onChange={(e) => setReportData({ ...reportData, day_month: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select Day
                      </MenuItem>
                      {Array.from({ length: 31 }, (_, index) => (
                        <MenuItem key="{index + 1}" value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
        </Grid>

          {/* Row 3 */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.recipients}>
                <Typography variant="body2">Recipients</Typography>
                <TextField
                  name="recipients"
                  fullWidth
                  placeholder="Recipients:"
                  className="form-input"
                  value={Array.isArray(reportData.recipients) ? reportData.recipients.join(', ') : ''}
                  onChange={(e) => {
                    const emailString = e.target.value;
                    const emailArray = emailString
                      .split(',')
                      .map((email) => email.trim())
                      .filter((email) => email.length > 0);

                    handleInputChange(e);
                    setReportData({ ...reportData, recipients: emailArray });
                  }}
                />
                {errors.recipients && <FormHelperText>{errors.recipients}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

          {/* Subject */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.subject}>
              <Typography variant="body2">Subject</Typography>
                <TagifyInput
                    value={subjectTags} 
                    onChange={handleTagsChange}
                    settings={{
                      placeholder: 'Add tags...',
                      delimiters: ', ',
                      whitelist: ['TYPE_REPORT', 'ACCOUNT', 'REPORT_NAME'],
                    }}
                  />
                {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File name */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.export_name}>
                <Typography variant="body2">Export File Name</Typography>
                <TextField
                  name="export_name"
                  fullWidth
                  placeholder="[TYPE_REPORT]-[ID_REPORT]-[ACCOUNT]-[DATETIME]"
                  className="form-input"
                  value={reportData.export_name}
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_name: e.target.value });
                  }}
                />
                {errors.export_name && <FormHelperText>{errors.export_name}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File Type */}
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.export_file_type}>
                <Typography variant="body2">Export File Type</Typography>
                <Select
                  fullWidth
                  value={reportData.export_file_type}
                  name="export_file_type"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_file_type: e.target.value });
                  }}
                  error={!!errors.export_file_type}
                >
                  <MenuItem value="CSV">CSV</MenuItem>
                  <MenuItem value="XLSV">Excel</MenuItem>
                </Select>
                {errors.export_file_type && (
                  <FormHelperText>{errors.export_file_type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ alignItems: 'flex-end' }} item xs={12}>
              {showAdditionalSettings && (
              <>
               <Grid item xs={4}>
               <FormControl fullWidth error={!!errors.attached_file}>
                <Typography variant="body2">Attached File</Typography>
                <Select
                  fullWidth
                  value={reportData.attached_file}
                  name="attached_file"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, attached_file: e.target.value });
                  }}
                  error={!!errors.attached_file}
                >
                   <MenuItem value="ZIP_COMPRESSION">Zip file</MenuItem>
                    <MenuItem value="NO_COMPRESSION">Uncompressed file</MenuItem>
                    <MenuItem value="NO_ATTACHMENT">No attachment</MenuItem>
                </Select>
                {errors.attached_file && (
                  <FormHelperText>{errors.attached_file}</FormHelperText>
                )}
              </FormControl>
              </Grid>
              <Grid item xs={4} sx={{ marginLeft: '2rem' }}>
                  <FormControl fullWidth>
                    <Typography variant="body2">WhiteLabel Email</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.whitelabel_email || ''}
                      onChange={(e) => setReportData({ ...reportData, whitelabel_email: String(e.target.value) })}
                      displayempty="true"
                    >
                    <MenuItem value="" disabled>
                      None
                    </MenuItem>
                    {whiteLabelEmail.length > 0 ? (
                      whiteLabelEmail.map((whiteLabelEmail) => (
                        <MenuItem key={whiteLabelEmail.id} value={whiteLabelEmail.id}>
                          {whiteLabelEmail.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Whitelabel Email Available</MenuItem>
                    )}
                  </Select>
                  </FormControl>
                </Grid>
              </>
              )}
          </Grid>
        </Grid>
          <Grid className="report-form-footer-section">
            <Grid container  spacing={2} item xs={12}>
              <Grid item xs={3}>
                <Button fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleCreateReport}
                  sx={{ marginRight: 2 }}
                >
                {isEdit ? 'Update Report' : 'Create Report'}
                </Button>
              </Grid>
              <Grid item xs={2}> 
                <Button fullWidth variant="outlined" onClick={onClose}> Cancel </Button>
              </Grid>
              <Grid item xs={7} sx={{ justifyItems: 'flex-end' }}  >
                <Grid item xs={12}>
                  <Button className="additional-settings" onClick={handleToggleSettings}  sx={{ marginRight: 2 }} >   <SettingsIcon   sx={{ marginRight: 2 }} /> Additional Settings</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <FormControl className="error-message" fullWidth error={!!errors.export_method}>
                  {errors.unspecified && <FormHelperText>{errors.unspecified}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
            {/* Snackbar for displaying success or error messages */}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
      </div>
    );
  };

  export default Billing;
