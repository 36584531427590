import React, { useState } from "react";
import { Box, Tabs, Tab, Select, MenuItem, TextField, IconButton, InputAdornment } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './KeywordsTabs.scss';

const KeywordsAccordion = ({ keywords, setKeywords, negativeKeywords, setNegativeKeywords, errors }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleAddKeyword = () => {
        const newEntry = { matchType: "", keyword: "" };
        if (activeTab === 0) {
            setKeywords([...keywords, newEntry]);
        } else {
            setNegativeKeywords([...negativeKeywords, newEntry]);
        }
    };

    const handleRemoveKeyword = (index, type) => {
        if (type === "keyword") {
            setKeywords(keywords.filter((_, i) => i !== index));
        } else if (type === "negative") {
            setNegativeKeywords(negativeKeywords.filter((_, i) => i !== index));
        }
    };

    const handleInputChange = (index, type, field, value) => {
        const updateKeywords = (list) => list.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );

        if (type === "keyword") {
            setKeywords(updateKeywords(keywords));
        } else if (type === "negative") {
            setNegativeKeywords(updateKeywords(negativeKeywords));
        }
    };

    const renderKeywordFields = (keywordsArray, type, errorArray) => (
        keywordsArray.map((item, index) => (
            <Box key={index} className="keyword-field">
                <Select
                    value={item.matchType}
                    onChange={(e) => handleInputChange(index, type, "matchType", e.target.value)}
                    displayEmpty
                    className="match-type-select no-border-focus"
                    sx={{
                        border: errorArray && errorArray[index] ? '1px solid red' : 'inherit',
                        borderRadius: '4px'
                    }}
                >
                    <MenuItem value="" disabled>Select Match Type</MenuItem>
                    <MenuItem value="EXACT">Exact</MenuItem>
                    <MenuItem value="BROAD">Broad Match</MenuItem>
                    <MenuItem value="PHRASE">Phrase Match</MenuItem>
                </Select>
                <TextField
                    value={item.keyword}
                    onChange={(e) => handleInputChange(index, type, "keyword", e.target.value)}
                    placeholder="Enter keyword"
                    variant="outlined"
                    size="small"
                    className="keyword-input no-border-focus"
                    inputProps={{ maxLength: 35 }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {`${item.keyword.length}/35`}
                            </InputAdornment>
                        ),
                    }}
                    sx={{
                        border: errorArray && errorArray[index] ? '1px solid red' : 'inherit',
                        borderRadius: '4px'
                    }}
                />
                <IconButton onClick={() => handleRemoveKeyword(index, type)} className="remove-button">
                    <RemoveIcon />
                </IconButton>
            </Box>
        ))
    );

    return (
        <Box className="search-ads-form-keywords">
            <Box className="tabs-container">
                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ padding: 0 }}>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        className="custom-tabs"
                        TabIndicatorProps={{ style: { display: "none" } }}
                        sx={{ minHeight: 'unset' }}
                    >
                        <Tab
                            label="Keywords"
                            className={activeTab === 0 ? 'tab active-tab' : 'tab'}
                            sx={{ padding: 0, minHeight: 'unset' }}
                        />
                        <Tab
                            label="Negative Keywords"
                            className={activeTab === 1 ? 'tab active-tab' : 'tab'}
                            sx={{ padding: 0, minHeight: 'unset' }}
                        />
                    </Tabs>
                    <IconButton onClick={handleAddKeyword} color="primary" className="add-button">
                        <AddIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box className="keywords-content" p={2}>
                {activeTab === 0 && renderKeywordFields(keywords, "keyword", errors.keywords)}
                {activeTab === 1 && renderKeywordFields(negativeKeywords, "negative", errors.negativeKeywords)}
            </Box>
        </Box>
    );
};

export default KeywordsAccordion;

