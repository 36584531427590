import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { APP_KAYAK_BIDDING_API_ENDPOINT } from "constants/api_urls";
import { useSelector } from "react-redux";
import { Alert, Button, Snackbar } from "@mui/material";
import FormFieldRenderer from "components/FormFieldRenderer";
import { kayakExtraBidFormFields } from "./options";
import HotelsFilterMenu from "components/hotelFilterMenu";
import { ArrowDropDown } from "@mui/icons-material";
import TextInput from "components/TextInput";

const fields = {
  baseBid: "bidding_value",
  bidStrategy: "bidding_type",
};

const KayakExtraBid = () => {
  const token = sessionStorage.getItem("token") || "";
  // Ref
  const hotelButtonRef = useRef(null);
  //Selectors
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const [openHotelMenu, setOpenHotelMenu] = useState(false);
  const [selectedHotelOrGroupType, setSelectedHotelOrGroupType] =
    useState("hotels");
  const [selectedHotelsorGroups, setSelectedHotelsorGroups] = useState([]);
  const [updateBidPayload, setUpdateBidPayload] = useState({
    account_id: account_id,
    bidding_level: selectedHotelOrGroupType === "hotels" ? "HOTEL" : "GROUP",
  });
  const [extraBidPayload, setExtraBidPayload] = useState([]);

  console.log(extraBidPayload, "Extra");

  // List Data
  const [biddingList, setBiddingList] = useState({});
  const [showToast, setShowToast] = useState(false);
  const { hotel_list = [], group_list = [], can_use_group } = biddingList || {};
  const COMMONFORMFIELDS = [
    {
      id: "hotel",
      label: "Hotel",
      type: "select",
      placeholder: "-- Select a hotel --",
      options: hotel_list,
    },
  ];

  console.log("Payload", updateBidPayload);

  // Service to render Bidding List based on Account Id
  useEffect(() => {
    console.log(token, "token");
    if (account_id) {
      setUpdateBidPayload((prev) => ({ ...prev, account_id }));
      axios
        .get(
          `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/bidding-panel-info/${account_id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setBiddingList(res?.data || {});
        });
    }
  }, [account_id]);

  const handleChangeFieldValue = useCallback((field, value) => {
    setUpdateBidPayload((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleSave = useCallback(() => {
    extraBidPayload
      .filter((p) => p?.bidding_strategy?.bidding_value)
      .forEach((payload) => {
        if (selectedHotelOrGroupType === "hotels") {
          delete payload?.group_id;
        }
        if (selectedHotelOrGroupType === "groups") {
          delete payload?.hotel_id;
        }
        axios
          .put(
            `${APP_KAYAK_BIDDING_API_ENDPOINT}/api/kayak/update-bids/`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setShowToast(true);
          });
      });
  }, [extraBidPayload]);

  return (
    <div style={{ position: "relative" }}>
      <div className="form-title">EDIT YOUR BIDDING OPTIONS</div>
      <div className="form-button-container">
        <div className="formGrid">
          <div className="common-field-container">
            {COMMONFORMFIELDS.map((field) => {
              if (field.id === "hotel") {
                return (
                  <div className="form-grid-item">
                    <div className="mul-form-label">Hotels</div>
                    <div ref={hotelButtonRef}>
                      <TextInput
                        placeholder={field.placeholder}
                        type={"text"}
                        readOnly
                        value={
                          selectedHotelsorGroups.length > 0
                            ? selectedHotelsorGroups.length === 1
                              ? selectedHotelsorGroups[0].name
                              : `${selectedHotelsorGroups.length} ${selectedHotelOrGroupType} selected`
                            : ""
                        }
                        sx={{
                          border: "1px solid #C4C4C4",
                          backgroundColor: "white",
                        }}
                        endAdornment={<ArrowDropDown />}
                        onClick={(e) => {
                          setOpenHotelMenu(true);
                        }}
                      />
                    </div>
                    {hotel_list?.length ? (
                      <HotelsFilterMenu
                        allHotels={hotel_list}
                        allGroups={group_list}
                        showGroups={group_list.length !== 0 && can_use_group}
                        anchorEl={hotelButtonRef.current}
                        open={openHotelMenu}
                        onClose={() => {
                          setOpenHotelMenu(false);
                        }}
                        onApply={(selected) => {
                          setSelectedHotelsorGroups(selected);
                          handleChangeFieldValue(
                            selectedHotelOrGroupType === "hotels"
                              ? "hotel_id"
                              : "group_id",
                            selected.map((s) => s.id)
                          );

                          if (extraBidPayload.length) {
                            setExtraBidPayload((prev) => ({
                              ...prev,
                              [selectedHotelOrGroupType === "hotels"
                                ? "hotel_id"
                                : "group_id"]: selected.map((s) => s.id),
                            }));
                          }
                        }}
                        setSelectedHotelOrGroupType={(type) => {
                          setSelectedHotelOrGroupType(type);
                          handleChangeFieldValue(
                            "bidding_level",
                            type === "hotels" ? "HOTEL" : "GROUP"
                          );
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              }
              return null;
            })}
            <div className="form-grid-item">
              <div className="form-title">THIS APPLIES TO ALL MARKETS</div>
            </div>
          </div>
          <div className="fields-container">
            {kayakExtraBidFormFields?.map((field) => {
              return (
                <div className="form-grid-item">
                  <FormFieldRenderer
                    item={field}
                    options={field?.options || []}
                    hotelMenuOpen={openHotelMenu}
                    onHotelMenuClose={() => {
                      setOpenHotelMenu(false);
                    }}
                    onChange={(val) => {
                      let device_identifier;
                      if (field.label.toLowerCase().includes("desktop")) {
                        device_identifier = "desktop";
                      }
                      if (field.label.toLowerCase().includes("mobile")) {
                        device_identifier = "mobile";
                      }

                      const initialPayload = {
                        ...updateBidPayload,
                        device_identifier,
                        bidding_strategy: {
                          ...updateBidPayload?.bidding_strategy,
                          bidding_type: field.id,
                          bidding_value: Number(val),
                        },
                      };

                      setExtraBidPayload((prev) => {
                        const index = prev.findIndex(
                          (pl) =>
                            pl?.device_identifier === device_identifier &&
                            pl?.bidding_strategy?.bidding_type === field.id
                        );
                        if (index !== -1) {
                          prev.splice(index, 1, {
                            ...prev[index],
                            bidding_strategy: {
                              ...prev[index].bidding_strategy,
                              bidding_type: field.id,
                              bidding_value: Number(val),
                            },
                          });
                          return prev;
                        } else {
                          return prev.concat(initialPayload);
                        }
                      });
                      handleChangeFieldValue(
                        "device_identifier",
                        device_identifier
                      );
                      handleChangeFieldValue("bidding_strategy", {
                        ...updateBidPayload?.bidding_strategy,
                        bidding_type: field.id,
                        bidding_value: Number(val),
                      });
                    }}
                    labelKey={"label"}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {!!updateBidPayload?.[
          selectedHotelOrGroupType === "hotels" ? "hotel_id" : "group_id"
        ]?.length &&
          !!extraBidPayload?.filter((p) => p?.bidding_strategy?.bidding_value)
            .length && (
            <div className="save">
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#12794F",
                  borderRadius: "20px",
                  height: "28px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textTransform: "none",
                  color: "white",
                }}
                title="Save"
                onClick={handleSave}
              >
                {" "}
                Save{" "}
              </Button>
            </div>
          )}
      </div>
      <Snackbar
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={showToast}
        autoHideDuration={3000}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Updated successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export default KayakExtraBid;
