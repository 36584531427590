import React from 'react';
import BiddingRuleTripadvisor from '../BiddingRule/Metasearch/BiddingRuleTripadvisor';
import BiddingRuleTrivago from '../BiddingRule/Metasearch/BiddingRuleTrivago';
import BiddingRuleGoogleHotelAds from "./Metasearch/BiddingRuleGoogleHotelAds";
import NewTemplateForm from '../TemplateCreationForm/NewTemplateForm';

const BiddingRuleManager = ({biddingRuleData, action, actionType, onClose }) => {

    console.log("Bidding Rule Data : " + JSON.stringify(biddingRuleData));
    console.log("Action : " + action);

    const getAlertForm = () => {
        if (biddingRuleData.bidding_rule_type === 'TRIPADVISOR_BIDDING_RULE') {
            if (action === 'rule-action') {
                return <BiddingRuleTripadvisor
                    biddingRuleData={biddingRuleData}
                    onClose={onClose}
                    actionType={actionType}/>;
            } else if (action === 'template-action') {
                return <NewTemplateForm/>;
            } else {
                return <p style={{color: "red", paddingLeft: "18px"}} >Unsupported action for Tripadvisor</p>;
            }

        } else if (biddingRuleData.bidding_rule_type === 'TRIVAGO_BIDDING_RULE') {
            if (action === 'rule-action') {
                return <BiddingRuleTrivago
                    biddingRuleData={biddingRuleData}
                    onClose={onClose}
                    actionType={actionType}/>;
            } else if (action === 'template-action') {
                return <NewTemplateForm/>;
            } else {
                return <p style={{color: "red", paddingLeft: "18px"}} >Unsupported action for Trivago</p>;
            }

        } else if (biddingRuleData.bidding_rule_type === 'GOOGLE_BIDDING_RULE') {
            if (action === 'rule-action') {
                return <BiddingRuleGoogleHotelAds
                    biddingRuleData={biddingRuleData}
                    onClose={onClose}
                    actionType={actionType}/>;
            } else if (action === 'template-action') {
                return <NewTemplateForm/>;
            } else {
                return <p style={{color: "red", paddingLeft: "18px"}} >Unsupported action for Google</p>;
            }

        } else {
            return <p style={{color: "red", paddingLeft: "18px"}} >Unsupported alert type</p>;
        }
    };

    return (
        <div>
            {getAlertForm()}
        </div>
    );
};

export default BiddingRuleManager;
