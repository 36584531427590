import React, {useState} from 'react';
import { MaterialReactTable } from 'material-react-table';
import {CircularProgress, Box, Stack, Typography, Select, MenuItem, Pagination} from '@mui/material';
import './SubUserTable.scss';

const generateColumns = (data, onEmailClick) => {
    if (!data || data.length === 0) {
        return [];
    }

    const keys = Object.keys(data[0]);

    return keys
        .filter((key) => key !== 'id' && key !== 'role')
        .map((key) => {
            if (key === 'username') {
                return {
                    accessorKey: key,
                    header: 'Username',
                    size: 100,
                    Cell: ({ cell, row }) => (
                        <a
                            className="username-link"
                            onClick={() => onEmailClick(row.original)}
                        >
                            {cell.getValue()}
                        </a>
                    ),
                };
            } else {
                return {
                    accessorKey: key,
                    header: key.charAt(0).toUpperCase() + key.slice(1),
                    size: 100,
                };
            }
        });
};

const SubUserTable = ({ usersData, onEmailClick, loading }) => {
    const columns = generateColumns(usersData, onEmailClick);
    const userCount = usersData.length;

    // State for pagination
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Calculate paginated data
    const paginatedData = usersData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const pageCount = Math.ceil(userCount / rowsPerPage);

    return (
        <Box sx={{ position: 'relative', maxWidth: '100%' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                        backgroundColor: 'rgba(255,255,255,0.7)',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <MaterialReactTable
                columns={columns}
                data={paginatedData}
                enablePagination={false}
                enableSorting={true}
                enableColumnVisibility={false}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableHiding={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                muiTableHeadCellProps={{
                    sx: {
                        textAlign: 'left',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        verticalAlign: 'middle',
                        padding: '0 16px'
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        textAlign: 'left',
                        width: '150px',
                        fontSize: '12px',
                        lineHeight: '40px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '45px',
                        padding: '0 16px',
                    },
                }}
            />

            {/* Pagination Component */}
            { pageCount > 0 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%', margin:'0 8px 0 4px' }}>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ color: '#BAB7B7', fontSize: '12px', lineHeight:'14px', fontWeight: '400' }}>Rows per page:</Typography>
                    <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        size="small"
                        sx={{
                            minWidth: 40,
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                fontSize:'10px',
                            },
                            color: '#000000',
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                        }}
                    >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </Stack>
                <Stack sx={{ alignItems: 'flex-end', textAlign: 'right' }}>
                    <Pagination
                        count={pageCount}
                        page={page}
                        onChange={handleChangePage}
                        siblingCount={1}
                        boundaryCount={1}
                        showFirstButton
                        showLastButton
                        size="small"
                        sx={{
                            '.MuiPaginationItem-root': {
                                fontSize: '14px',
                            },
                        }}
                    />
                </Stack>
            </Stack>
            )}
        </Box>
    );
};

export default SubUserTable;
