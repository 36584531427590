import { Search } from "@mui/icons-material";
import {
  Menu,
  Chip,
  OutlinedInput,
  Box,
  Button,
  Typography,
} from "@mui/material";
import CustomCheckbox from "components/Checkbox";
import { useMemo, useState } from "react";

const HotelsFilterMenu = ({
  open,
  onClose,
  anchorEl,
  allHotels,
  allGroups,
  onApply,
  setSelectedHotelOrGroupType,
  isSelectAllRequired = false,
  showGroups = true,
}) => {
  const [selectedHotels, setSelectedHotels] = useState(allHotels);
  const [selectedGroups, setSelectedGroups] = useState(allGroups);
  const [selectedType, setSelectedType] = useState("hotels");
  const [searchTerm, setSearchTerm] = useState("");

  const selected = showGroups
    ? selectedType === "hotels"
      ? selectedHotels
      : selectedGroups
    : selectedHotels;
  const isSelectAllChecked = useMemo(() => {
    if (selectedType === "hotels") {
      const selectedCount = selectedHotels.filter(
        (item) => item.isSelected
      ).length;

      return selectedCount === allHotels.length;
    } else if (selectedType === "groups") {
      const selectedCount = selectedGroups.filter(
        (item) => item.isSelected
      ).length;
      return selectedCount === allGroups.length;
    }
  }, [selectedType, selectedHotels, selectedGroups]);
  const toggleSelectAll = () => {
    if (selectedType === "hotels") {
      const selectedCount = selectedHotels.filter(
        (item) => item.isSelected
      ).length;
      if (selectedCount === allHotels.length) {
        setSelectedHotels(
          selected.map((item) => {
            return { ...item, isSelected: false };
          })
        );
      } else {
        setSelectedHotels(
          selected.map((item) => {
            return { ...item, isSelected: true };
          })
        );
      }
    } else if (selectedType === "groups") {
      const selectedCount = selectedGroups.filter(
        (item) => item.isSelected
      ).length;
      if (selectedCount === allGroups.length) {
        setSelectedGroups(
          selected.map((item) => {
            return { ...item, isSelected: false };
          })
        );
      } else {
        setSelectedGroups(
          selected.map((item) => {
            return { ...item, isSelected: true };
          })
        );
      }
    }
  };

  const clearFilters = () => {
    if (selectedType === "hotels") {
      setSelectedHotels(
        selected.map((item) => {
          return { ...item, isSelected: false };
        })
      );

      if (selectedType === "groups") {
        setSelectedGroups(
          selected.map((item) => {
            return { ...item, isSelected: false };
          })
        );
      }
    }
  };

  console.log(selected, "Selected");

  return (
    <>
      <Menu
        sx={{ padding: 0 }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            maxWidth: "258px",
            minWidth: "140px",
          },
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <div
          style={{
            paddingInline: "17px",
            paddingTop: "0px",
            paddingBottom: "7px",
          }}
        >
          <div
            style={{
              fontFamily: "inter",
              color: "#C4C4C4",
              fontSize: "10px",
              marginBottom: "10px",
            }}
          >
            Select
          </div>
          <div className="flex" style={{ marginBottom: "10px" }}>
            <div
              onClick={() => {
                setSelectedType("hotels");
                setSelectedHotelOrGroupType("hotels");
                //  setSelected(selectedHotels);
              }}
            >
              <Chip
                label="Hotels"
                sx={{
                  backgroundColor:
                    selectedType === "hotels" ? "#61D5A9" : "none",
                  marginRight: "10px",
                  height: "20px",
                  width: "92px",
                }}
              />
            </div>
            {showGroups && (
              <div
                onClick={() => {
                  setSelectedType("groups");
                  setSelectedHotelOrGroupType("groups");
                }}
              >
                <Chip
                  label="Groups"
                  sx={{
                    backgroundColor:
                      selectedType === "groups" ? "#61D5A9" : "none",
                    marginRight: "4px",
                    height: "20px",
                    width: "92px",
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <OutlinedInput
              sx={{
                borderRadius: "10px",
                borderColor: "#CADEDD",
                width: "195px",
                height: "28px",
              }}
              size="small"
              placeholder="Search"
              startAdornment={
                <Search sx={{ color: "#CADEDD", fontSize: "18px" }} />
              }
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div>
              {isSelectAllRequired && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    toggleSelectAll();
                  }}
                >
                  <span>
                    <CustomCheckbox
                      fontSize="10px"
                      size={"small"}
                      checked={isSelectAllChecked}
                      labelStyles={{ color: "#C4C4C4" }}
                      title={
                        selectedType === "hotels" ? "Hotels" : "All groups"
                      }
                    />
                  </span>
                </div>
              )}
              <ul
                style={{
                  listStyleType: "none",
                  marginBlock: 0,
                  padding: 0,
                  paddingLeft: "4px",
                  width: "100%",
                  maxHeight: "250px",
                  overflow: "auto",
                }}
              >
                {selected
                  ?.filter((hotel) =>
                    (hotel?.name || hotel?.hotel_name)
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((item) => {
                    return (
                      <li
                        style={{
                          borderLeft: "1px solid #CADEDD",
                          paddingLeft: "10px",
                          height: "10%",
                          paddingBlock: "7px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (selectedType === "hotels") {
                              setSelectedHotels(
                                selected?.map((i) => {
                                  return {
                                    ...i,
                                    isSelected: (
                                      i?.id
                                        ? i?.id === item.id3
                                        : i?.hotel_id === item?.hotel_id
                                    )
                                      ? !i?.isSelected
                                      : i?.isSelected,
                                  };
                                })
                              );
                            } else if (selectedType === "groups") {
                              setSelectedGroups(
                                selected?.map((i) => {
                                  return {
                                    ...i,
                                    isSelected:
                                      i?.id === item.id
                                        ? !i?.isSelected
                                        : i?.isSelected,
                                  };
                                })
                              );
                            }
                          }}
                        >
                          <span>
                            <CustomCheckbox
                              fontSize="12px"
                              size={"small"}
                              checked={item?.isSelected}
                              title={
                                item?.label || item?.name || item?.hotel_name
                              }
                            />
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "15px",
              paddingBottom: "0px",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#C4C4C4",
                cursor: "pointer",
              }}
              onClick={clearFilters}
            >
              Clear Filters
            </Typography>
            <Button
              variant="outlined"
              sx={{
                color: "black",
                backgroundColor: "#61D5A9",
                borderRadius: "20px",
                fontSize: "10px",
                width: "100px",
                height: "22px",
                paddingInline: "8px",
              }}
              onClick={() => {
                onApply(selected?.filter((item) => item.isSelected));
                setSelectedHotelOrGroupType(selectedType);
                onClose();
              }}
            >
              Apply
            </Button>
          </Box>
        </div>
      </Menu>
    </>
  );
};
export default HotelsFilterMenu;
