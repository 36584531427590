import CustomCheckbox from "components/Checkbox";
import "./export.scss";
import DateInput from "components/DateInput";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
export default function Export() {
  const account = useSelector((state) => state.account.account);
  return (
    <div className="export-container">
      <div className="export-header">
        <span>EXPORT BIDDING OFFLINE</span>
        <span>{account?.account_name ?? ""}</span>
      </div>
      <div className="export-fields-container">
        <div className="row">
          <div className="row-label">METRIC DATE RANGE</div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <div>
              <div className="dateLabel">From</div>
              <DateInput />
            </div>
            <div>
              <div className="dateLabel">To</div>
              <DateInput />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="row-label">EXPORT LEVEL</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={"Hotel"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Groups"} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-label">PLATFORM</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={"Tripadvisor"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Google Hotel Ads"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Trivago"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Kayak"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Bing"} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-label"> BREAK DOWN FOR GOOGLE HOTEL ADS</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={"Device"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Length Of Stay"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Checkin Day"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Days to Arrival"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Date Type"} />
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#12794F",
              height: "24px",
              borderRadius: "12px",
              textTransform: "none",
              color: "white",
              fontSize: "12px",
              "&:hover": {
                backgroundColor: "#12794F",
              },
            }}
            disableRipple
            disableElevation
          >
            Export
          </Button>
        </div>
      </div>
    </div>
  );
}
