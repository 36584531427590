import React, { useState } from "react";
import "./Sidebar.scss";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DownArrow from "../../../assets/images/DownArrow.svg";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { drawerItems } from "constants/productmenu";
import MenuIcon from "assets/images/MenuIcon.svg";

const drawerWidth = "100%",
  openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuItem = ({ item, open, handleClick }) => {
  const navigate = useNavigate();
  return (
    <>
      <ListItem button onClick={() => handleClick(item.id)}>
        <ListItemText primary={item.label} />
        {item.children ? (
          open ? (
            <img src={DownArrow} alt={"Down Arrow"} />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <div className="menuItem-child">
                <MenuItem
                  key={child.id}
                  item={child}
                  open={open[child.id]}
                  handleClick={() => {
                    navigate(child?.link);
                  }}
                />
              </div>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default function CustomDrawer({
  menuItems,
  initialItems,
  currentSection,
  setCurrentSection,
}) {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(true);
  const handleMenuItemClick = (name, url) => {
    navigate(url);
    setCurrentSection(name === "META SEARCH" ? "Campaign Manager" : name);
  };

  return (
    <Box
      className={"sidebar"}
      sx={{
        display: "flex",
        justifyContent: "center",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.05)",
        width: openDrawer ? "180px" : "51px",
        // transition: "all 0.5s",
      }}
    >
      <Box>
        <List>
          {currentSection === null && (
            <>
              <div
                className="my-products-title"
                style={{ marginBottom: "10px" }}
              >
                <div
                  style={{
                    fontFamily: "inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    color: "#BAB7B7",
                    width: "100%",
                    marginLeft:'-18px',
                  }}
                  className={"underline"}
                >
                  Products
                </div>
              </div>

              {initialItems.map(({ name, icon, url }) => (
                <div
                  onClick={() => handleMenuItemClick(name, url)}
                  key={name}
                  disablePadding
                  className={"list-menu"}
                  style={{
                    fontSize: "14px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "#587A78",
                    marginTop: "4px",
                    cursor: "pointer",
                    marginLeft:'-18px'
                  }}
                >
                  <div>
                    <img
                      style={{
                        display: "inline",
                        height: "24px",
                        width: "24px",
                      }}
                      alt={name}
                      src={icon}
                      className={"menu-icon"}
                    />
                  </div>
                  <div
                    style={{
                      height: "100%",
                      marginTop: "7px",
                      marginLeft: "8px",
                      fontSize: "14px",
                    }}
                    className={"list-menu-name"}
                  >
                    {name}
                  </div>
                </div>
              ))}
            </>
          )}
          {currentSection !== null && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                maxWidth: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                <img
                  src={MenuIcon}
                  style={{ height: "18px" }}
                  onClick={() => {
                    setOpenDrawer(!openDrawer);
                  }}
                />
                <div>
                  {drawerItems?.map((item) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{ marginTop: "16px", cursor: "pointer" }}
                          onClick={() => {
                            navigate(item.url);
                          }}
                        >
                          <img
                            style={{
                              display: "inline",
                              height: "18px",
                              width: "18px",
                            }}
                            alt={item.name}
                            src={item.icon}
                            className={"menu-icon"}
                          />
                        </div>
                        <div
                          style={{
                            height: "100%",
                            fontSize: "8px",
                          }}
                          className={"list-menu-name"}
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className="expanded-container"
                style={{ display: openDrawer ? "block" : "none" }}
              >
                <div className="title">Campaign Manager</div>
                {menuItems.map((item) => (
                  <MenuItem
                    item={item}
                    open={true}
                    handleClick={() => navigate(item?.link)}
                    className="menu-item"
                  />
                ))}
              </div>
            </div>
          )}
        </List>
      </Box>
    </Box>
  );
}
