import React  from "react";
import { Typography, Box, TextField, Grid } from "@mui/material";
import Section from "./Templates/Section";
import "./AdsCreativeAccordion.scss";
import SiteLinkExtensionSection from "./Templates/SiteLinkExtensionSection";
import CallsSection from "./Templates/CallsSection";

const generateId = () => Date.now() + Math.random().toString(36).substr(2, 9);

const AdsCreativeAccordion = ({
    headlines,
    setHeadlines,
    descriptions,
    setDescriptions,
    siteLinks,
    setSiteLinks,
    callOuts,
    setCallOuts,
    structuredSnippets,
    setStructuredSnippets,
    finalURL,
    setFinalURL,
    businessName,
    setBusinessName,
    calls,
    setCalls,
    displayPaths,
    setDisplayPaths
}) => {

    const addDisplayPath = () => setDisplayPaths([...displayPaths, { id: generateId(), text: '' }]);
    const addHeadline = () => setHeadlines([...headlines, { id: generateId(), text: '' }]);
    const addDescription = () => setDescriptions([...descriptions, { id: generateId(), text: '' }]);
    const addSiteLink = () => setSiteLinks([...siteLinks, { id: generateId(), text: '' }]);
    const addCallOut = () => setCallOuts([...callOuts, { id: generateId(), text: '' }]);
    const addStructuredSnippet = () => setStructuredSnippets([...structuredSnippets, { id: generateId(), text: '' }]);
    const addCall = () => setCalls([...calls, { id: generateId(), phoneNumber: '' }]);

    const handleInputChange = (setter) => (id, key, value) => {
        setter((items) => items.map((item) =>
            item.id === id ? { ...item, [key]: value } : item
        ));
    };

    const handleRemoveCall = (id) => {
        setCalls((prevCalls) => {
            return prevCalls.filter((call) => {
                return (call.irremovable || call.id !== id);
            });
        });
    };

    const handleRemoveDisplayPath = (id) => {
        setDisplayPaths((prevDisplayPaths) => {
            return prevDisplayPaths.filter((path) => {
                return (path.irremovable || path.id !== id);
            });
        });
    };

    const handleRemove = (setter) => (id) => {
        setter((items) => items.filter((item) => item.id !== id));
    };

    return (
        <Box className="adsCreativeContainer">
            <Box className="property-section" mb={4}>
                <Box display="flex" alignItems="center" className="header-box">
                    <Typography variant="subtitle1">PROPERTY</Typography>
                </Box>
                <Box display="flex" gap={2} mt={1}>
                    <TextField
                        placeholder="Final URL"
                        className="no-border-focus"
                        variant="outlined"
                        fullWidth
                        value={finalURL}
                        onChange={(e) => setFinalURL(e.target.value)}
                    />
                    <TextField
                        placeholder="Business Name"
                        variant="outlined"
                        className="no-border-focus"
                        fullWidth
                        value={businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                    />
                </Box>
            </Box>
            <Grid container spacing={2} alignItems="center">
                <Section
                    title="HEADLINE"
                    items={headlines}
                    addItem={addHeadline}
                    handleInputChange={handleInputChange(setHeadlines)}
                    handleRemove={handleRemove(setHeadlines)}
                    maxLengthInput={35}
                />
                <Section
                    title="DESCRIPTION"
                    items={descriptions}
                    addItem={addDescription}
                    handleInputChange={handleInputChange(setDescriptions)}
                    handleRemove={handleRemove(setDescriptions)}
                    maxLengthInput={90}
                />
                <SiteLinkExtensionSection
                    title="SITE LINK EXTENSION"
                    items={siteLinks}
                    addItem={addSiteLink}
                    handleInputChange={handleInputChange(setSiteLinks)}
                    handleRemove={handleRemove(setSiteLinks)}
                />
                <Section
                    title="CALL OUT EXTENSION"
                    items={callOuts}
                    addItem={addCallOut}
                    handleInputChange={handleInputChange(setCallOuts)}
                    handleRemove={handleRemove(setCallOuts)}
                />
                <Section
                    title="STRUCTURED SNIPPET"
                    items={structuredSnippets}
                    addItem={addStructuredSnippet}
                    handleInputChange={handleInputChange(setStructuredSnippets)}
                    handleRemove={handleRemove(setStructuredSnippets)}
                />
                <CallsSection
                    calls={calls}
                    addCall={addCall}
                    handleInputChange={handleInputChange(setCalls)}
                    handleRemove={handleRemoveCall}
                />
                <Section
                    title="DISPLAY PATH"
                    items={displayPaths}
                    addItem={addDisplayPath}
                    handleInputChange={handleInputChange(setDisplayPaths)}
                    handleRemove={handleRemoveDisplayPath}
                />
            </Grid>
        </Box>
    );
};

export default AdsCreativeAccordion;
