import CustomCheckbox from "components/Checkbox";
import { Button } from "@mui/material";
import iconupload from "assets/images/iconupload.svg";
import IconWrapper from "components/IconWrapper";
import "./import.scss";
export default function Import() {
  return (
    <div className="export-container">
      <div className="export-header">
        <span>IMPORT BIDDING OFFLINE</span>
        <span>Account A</span>
      </div>
      <div className="export-fields-container">
        <div className="row">
          <div className="row-label">IMPORT LEVEL</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={"Hotel"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Groups"} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-label">PLATFORM</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={"Tripadvisor"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Google Hotel Ads"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Trivago"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Kayak"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={"Bing"} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-label"> COLUMNS SEPERATOR</div>
          <div className="row-item-container">
            <div className="row-item">
              <CustomCheckbox title={";"} />
            </div>
            <div className="row-item">
              <CustomCheckbox title={","} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row-label">BINDING FILE</div>
          <div className="row-item-container">
            <Button
              variant="outlined"
              sx={{
                height: "28px",
                paddingLeft: "10px",
                paddingRight: 0,
                borderColor: "#C4C4C4",
                "&:hover": {
                  borderColor: "#C4C4C4",
                },
              }}
              disableRipple
            >
              <div className="import-button">
                <span className="import-button-label">Upload</span>
                <span>
                  <IconWrapper icon={iconupload} />
                </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
