import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
} from "@mui/material";
import IconWrapper from "components/IconWrapper";
import close from "assets/images/close.svg";
import TextInput from "components/TextInput";
import "./tableValueEditor.scss";
import CustomButton from "components/Button";
const TableValueEditor = ({ open, onClose }) => {
  const data = [
    { date: "19/09/2024 09:30", value: "+27%", option: "-" },
    { date: "29/09/2024 09:30", value: "+67%", option: "-" },
    { date: "19/09/2024 09:30", value: "+27%", option: "-" },
    { date: "29/09/2024 09:30", value: "+67%", option: "-" },
    { date: "19/09/2024 09:30", value: "+27%", option: "-" },
    { date: "29/09/2024 09:30", value: "+67%", option: "-" },
  ];
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{}}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="dialogTitle">Desktop</span>
        <span>
          {" "}
          <IconWrapper onClick={onClose} icon={close} />{" "}
        </span>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "3px",
          }}
        >
          <TextInput
            sx={{
              borderRadius: "10px",
              border: "1px solid #C4C4C4",
              flexShrink: 0,
            }}
            placeholder="Enter value"
          />
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flexShrink: 0,
              marginLeft: "10px",
            }}
          >
            <CustomButton title="Apply" variant={"filled"} onClick={() => {}} />
          </DialogActions>
        </Box>
        <Table>
          <tr>
            <th>Date</th>
            <th>Value</th>
            <th>Options</th>
          </tr>
          {data.map((item) => {
            return (
              <tr>
                <td>{item?.date}</td>
                <td>{item?.value}</td>
                <td>{item?.option}</td>
              </tr>
            );
          })}
        </Table>
      </DialogContent>
    </Dialog>
  );
};
export default TableValueEditor;
