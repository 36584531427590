// HotelListModal.jsx
import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import SlidingPanel from "../SlidingPanel";
import "./index.scss";
import Icons from "components/DmpIcons/icons";

const HotelListModal = ({ open, handleClose, hotels, groups, hotelColumns, groupColumns }) => {
    const [isHotelsOpen, setIsHotelsOpen] = useState(true);
    const [isGroupsOpen, setIsGroupsOpen] = useState(false);

    const toggleHotels = () => {
        setIsHotelsOpen(!isHotelsOpen);
    };

    const toggleGroups = () => {
        setIsGroupsOpen(!isGroupsOpen);
    };

    return (
        <SlidingPanel
            isOpen={open}
            size={50} // Ajustez la taille selon vos besoins
            onClose={handleClose}
            sx={{
                left: "15px",
                padding: "30px",
                height: "100%",
                width: "50%"
            }}
        >
            <div className="bidding-panel-container">
                <div className="platform-container" style={{ width: '100%' }}>
                    <div className="panel-header" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <div
                            onClick={handleClose}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '28px',
                                height: '28px',
                                borderRadius: '50%',
                                border: '1px solid #000',
                                padding: '5px',
                                cursor: 'pointer',
                                marginRight: '10px',
                                boxSizing: 'border-box'
                            }}
                        >
                            <img
                                src={Icons.ActionsIconsSVG.ClosePanelIconSVG}
                                alt="Close"
                            />
                        </div>
                        <h2 style={{
                            fontSize: '20px',
                            textAlign: 'left',
                            fontWeight: 400,
                            fontFamily: 'Quincy CF, serif',
                        }}>
                            New Bidding Rule
                        </h2>
                    </div>
            <div className="hotels-and-groups-list">
                <div className="hotel-collapsible-section">
                    <div className={`hotel-collapsible-header ${isHotelsOpen ? 'expanded' : ''}`} onClick={toggleHotels}>
                        <h2 className="hotel-title">Linked Hotels</h2>
                        <span className="arrow-icon">
                            {isHotelsOpen ? <img src={Icons.ActionsIconsSVG.UpArrowIconSVG} alt="Arrow Up" /> : <img src={Icons.ActionsIconsSVG.DownArrowIconSVG} alt="Arrow Down" />}
                        </span>
                    </div>
                    {isHotelsOpen && (
                        <div className="hotel-collapsible-content">
                            <MaterialReactTable
                                initialState={{
                                    density: 'compact',
                                    pagination: {
                                        pageIndex: 0,
                                        pageSize: 12,
                                    },
                                }}
                                columns={hotelColumns}
                                enableTopToolbar={false}
                                data={hotels}
                                getRowId={(row) => row.id.toString()}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                enableStickyHeader={true}
                                localization={{
                                    noRecordsToDisplay: 'No records available',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: '450px' } }}
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [],
                                }}
                                muiTablePaperProps={{
                                    sx: {
                                        boxShadow: 'none',
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        padding: '4px 8px',
                                        fontSize: '10px',
                                        fontWeight: 400,
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        padding: '4px 8px',
                                        fontSize: '0.75rem',
                                    },
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    sx: {
                                        height: '30px',
                                        color: row.original.line_disabled ? 'gray' : '#000000',
                                    },
                                })}
                                muiTableBodyCellProps={({ cell }) => ({
                                    sx: {
                                        fontSize: '12px',
                                        color: cell.row.original.line_disabled ? 'gray' : '#000000',
                                    },
                                })}
                                sx={{
                                    '& .MuiTableCell-root': {
                                        fontSize: '0.65rem',
                                        padding: '4px 8px',
                                    },
                                    '& .MuiTableRow-root': {
                                        height: '40px',
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="hotel-collapsible-section">
                    <div className={`hotel-collapsible-header ${isGroupsOpen ? 'expanded' : ''}`} onClick={toggleGroups}>
                        <h2 className="hotel-title">Linked Groups</h2>
                        <span className="arrow-icon">
                            {isGroupsOpen ? <img src={Icons.ActionsIconsSVG.UpArrowIconSVG} alt="Arrow Up" /> : <img src={Icons.ActionsIconsSVG.DownArrowIconSVG} alt="Arrow Down" />}
                        </span>
                    </div>
                    {isGroupsOpen && (
                        <div className="hotel-collapsible-content">
                            <MaterialReactTable
                                initialState={{
                                    density: 'compact',
                                    pagination: {
                                        pageIndex: 0,
                                        pageSize: 12,
                                    },
                                }}
                                enableTopToolbar={false}
                                columns={groupColumns}
                                data={groups}
                                getRowId={(row) => row.id.toString()}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                enableStickyHeader={true}
                                localization={{
                                    noRecordsToDisplay: 'No records available',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: '450px' } }}
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [],
                                }}
                                muiTablePaperProps={{
                                    sx: {
                                        boxShadow: 'none',
                                    },
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        padding: '4px 8px',
                                        fontSize: '10px',
                                        fontWeight: 400,
                                    },
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        padding: '4px 8px',
                                        fontSize: '0.75rem',
                                    },
                                }}
                                muiTableBodyRowProps={({ row }) => ({
                                    sx: {
                                        height: '30px',
                                        color: row.original.line_disabled ? 'gray' : '#000000',
                                    },
                                })}
                                muiTableBodyCellProps={({ cell }) => ({
                                    sx: {
                                        fontSize: '12px',
                                        color: cell.row.original.line_disabled ? 'gray' : '#000000',
                                    },
                                })}
                                sx={{
                                    '& .MuiTableCell-root': {
                                        fontSize: '0.65rem',
                                        padding: '4px 8px',
                                    },
                                    '& .MuiTableRow-root': {
                                        height: '40px',
                                    },
                                }}
                            />


                        </div>
                    )}
                </div>
            </div>
                </div>
            </div>
        </SlidingPanel>
    );
};

export default React.memo(HotelListModal);
