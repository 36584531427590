import { combineReducers, configureStore } from "@reduxjs/toolkit";
import calenderSlice from "./slices/calender/calenderSlice";
import metaSearchSlice from "./slices/metaSearch/metaSearch";
import accountSlice from "./slices/account/accountSlice";
const reducers = combineReducers({
  calender: calenderSlice,
  metaSearch: metaSearchSlice,
  account: accountSlice,
});
export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
