import React from "react";
import {
    Box,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION from "../../../constants/search-ads-criterion/googleSearchAdsLanguageCriterion";

const LanguageSelection = ({ selectedLanguage, setSelectedLanguage }) => {
    const primaryLanguageIds = [11, 15, 43, 16, 24];
    const primaryLanguages = GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION.filter((lang) =>
        primaryLanguageIds.includes(lang.id_lang)
    );
    const otherLanguages = GOOGLE_SEARCH_ADS_LANGUAGE_CRITERION.filter(
        (lang) => !primaryLanguageIds.includes(lang.id_lang)
    );

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    return (
        <Box className="languageSelection">
            <Typography className="sectionTitle">Select Your Target Audience Language</Typography>
            <Box display="flex" alignItems="center" p={2} gap={2}>
                <RadioGroup row value={selectedLanguage} onChange={handleLanguageChange}>
                    {primaryLanguages.map((language) => (
                        <FormControlLabel
                            key={language.id_lang}
                            value={language.id_lang}
                            control={<Radio />}
                            label={language.language_name}
                        />
                    ))}
                </RadioGroup>

                <FormControl className="languageSelect" sx={{ width: 'auto', minWidth: '125px' }}>
                    <Select
                        className="no-border-focus"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        displayEmpty
                        renderValue={selectedLanguage !== "" ? undefined : () => "Other"}
                    >
                        <MenuItem value="" disabled>
                            Other
                        </MenuItem>
                        {otherLanguages.map((language) => (
                            <MenuItem key={language.id_lang} value={language.id_lang}>
                                {language.language_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};

export default LanguageSelection;
