import { ReactComponent as AddIcon } from '../../assets/images/ActionIcons/Add.svg';
import AddIconSVG from '../../assets/images/ActionIcons/Add.svg';
import {ReactComponent as AddIconBlack} from '../../assets/images/ActionIcons/AddBlack.svg';
import AddIconBlackSVG from '../../assets/images/ActionIcons/AddBlack.svg';
import {ReactComponent as SearchIcon} from '../../assets/images/ActionIcons/Search.svg';
import SearchIconSVG from '../../assets/images/ActionIcons/Search.svg';

import CogsIconSVG from '../../assets/images/ActionIcons/Cogs.svg';
import {ReactComponent as CogsIcon} from '../../assets/images/ActionIcons/Cogs.svg';

import CogsGrayIconSVG from '../../assets/images/ActionIcons/CogsGray.svg';
import {ReactComponent as CogsGrayIcon} from '../../assets/images/ActionIcons/CogsGray.svg';

import {ReactComponent as CloseIcon} from '../../assets/images/ActionIcons/Close.svg';
import CloseIconSVG from '../../assets/images/ActionIcons/Close.svg';
import {ReactComponent as DeleteIcon} from '../../assets/images/ActionIcons/Delete.svg';
import DeleteIconSVG from '../../assets/images/ActionIcons/Delete.svg';
import {ReactComponent as ClosePanelIcon} from '../../assets/images/ActionIcons/ClosePanel.svg';
import ClosePanelIconSVG from '../../assets/images/ActionIcons/ClosePanel.svg';
import {ReactComponent as DownArrowIcon} from '../../assets/images/Accessibility/DownArrow.svg';
import DownArrowIconSVG from '../../assets/images/Accessibility/DownArrow.svg';
import {ReactComponent as UpArrowIcon} from '../../assets/images/Accessibility/UpArrow.svg';
import UpArrowIconSVG from '../../assets/images/Accessibility/UpArrow.svg';




const DmpIcons = {
    "ActionsIconsSVG": {
        AddIconSVG,
        AddIconBlackSVG,
        SearchIconSVG,
        CogsIconSVG,
        CogsGrayIconSVG,
        CloseIconSVG,
        DeleteIconSVG,
        ClosePanelIconSVG,
        DownArrowIconSVG,
        UpArrowIconSVG
    },
    "ActionsIcons": {
        AddIcon,
        AddIconBlack,
        SearchIcon,
        CogsIcon,
        CogsGrayIcon,
        CloseIcon,
        DeleteIcon,
        ClosePanelIcon,
        DownArrowIcon,
        UpArrowIcon
    }

};

export default DmpIcons;