// TagifyInput.js
import React, { useRef, useEffect } from 'react';
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

const TagifyInput = ({ value, onChange, settings }) => {
  const inputRef = useRef();

  useEffect(() => {
    const tagify = new Tagify(inputRef.current, settings);
    if (value && typeof value === 'string') {
      const tagsArray = value.split(' ').map(tag => ({ value: tag }));
      tagify.addTags(tagsArray);
    }

    // Handle changes in tags and pass the JSON string back to the parent
    tagify.on('change', (e) => {
      const tagsArray = e.detail.value ? JSON.parse(e.detail.value) : [];
      const newTagsString = tagsArray.map(tag => tag.value).join(' '); 
      onChange(newTagsString); 
    });

    return () => {
      tagify.destroy(); 
    };
  }, [settings, onChange]);

  return <input type="text" ref={inputRef} />;
};

export default TagifyInput;
