import React from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import './Modal.scss';

const DeleteConfirmationModal = ({ open, onClose, onConfirm, groupName, loading, error }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Group</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the group "{groupName}"? This action will move the hotels to the ungrouped list.
                </DialogContentText>

                {/* Error message in red, if any */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </DialogContent>
            <DialogActions>
                {/* Display loader instead of buttons while waiting for the response */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button
                            variant="outlined"
                            onClick={onClose}
                            className='cancelBtn'
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={onConfirm}
                            className='deleteBtn'
                        >
                            Delete
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DeleteConfirmationModal;
