import SimpleTable from "components/SimpleTable";
import { HISTORY_COLUMN_DEFS } from "constants/ColumnDefinitions/history";

export default function History() {
  const rows = [
    {
      id: "1",
      count: "243",
      date: "23/3/2002",
      status: "Import success",
      type: "Type",
      metaSearch: "Google",
      level: "Hotel",
      download: "Download",
    },
    {
      id: "1",
      count: "243",
      date: "23/3/2002",
      status: "Import success",
      type: "Type",
      metaSearch: "Google",
      level: "Hotel",
      download: "Download",
    },
    {
      id: "1",
      count: "243",
      date: "23/3/2002",
      status: "Import success",
      type: "Type",
      metaSearch: "Google",
      level: "Hotel",
      download: "Download",
    },
    {
      id: "1",
      count: "243",
      date: "23/3/2002",
      status: "Import success",
      type: "Type",
      metaSearch: "Google",
      level: "Hotel",
      download: "Download",
    },
    {
      id: "1",
      count: "243",
      date: "23/3/2002",
      status: "Import success",
      type: "Type",
      metaSearch: "Google",
      level: "Hotel",
      download: "Download",
    },
  ];
  return (
    <div>
      <SimpleTable columns={HISTORY_COLUMN_DEFS} rows={rows} />
    </div>
  );
}
