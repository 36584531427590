import React, { useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress } from '@mui/material';

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
    const [loading, setLoading] = useState(false); // State for loading spinner

    const handleConfirm = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error) {
            console.error('Error during confirmation:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: 400,
                    p: 4,
                    backgroundColor: 'white',
                    margin: 'auto',
                    marginTop: '10%',
                    borderRadius: 2,
                    boxShadow: 24,
                    textAlign: 'center', // Center the content inside the modal
                }}
            >
                <Typography variant="h6" sx={{ mb: 3 }}>
                    {message}
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button variant="outlined" onClick={onClose} disabled={loading} fullWidth sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleConfirm}
                        disabled={loading}
                        fullWidth
                        startIcon={loading && <CircularProgress size={20} />}
                    >
                        {loading ? 'Processing...' : 'Confirm'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;