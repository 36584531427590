import React from "react";
import {
    Box,
    Typography,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputAdornment,
    Grid,
} from "@mui/material";
import {AD_LOCATIONS} from "../../../constants/adLocations";
import {BIDDING_STRATEGIES} from "../../../constants/biddingStrategies";

const CampaignSettingsDetails = ({
    biddingStrategy,
    setBiddingStrategy,
    campaignName,
    setCampaignName,
    targetCpa,
    setTargetCpa,
    targetRoas,
    setTargetRoas,
    maxCpcLimit,
    setMaxCpcLimit,
    impressionShare,
    setImpressionShare,
    adLocation,
    setAdLocation,
    errors
}) => {
    const handleBiddingStrategyChange = (event) => {
        setBiddingStrategy(event.target.value);
    };

    const renderMaximizeConversionsFields = () => (
        <TextField
            className="no-border-focus"
            placeholder="Target CPA"
            variant="outlined"
            value={targetCpa}
            onChange={(e) => setTargetCpa(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={errors.targetCpaError}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{ mr: 1 }}>€</InputAdornment>,
            }}
            sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                    borderColor: errors.targetCpaError ? 'red !important' : 'inherit'
                }
            }}
        />
    );

    const renderMaximizeConversionValueFields = () => (
        <TextField
            className="no-border-focus"
            placeholder="Target ROAS"
            variant="outlined"
            value={targetRoas}
            onChange={(e) => setTargetRoas(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={errors.targetRoasError}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                    borderColor: errors.targetRoasError ? 'red !important' : 'inherit'
                }
            }}
        />
    );

    const renderMaximizeClicksFields = () => (
        <TextField
            className="no-border-focus"
            placeholder="Maximum CPC Bid Limit"
            variant="outlined"
            value={maxCpcLimit}
            onChange={(e) => setMaxCpcLimit(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={errors.maxCpcLimitError}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{ mr: 1 }}>€</InputAdornment>,
            }}
            sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                    borderColor: errors.maxCpcLimitError ? 'red !important' : 'inherit'
                }
            }}
        />
    );

    const renderManualCpcFields = () => (
        <TextField
            className="no-border-focus"
            placeholder="CPC"
            variant="outlined"
            value={maxCpcLimit}
            onChange={(e) => setMaxCpcLimit(e.target.value)}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            error={errors.maxCpcLimitError}
            InputProps={{
                startAdornment: <InputAdornment position="start" sx={{ mr: 1 }}>€</InputAdornment>,
            }}
            sx={{
                backgroundColor: 'white',
                '& .MuiOutlinedInput-root': {
                    borderColor: errors.maxCpcLimitError ? 'red !important' : 'inherit'
                }
            }}
        />
    );

    const renderMaximizeImpressionShareFields = () => (
        <>
            <TextField
                className="no-border-focus"
                placeholder="Percent Impression Share"
                variant="outlined"
                value={impressionShare}
                onChange={(e) => setImpressionShare(e.target.value)}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={errors.impressionShareError}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                sx={{
                    backgroundColor: 'white',
                    marginRight: "15px",
                    '& .MuiOutlinedInput-root': {
                        borderColor: errors.impressionShareError ? 'red !important' : 'inherit'
                    }
                }}
            />
            <TextField
                className="no-border-focus"
                placeholder="Maximum CPC Bid Limit"
                variant="outlined"
                value={maxCpcLimit}
                onChange={(e) => setMaxCpcLimit(e.target.value)}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                error={errors.maxCpcLimitError}
                InputProps={{
                    startAdornment: <InputAdornment position="start" sx={{ mr: 1 }}>€</InputAdornment>,
                }}
                sx={{
                    backgroundColor: 'white',
                    marginRight: "15px",
                    '& .MuiOutlinedInput-root': {
                        borderColor: errors.maxCpcLimitError ? 'red !important' : 'inherit'
                    }
                }}
            />
            <FormControl
                sx={{
                    backgroundColor: 'white',
                    width: 'auto',
                    marginRight: "15px",
                    minWidth: '225px'
                }}
                error={errors.adLocationError}
            >
                <Select
                    className="no-border-focus"
                    value={adLocation}
                    onChange={(e) => setAdLocation(e.target.value)}
                    displayEmpty
                    renderValue={adLocation !== "" ? undefined : () => "Choose an Ad Location"}
                    sx={{
                        backgroundColor: 'white',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: errors.adLocationError ? 'red !important' : 'inherit'
                        }
                    }}
                >
                    <MenuItem value="" disabled>
                        Choose an Ad Location
                    </MenuItem>
                    {Object.entries(AD_LOCATIONS).map(([key, value]) => {
                        const formattedValue = key.toUpperCase();
                        return (
                            <MenuItem key={formattedValue} value={formattedValue}>
                                {value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );

    return (
        <Box className="campaignSettingsDetails">
            <Box display="flex" gap={2}>
                <Typography className="sectionTitle">Create Campaign Name and Select Your Bidding Strategy</Typography>
            </Box>
            <Box p={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3} display="flex" justifyContent="center">
                        <TextField
                            className="no-border-focus"
                            placeholder="Campaign Name"
                            variant="outlined"
                            value={campaignName}
                            onChange={(e) => setCampaignName(e.target.value)}
                            error={errors.campaignNameError}
                            sx={{
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-root': {
                                    borderColor: errors.campaignNameError ? 'red !important' : 'inherit'
                                },
                                margin: "0 15px"
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={9}>
                        <FormControl
                            sx={{
                                backgroundColor: 'white',
                                marginRight: "25px",
                                width: 'auto',
                                minWidth: '225px'
                            }}
                            error={errors.biddingStrategyError}
                        >
                            <Select
                                className="no-border-focus"
                                value={biddingStrategy}
                                onChange={handleBiddingStrategyChange}
                                displayEmpty
                                renderValue={biddingStrategy !== "" ? undefined : () => "Choose a bidding strategy"}
                                sx={{
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: errors.biddingStrategyError ? 'red !important' : 'inherit'
                                    }
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Choose a bidding strategy
                                </MenuItem>
                                {Object.entries(BIDDING_STRATEGIES).map(([key, value]) => {
                                    const formattedValue = key;
                                    return (
                                        <MenuItem key={formattedValue} value={formattedValue}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        {biddingStrategy === "MAXIMIZE_CONVERSIONS" && renderMaximizeConversionsFields()}
                        {biddingStrategy === "MAXIMIZE_CONVERSION_VALUE" && renderMaximizeConversionValueFields()}
                        {biddingStrategy === "MAXIMIZE_CLICKS" && renderMaximizeClicksFields()}
                        {(biddingStrategy === "MANUAL_CPC" || biddingStrategy === "MANUAL_CPC_ENHANCED") && renderManualCpcFields()}
                        {biddingStrategy === "TARGET_IMPRESSION_SHARE" && renderMaximizeImpressionShareFields()}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CampaignSettingsDetails;