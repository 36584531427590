import React from "react";
import { Box, Button } from "@mui/material";
import './ObjectiveAccordion.scss';

const ObjectiveAccordion = ({ selectedObjective, setSelectedObjective }) => {
    const objectives = [
        { name: "Brand Protection", description: "Drive sales online, in app, by phone, or in store" },
        { name: "Generic Campaign", description: "Drive sales online, in app, by phone, or in store" },
    ];

    return (
        <Box className="search-ads-form-objective">
            <Box className="search-ads-form-objective-buttons">
                {objectives.map((objective) => (
                    <Button
                        key={objective.name}
                        className={`objectiveButton ${selectedObjective === objective.name ? "selected" : ""}`}
                        onClick={() => setSelectedObjective(objective.name)}
                    >
                        <div>{objective.name}</div>
                        <small>{objective.description}</small>
                    </Button>
                ))}
            </Box>
        </Box>
    );
};

export default ObjectiveAccordion;
