import { Grid } from "@mui/material";
import Table from "components/Table";
import { metaSearchTableData } from "mock/tableData";
import GoogleAds from "assets/images/GoogleHotelAds.svg";
import TripAdvisor from "assets/images/TripAdvisor.svg";
import Trivago from "assets/images/Trivago.svg";
import SkyScanner from "assets/images/SkyScanner.svg";
import Kayak from "assets/images/Kayak.svg";
import BingAds from "assets/images/BingAds.svg";
import { useEffect, useState } from "react";
import { METASEARCH_COLUMN_DEFINITIONS } from "constants/ColumnDefinitions/metasearch";
import TextInput from "components/TextInput";
import BudgetCapModal from "../budgetCapModal";
import TableValueEditor from "../tableValueEditor";
import { api_urls_charts } from "../chartContainer";
import { createSearchParams } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const TableCellImage = ({ channelId, cell }) => {
  const ChannelImageMapper = {
    googleHotelAds: GoogleAds,
    tripadvisor: TripAdvisor,
    trivago: Trivago,
    skyscanner: SkyScanner,
    kayak: Kayak,
    bingAds: BingAds,
  };
  if (Object.keys(ChannelImageMapper).find((ch) => ch === channelId)) {
    return <img src={ChannelImageMapper[channelId]} alt="" />;
  }
  return channelId;
};
const TableContainer = ({
  isChartCollapsed,
  setIsChartCollapsed,
  onEditClick,
}) => {
  const [openMonthlyBudgetCapModal, setOpenMonthlyBudgetCapModal] =
    useState(false); //test1
  const [deskTopdateModal, setdeskTopDateModal] = useState(false);
  // const columns = METASEARCH_COLUMN_DEFINITIONS?.map((item) => {
  //   if (item.accessorKey === "channel") {
  //     return {
  //       ...item,
  //       isSelected: true,

  //       muiTableBodyCellProps: ({ row }) => {
  //         return {
  //           sx: {
  //             '& [data-pinned="true"]:before': {
  //               backgroundColor: row.depth === 0 ? "#f5f5f5" : "#ffff",
  //             },
  //           },
  //         };
  //       },
  //       Cell: ({ cell }) => (
  //         <TableCellImage channelId={cell.getValue()} cell={cell} />
  //       ),
  //     };
  //   }
  //   if (item.accessorKey === "monthlyBudgetCap") {
  //     console.log("monnn");

  //     return {
  //       ...item,
  //       isSelected: true,
  //       Cell: ({ cell }) => {
  //         return (
  //           <TextInput
  //             value={cell.getValue()}
  //             sx={{ width: "69px" }}
  //             onClick={() => {
  //               setOpenMonthlyBudgetCapModal(true);
  //             }}
  //           />
  //         );
  //       },
  //     };
  //   }
  //   if (item.accessorKey === "test1") {
  //     console.log("monnn");

  //     return {
  //       ...item,
  //       isSelected: true,
  //       Cell: ({ cell }) => {
  //         return (
  //           <div>
  //             <TextInput
  //               value={cell.getValue()}
  //               sx={{ width: "69px" }}
  //               onClick={() => {
  //                 console.log("90::::::");
  //                 setdeskTopDateModal(true);
  //               }}
  //             />
  //           </div>
  //         );
  //       },
  //     };
  //   }
  //   return {
  //     ...item,
  //     isSelected: true,
  //   };
  // });
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const token = sessionStorage.getItem("token") || "";
  const { account_id = "" } = useSelector(
    (state) => state?.account?.account || {}
  );
  const { currentDateRange = {} } = useSelector((state) => state?.calender);
  const {
    level = "",
    hotel_id = [],
    group_id = [],
    segment_list = [],
  } = useSelector((state) => state?.metaSearch);

  useEffect(() => {
    const params = {
      account_id,
      level,
      "segment_list[]": segment_list,
      start_date: format(
        currentDateRange?.startDate || new Date(),
        "yyyy-MM-dd"
      ),
      end_date: format(currentDateRange?.endDate || new Date(), "yyyy-MM-dd"),
      ...(level === "HOTEL"
        ? { "hotel_id[]": hotel_id }
        : { "group_id[]": group_id }),
    };

    if (account_id) {
      axios
        .get(
          `${
            api_urls_charts["skyScanner"]
          }/api/skyscanner/reporting/?${createSearchParams(params)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const data = res?.data;
          console.log(data);
          const mappedColumns = data?.columns?.map((col, index) => {
            let renderCell = ({ cell }) => (
              <span key={index}>{cell.getValue()}</span>
            );

            if (col?.label === "Budget") {
              renderCell = ({ cell }) => (
                <TextInput
                  key={index}
                  value={cell.getValue()}
                  sx={{ width: "69px" }}
                  onClick={() => {
                    setOpenMonthlyBudgetCapModal(true);
                  }}
                />
              );
            }

            if (col?.label === "Bids") {
              renderCell = ({ cell }) => {
                return (
                  <div>
                    <TextInput
                      value={cell.getValue()}
                      sx={{ width: "69px" }}
                      onClick={() => {
                        setdeskTopDateModal(true);
                      }}
                    />
                  </div>
                );
              };
            }
            return {
              id: index.toString(),
              accessorKey: col.id,
              header: col?.label,
              Cell: renderCell,
              isSelected: true,
              type: col?.type,
            };
          });

          const formatRows = (row, id) => {
            const metricsRows = Object.keys(row?.metrics_list).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.metrics_list?.[curr]?.value ?? "-",
                };
              },
              {}
            );

            const segmentsRows = Object.keys(row?.segment_list).reduce(
              (acc, curr) => {
                return {
                  ...acc,
                  [curr]: row?.segment_list?.[curr]?.label ?? "-",
                };
              },
              {}
            );

            return {
              id,
              ...segmentsRows,
              ...metricsRows,
              bid: "0",
              budget_spent: "-",
            };
          };

          const rowsToDisplay = data?.row_list?.map((row, ind) =>
            formatRows(row, ind + 1)
          );

          setColumns(mappedColumns);
          setSelectedColumns(mappedColumns);
          setTableData([formatRows(data?.total_row, "0"), ...rowsToDisplay]);
        });
    }
  }, [
    token,
    account_id,
    currentDateRange,
    level,
    segment_list,
    hotel_id,
    group_id,
  ]);

  return (
    <>
      {deskTopdateModal && (
        <TableValueEditor
          open={deskTopdateModal}
          onClose={() => setdeskTopDateModal(false)}
        />
      )}
      <Grid sx={{ marginBlock: "10px", maxWidth: "100%" }}>
        <Table
          rows={tableData}
          columns={columns}
          selectedColumns={selectedColumns}
          handleColumnSelectionChange={(columns) => {
            setSelectedColumns(columns);
          }}
          isChartCollapsed={isChartCollapsed}
          setIsChartCollapsed={setIsChartCollapsed}
          maxHeight={isChartCollapsed ? "calc(100vh - 255px)" : "355px"}
          onEditClick={() => {
            onEditClick();
          }}
        />
      </Grid>
      {openMonthlyBudgetCapModal && (
        <BudgetCapModal
          open={openMonthlyBudgetCapModal}
          onClose={() => setOpenMonthlyBudgetCapModal(false)}
        />
      )}
    </>
  );
};

export default TableContainer;
