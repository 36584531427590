export const ghaMultiplierFormFields = [
  {
    id: "multiplier",
    label: "Multiplier",
    type: "textField",
    placeholder: "Please enter a value",
  },
  {
    id: "DESKTOP",
    label: "Desktop",
    type: "textField",
    placeholder: "Please enter a value",
  },
  {
    id: "MOBILE",
    label: "Mobile",
    type: "textField",
    placeholder: "Please enter a value",
  },
  {
    id: "TABLET",
    label: "Tablet",
    type: "textField",
    placeholder: "Please enter a value",
  },

  {
    id: "numberOfNights",
    label: "Number of nights",
    type: "range",
    placeholder: "-- Select a strategy --",
  },
  {
    id: "multiplierValue",
    label: "Multiplier Value",
    type: "textField",
    placeholder: "Please enter a value",
    isAdder: true,
  },
  {
    id: "numberOfDays",
    label: "Number of days",
    type: "select",
    placeholder: "-- Select a strategy --",
  },
  {
    id: "days",
    label: "Days",
    type: "select",
    placeholder: "Select Days",
    options: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8-14",
      "15-19",
      "30+",
    ].map((val) => ({ id: val, label: val, value: val })),
  },
  {
    id: "bidMultiplier",
    label: "Bid Multiplier",
    type: "textField",
    placeholder: "Please enter a value",
    isAdder: true,
  },
  {
    id: "default",
    label: "Default",
    type: "textField",
    placeholder: "Bid multiplier",
  },
  {
    id: "userSelected",
    label: "User selected",
    type: "textField",
    placeholder: "Bid multiplier",
  },
  {
    id: "monday",
    label: "Monday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "tuesday",
    label: "Tuesday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "wednesday",
    label: "Wednesday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "thursday",
    label: "Thursday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "friday",
    label: "Friday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "saturday",
    label: "Saturday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
  {
    id: "sunday",
    label: "Sunday",
    type: "textField",
    subType: "number",
    placeholder: "Bid multiplier",
  },
];
export const ghaButtons = [
  {
    id: "device",
    label: "Device",
    value: "DEVICE",
  },

  {
    id: "lengthOfStay",
    label: "Length of stay",
    value: "LENGTH_OF_STAY",
  },
  {
    id: "checkInDay",
    label: "Check in day",
    value: "CHECKIN_DAY",
  },
  {
    id: "dayArrival",
    label: "Days arrival",
    value: "DAYS_TO_ARRIVAL",
  },
  {
    id: "dateType",
    label: "Date Type",
    value: "DATE_TYPE",
  },
];

export const allFields = {
  lengthOfStay: [
    "device_identifier",
    "numberOfNights",
    "empty",
    "multiplierValue",
  ],
  checkInDay: [
    "device_identifier",
    "empty",
    "monday",
    "friday",
    "tuesday",
    "saturday",
    "wednesday",
    "sunday",
    "thursday",
  ],
  dayArrival: ["days", "bidMultiplier"],
  device: ["desktop", "tablet", "mobile"],
  dateType: ["default", "userSelected"],
};
