// images
import IconMetaSearch from "../assets/images/IconMetaSearch.svg";
import IconSocialAds from "../assets/images/IconSocialAds.svg";
import IconSearchAds from "../assets/images/IconSearchAds.svg";
import IconOtaAds from "../assets/images/IconOtaAds.svg";
import IconPMax from "../assets/images/IconPMax.svg";
import IconSeo from "../assets/images/IconSeo.svg";
import IconCms from "../assets/images/IconCms.svg";
import IconRateMatch from "../assets/images/IconRateMatch.svg";
import IconPushTech from "../assets/images/IconPushTech.svg";
import IconHome from "../assets/images/IconHome.svg";

import {
  REACT_APP_ICONCMS_URL,
  REACT_APP_RATEMATCH_URL,
  REACT_APP_ICONSEO_URL,
  REACT_APP_PUSHTECH_URL,
} from "./external_urls";

export const homepageSideMyMenuItems = [
  {
    name: "Meta Search",
    icon: IconMetaSearch,
    disabled: false,
    url: "/metasearch",
  },
  {
    name: "Search ads",
    icon: IconSearchAds,
    disabled: false,
    url: "/search-ads-campaign",
  },
  {
    name: "PMax",
    icon: IconPMax,
    disabled: false,
    url: "/productSignUpPage",
  },
];
export const drawerItems = [
  {
    name: "Home",
    icon: IconHome,
    disabled: false,
    url: "/home",
  },
  {
    name: "Meta",
    icon: IconMetaSearch,
    disabled: false,
    url: "/metasearch",
  },
  {
    name: "SEM",
    icon: IconSearchAds,
    disabled: false,
    url: "/searchads",
  },
  {
    name: "PMAX",
    icon: IconPMax,
    disabled: false,
    url: "/productSignUpPage",
  },
];

export const PRODUCT_MENU_LIST = [
  {
    heading: "Paid Ads",
    className: "split",
    menuItems: [
      {
        icon: IconMetaSearch,
        text: "META SEARCH",
        url: "/",
      },
      {
        icon: IconSearchAds,
        text: "SEARCH ADS",
        external: true,
        url: "/",
      },
      {
        icon: IconPMax,
        text: "PMAX",
        external: true,
        url: "/",
      },
    ],
  },
  {
    heading: "Website",
    menuItems: [
      {
        icon: IconSeo,
        text: "SEO",
        url: REACT_APP_ICONSEO_URL,
      },
      {
        icon: IconCms,
        text: "CMS",
        url: REACT_APP_ICONCMS_URL,
      },
    ],
  },
  {
    heading: "Rate Match",
    menuItems: [
      {
        icon: IconRateMatch,
        text: "RATE MATCH",
        url: REACT_APP_RATEMATCH_URL,
      },
    ],
  },
  {
    heading: "CRM",
    menuItems: [
      {
        icon: IconPushTech,
        text: "PUSHTECH",
        url: REACT_APP_PUSHTECH_URL,
      },
    ],
  },
];

export const homepageSideMoreMenuItems = [
  {
    name: "SOCIAL ADS",
    icon: IconSocialAds,
    disabled: false,
  },
  {
    name: "OTA ADS",
    icon: IconOtaAds,
    disabled: false,
  },
];

export const homepageMenuItems = [
  {
    id: "settings",
    label: "Settings",

    children: [
      { id: "groups", label: "Groups", link: "/groups" },

      { id: "bidding-center", label: "Bidding Center", link: "/bidding-center/124" },
      {
        id: "bidding-offline",
        label: "Bidding Offline",
        link: "/biddingOffline",
      },
    ],
  },
  {
    id: "reports",
    label: "Reports",

    children: [
      { id: "report-center", label: "Report Center" , link: "/report-center/124"},
      { id: "alert-center", label: "Alert Center", link: "/alert-center/124" },
    ],
  },
  {
    id: "reservations",
    label: "Reservations",

    children: [
      { id: "main-reservation", label: "Reservations", link: "/reservations/124" },
    ],
  },
];
