import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import DefaultLayout from "../../layout/defaultLayout";
import ReservationsList from "features/reservations/Reservations";
import ReservationFilters from "features/reservations/Reservationfilters/index";
import PageTitles from "../../components/PageTitles";

const  ReservationsPage = () => {
    const { idaccount } = useParams();
    const [page, setPage] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState({
        idPlatform: '',
        bookingMonth: '',
        checkOutMonth: '',
        bookingStatus: '',
        billingMode: '',
    });
    const handleFiltersUpdate = (newFilters) => {
        setSelectedFilters(newFilters);
        console.log("Updated Filters in Parent:", newFilters);
    };

    const resetPage = (newPage) => {
        setPage(newPage);
    };

    const setDefaultFilterIfNoFilterSelected = () => {
        const currentDate = new Date();
        const currentYearMonth = currentDate.toISOString().slice(0, 7); // Format: YYYY-MM
        // Check if all filters are empty
        const areFiltersEmpty = Object.values(selectedFilters).every(value => value === '');
        if (areFiltersEmpty) {
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                bookingMonth: currentYearMonth, // Update bookingMonth to current year-month
            }));
        }
    };

    //-Set Default filter if no filter selected
    setDefaultFilterIfNoFilterSelected();

    return (
        <div className="reservations">
            <DefaultLayout page="metaSearch">

                <PageTitles
                    pageTitle="Metasearch"
                    pageSubTitle="Reservations"
                />

                <Box sx={{}}>
                    <Box sx={{width: "100%"}}>
                        <div style={{marginLeft: "18px", marginTop:"50px"}}>
                            <ReservationFilters
                                selectedFilters={selectedFilters}
                                onFiltersChange={handleFiltersUpdate}
                                resetPage={resetPage}
                            />
                            {/*<pre>{JSON.stringify(selectedFilters, null, 2)}</pre>*/}
                        </div>
                    </Box>
                    <ReservationsList
                        idAccount={idaccount}
                        selectedFilters={selectedFilters}
                        page={page}
                        resetPage={resetPage}
                    />
                </Box>
            </DefaultLayout>
        </div>
    );
};
export default ReservationsPage;
