export const validateCampaignSettings = ({
    campaignName,
    biddingStrategy,
    targetCpa,
    targetRoas,
    maxCpcLimit,
    impressionShare,
    adLocation,
    targetValue
}) => {
    const errors = {};

    errors.campaignNameError = !campaignName;
    errors.biddingStrategyError = !biddingStrategy;

    if (biddingStrategy === "MAXIMIZE_CONVERSIONS") {
        errors.targetCpaError = !targetCpa;
    }

    if (biddingStrategy === "MAXIMIZE_CONVERSION_VALUE") {
        errors.targetRoasError = !targetRoas;
    }

    if (biddingStrategy === "MAXIMIZE_CLICKS") {
        errors.maxCpcLimitError = !maxCpcLimit;
    }

    if (biddingStrategy === "TARGET_IMPRESSION_SHARE") {
        errors.impressionShareError = !impressionShare;
        errors.adLocationError = !adLocation;
    }

    errors.targetValueError = !targetValue;

    return errors;
};

export const validateKeywords = ({ keywords, negativeKeywords }) => {
    const errors = { keywords: [], negativeKeywords: [] };

    keywords.forEach((keyword, index) => {
        if (!keyword.keyword || !keyword.matchType) {
            errors.keywords[index] = true;
        }
    });

    negativeKeywords.forEach((keyword, index) => {
        if (!keyword.keyword || !keyword.matchType) {
            errors.negativeKeywords[index] = true;
        }
    });

    return errors;
};