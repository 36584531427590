import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DmpIcons from 'components/DmpIcons/icons';

const SearchTableInputComponent = ({ placeholder, value, onChange }) => {
    return (
        <TextField
            variant="outlined"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            InputProps={{
                sx: {
                    height: '24px',
                    borderRadius: '12px',
                    backgroundColor: '#FFFFFF',
                    '& .MuiInputBase-input': {
                        padding: '4px 0px',
                        fontSize: '0.7rem',
                    },
                    '& fieldset': {
                        borderColor: '#CCCCCC',
                    },
                },
                startAdornment: (
                    <InputAdornment position="start">
                        <img src={DmpIcons.ActionsIconsSVG.SearchIconSVG} alt="search icon" />
                    </InputAdornment>
                ),
            }}
            sx={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '150px',
            }}
        />
    );
};

export default SearchTableInputComponent;
