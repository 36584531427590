import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {APP_ALERT_CENTER_API_ENDPOINT} from "../../../constants/api_urls";

const HotelListTable = ({ idaccount, jwtToken, onHotelSelect, rowSelection, setRowSelection }) => {
    const [hotels, setHotels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const columns = useMemo(() => [
        { accessorKey: 'id', header: 'ID', maxSize: 40 },
        { accessorKey: 'name', header: 'Name', maxSize: 220 },
        { accessorKey: 'country', header: 'Country', maxSize: 40 },
    ], []);

    useEffect(() => {
        const fetchHotels = async () => {
            try {
                const response = await fetch(`${APP_ALERT_CENTER_API_ENDPOINT}/api/alert-center-budget/google-search-ads/list-hotels/${idaccount}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                setHotels(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchHotels();
    }, [idaccount, jwtToken]);

    useEffect(() => {
        console.log('rowSelection:', rowSelection);
        const selectedIds = Object.keys(rowSelection);
        const selectedHotels = selectedIds.map(id =>
            hotels.find(hotel => hotel.id.toString() === id)
        );
        console.log('Selected hotels in HotelListTable:', selectedHotels);
        onHotelSelect(selectedHotels);
    }, [rowSelection, hotels]);

    useEffect(() => {
        if (hotels.length > 0 && Object.keys(rowSelection).length > 0) {
            const selectedIds = Object.keys(rowSelection);
            const selectedHotels = selectedIds
                .map(id => hotels.find(hotel => hotel.id.toString() === id))
                .filter(hotel => hotel !== undefined); // Filter out undefined entries

            console.log('Filtered Selected Hotels:', selectedHotels);
            onHotelSelect(selectedHotels);
        }
    }, [rowSelection, hotels, onHotelSelect]);

    return (
        <div className="table-container">
            <br/>
            <div className="alert-table-title">
                <h4>Select Hotels</h4>
            </div>
            <MaterialReactTable
                initialState={{
                    density: 'compact',
                    pagination: {
                        pageIndex: 0,
                        pageSize: 12,
                    },
                }}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableStickyHeader={true}
                columns={columns}
                data={hotels}
                enableRowSelection
                enableSelectAll={false}
                muiTablePaperProps={{
                    sx: {
                        boxShadow: 'none',
                        width: '100%',
                    },
                }}
                getRowId={(row) => row.id.toString()} // Ensure string ID for comparison

            />
        </div>
    );
};

export default HotelListTable;
