import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Button, Box, CircularProgress, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HotelsTable from "../../Table/Hotels/HotelsTable";

const HotelsAccessAccordion = ({ expanded, handleChange, hotelsData, preselectedHotels, onSaveHotels, onCancel, accountName }) => {
    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    useEffect(() => {
        const preselectRows = {};
        preselectedHotels.forEach(hotelId => {
            preselectRows[hotelId] = true;
        });
        setRowSelection(preselectRows);
        setCurrentPage(1);
    }, [preselectedHotels]);

    const handleRowSelectionChange = (newRowSelection) => {
        setRowSelection(newRowSelection);
    };

    const handleSelectAll = (selectAll) => {
        const allSelected = {};
        if (selectAll) {
            hotelsData.forEach(hotel => {
                allSelected[hotel.id] = true;
            });
        }
        setRowSelection(allSelected);
    };

    const handleSave = () => {
        setLoading(true);
        const selectedHotelIds = Object.keys(rowSelection).filter(id => rowSelection[id]);
        onSaveHotels(selectedHotelIds).finally(() => setLoading(false));
    };

    const paginatedData = hotelsData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handleNextPage = () => {
        if (currentPage * rowsPerPage < hotelsData.length) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            sx={{
                border: '1px solid #61D5A9',
                borderRadius: '8px',
                marginBottom: '10px',
                '&::before': expanded === 'panel3' ? {} : { content: 'none' },
                boxShadow: 'none',
                ...(expanded === 'panel3' && {
                    borderBottomLeftRadius: '2px',
                    borderBottomRightRadius: '2px',
                })
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
                borderBottom: expanded === 'panel3' ? '1px solid #61D5A9' : 'none',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                height: '42px'
            }}>
                <Grid container alignItems="center" sx={{ padding: '4px' }}>
                    <Typography sx={{ fontWeight: '400', fontFamily: 'Inter', lineHeight: '14.52px' }}>
                        {accountName} : Hotels Access
                    </Typography>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{
                backgroundColor: '#F5F5F5',
                height: '100%',
            }}>
                <Grid container spacing={2} sx={{ padding: '8px', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12}>
                        <HotelsTable
                            hotelsData={paginatedData}
                            rowSelection={rowSelection}
                            onRowSelectionChange={handleRowSelectionChange}
                            loading={loading}
                            onSelectAll={handleSelectAll}
                        />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                width: '100px',
                                height: '24px',
                                borderRadius: '12px',
                                backgroundColor: '#12794F',
                                color: 'white',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    color: '#12794F',
                                    backgroundColor: 'white',
                                },
                                fontFamily: 'Inter'
                            }}
                            onClick={handleSave}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={12} sx={{ color: 'white' }} /> : 'Save'}
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                width: '100px',
                                height: '24px',
                                borderRadius: '12px',
                                backgroundColor: 'gray',
                                color: 'black',
                                textTransform: 'capitalize',
                                '&:hover': {
                                    color: 'gray',
                                    backgroundColor: 'white'
                                },
                                fontFamily: 'Inter'
                            }}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={handlePreviousPage} disabled={currentPage === 1}>
                            <ChevronLeftIcon fontSize="small" />
                        </IconButton>
                        <Typography variant="body2" sx={{ fontSize: '14px', mx: 1 }}>
                            Page {currentPage} of {Math.ceil(hotelsData.length / rowsPerPage)}
                        </Typography>
                        <IconButton
                            onClick={handleNextPage}
                            disabled={currentPage * rowsPerPage >= hotelsData.length}
                        >
                            <ChevronRightIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default HotelsAccessAccordion;
