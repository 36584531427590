export const APP_LOGIN_API_ENDPOINT = process.env.REACT_APP_LOGIN_API_ENDPOINT;
export const APP_RESET_PASSWORD_API = process.env.REACT_APP_RESET_API_PASSWORD;
export const APP_LOST_PASSWORD_ENDPOINT = process.env.REACT_APP_LOST_PASSWORD_ENDPOINT;
export const APP_ACCOUNT_API_ENDPOINT = process.env.REACT_APP_ACCOUNT_API_ENDPOINT;
export const APP_SKYSCANNER_BIDDING_API_ENDPOINT = process.env.REACT_APP_SKYSCANNER_BIDDING_API_ENDPOINT;
export const APP_TRIVAGO_BIDDING_API_ENDPOINT = process.env.REACT_APP_TRIVAGO_BIDDING_API_ENDPOINT;
export const APP_TRIPADVISOR_BIDDING_API_ENDPOINT = process.env.REACT_APP_TRIPADVISOR_BIDDING_API_ENDPOINT;
export const APP_KAYAK_BIDDING_API_ENDPOINT = process.env.REACT_APP_KAYAK_BIDDING_API_ENDPOINT;
export const APP_GHA_BIDDING_API_ENDPOINT = process.env.REACT_APP_GHA_BIDDING_API_ENDPOINT;
export const APP_BING_BIDDING_API_ENDPOINT = process.env.REACT_APP_BING_BIDDING_API_ENDPOINT;
export const APP_GROUP_EDITOR_API_ENDPOINT = process.env.REACT_APP_GROUP_EDITOR_API_ENDPOINT;
export const APP_RESERVATIONS_API_ENDPOINT = process.env.REACT_APP_RESERVATIONS_API_ENDPOINT;
export const REACT_APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT = process.env.REACT_APP_SUBUSER_MANAGEMENT_EDITOR_API_ENDPOINT;
export const APP_WEGO_BIDDING_API_ENDPOINT = process.env.REACT_APP_WEGO_BIDDING_API_ENDPOINT;
export const APP_ALERT_CENTER_API_ENDPOINT = process.env.REACT_APP_ALERT_CENTER_API_ENDPOINT;
export const APP_BIDDING_CENTER_API_ENDPOINT = process.env.REACT_APP_BIDDING_CENTER_API_ENDPOINT;
export const APP_REPORT_CENTER_API_ENDPOINT= process.env.REACT_APP_REPORT_CENTER_API_ENDPOINT
export const REACT_APP_SEARCH_ADS_CAMPAIGN_API_ENDPOINT = process.env.REACT_APP_SEARCH_ADS_CAMPAIGN_API_ENDPOINT