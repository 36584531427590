import React, { useState, useEffect } from 'react';
import { Drawer, Box, Typography, Accordion, AccordionSummary, AccordionDetails, Button, Grid, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import GlobalReportForm from "../Forms/GlobalPerformance";
import GoogleFBL from "../Forms/GoogleFBL";
import Metasearch from "../Forms/Metasearch";
import PerformancePmax from "../Forms/PerformancePmax";
import SpFunnelTripAdvisor from "../Forms/SpFunnelTripAdvisor";
import PriceCompetitiveness from "../Forms/PriceCompetitiveness";
import PerformanceMarketPmax from "../Forms/PerformanceMarketPmax";
import Billing from "../Forms/Billing";
import Reservations from "../Forms/Reservations";
import PerformanceGoogleMissedOpportunity from "../Forms/PerformanceGoogleMissedOpportunity";
import './styles.scss'; 

const SlidingPanel =  ({ open, onClose, skipStep1, reportData , report_type, isEdit }) => {
  const [expanded, setExpanded] = useState(skipStep1 ? 'panel2' : 'panel1');
  const [selectedReport, setSelectedReport] = useState(null);
  const [panel2Visible, setPanel2Visible] = useState(skipStep1);
  const [visibleStep, setVisibleStep] = useState(skipStep1 ? 2 : 1);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (reportData) {
      setFormData(reportData);
    }
    
    if (report_type) {
      setVisibleStep(2); 
      setSelectedReport(report_type);
      setPanel2Visible(true);
      setExpanded('panel2');
    }
  }, [reportData, report_type]);

  // Selecting a report type
  const handleSelectReport = (reportType) => {
    setSelectedReport(reportType);
    setPanel2Visible(true);
    setExpanded('panel2');
  };

  // Reports and forms mapping
  const reportForms = {
    PERFORMANCE_GLOBAL: <GlobalReportForm formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_GOOGLE_FBL: <GoogleFBL formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_METASEARCH: <Metasearch formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_PMAX: <PerformancePmax formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    SP_FUNNEL_TRIPADVISOR: <SpFunnelTripAdvisor formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    PRICE_COMPETITIVENESS: <PriceCompetitiveness formData={formData} onClose={onClose}  isEdit= {isEdit} />,
    PERFORMANCE_MARKET_PMAX: <PerformanceMarketPmax formData={formData} onClose={onClose} isEdit= {isEdit} />,
    BILLING: <Billing formData={formData}   onClose={onClose} isEdit= {isEdit} />,
    RESERVATIONS: <Reservations formData={formData}  onClose={onClose} isEdit= {isEdit} />,
    PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY: <PerformanceGoogleMissedOpportunity formData={formData}  onClose={onClose} isEdit= {isEdit} />,
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose} PaperProps={{ style: { width: "65%", background: "#F2F2F2" } }}>
      <Box sx={{ p: 4 }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose(); 
          }}
          sx={{ position: 'absolute', color: 'black' }}
        >
          <CloseIcon sx={{ color: 'black', borderRadius: '50%', border: "1px solid black", zoom: '1.4' }} />
        </IconButton>
        <Typography variant="h4" component="h2" sx={{ mb: 2, paddingLeft: 9 }}> New Report - Astohotel </Typography>
          <Accordion className="customAccordion"
            expanded={expanded === 'panel1'}
            disabled={panel2Visible}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Select Report Type</Typography>
            </AccordionSummary>
            <AccordionDetails  sx={{ background: "#F2F2F2"}} >
              <Grid container spacing={4}>
                <Grid item xs={6} md={9}>
                  <Grid item xs={6} md={12}>
                    <Typography variant="subtitle1">Performance Report:</Typography>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={3} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_GLOBAL')} > Global </Button>
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('SP_FUNNEL_TRIPADVISOR')} > Sp Funnel Tripadvisor </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_MARKET_PMAX')} > Google PMax -market </Button>
                    </Grid>
                    <Grid item xs={5} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_METASEARCH')} > Metasearch </Button>
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_GOOGLE_FBL')} > Google FBL </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY')} > Google Missed Opportunity </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PRICE_COMPETITIVENESS')} > Price Competitiveness </Button>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('PERFORMANCE_PMAX')} > Google PMax </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={12}>
                      <Typography variant="subtitle1">Billing Report:</Typography>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('BILLING')} > Global </Button>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Typography variant="subtitle1">Reservation Report:</Typography>
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <Button className="reportButton" fullWidth onClick={() => handleSelectReport('RESERVATIONS')} > Global</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>  

       {panel2Visible && (
          <Accordion className="report-accordion-container" expanded={expanded === 'panel2'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{selectedReport ? selectedReport : 'Performance Report'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {selectedReport ? reportForms[selectedReport] : <Typography>Select a report to see the form</Typography>}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </Drawer>
  );
};

export default SlidingPanel;