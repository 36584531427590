import { Grid } from "@mui/material";
import React, { useMemo } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { FilledCircleIcon } from "../../assets/images/Icons";
import { formatCash } from "../../utils/functions/common";

const colorMapper = {
  "#12794F": "#61D5A9",
  "#EA9196": "#FEBDB6",
  "#46508D": "#CACDFE",
  "#4470C1": "#84B8FD",
};
const symbolMapper = {
  cost: "€",
  spent: "€",
  generated: "€",
  cpc: "€",
  clicks: "",
  revenue_perclick: "€",
  bookingcount: "",
  conversion: "%",
  roi: "NET",
};
const BiAxialLineChart = ({ data, colors }) => {
  const lines = useMemo(() => {
    if (!data?.[0]) {
      return [];
    }
    return Object.keys(data?.[0])?.filter((line) => line !== "name") ?? [];
  }, [data]);
  const originalLines = lines.filter((line) => !line.startsWith("compare"));
  const compareLines = lines.filter((line) => line.startsWith("compare"));
  const CustomTooltip = ({ payload }) => {
    console.log(payload, "PL");

    const originalLines = payload.filter(
      (line) => !line.dataKey.startsWith("compare")
    );
    const compareLines = payload.filter((line) =>
      line.dataKey.startsWith("compare")
    );

    console.log(originalLines, lines, "Lines");

    return (
      <div
        style={{
          border: "1px solid #61D5A9",
          borderRadius: "10px",
          paddingInline: "5px",
          paddingBlock: "10px",
          boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
          width: "fit-content",
          backgroundColor: "#fff",
        }}
      >
        <Grid container xs={12}>
          <div
            style={{
              display: "flex",
              fontSize: "10px",
              fontWeight: 700,
              color: "#9D9D9D",
            }}
          >
            <div style={{ width: "130px" }}>&nbsp;</div>
            <div style={{ width: "70px", textAlign: "end" }}>
              {originalLines[0]?.payload.name}
            </div>
            <div
              style={{
                width: compareLines?.length === 0 ? "0px" : "70px",
                textAlign: "end",
                marginLeft: "20px",
              }}
            >
              {compareLines?.length > 0 ? compareLines[0]?.payload.name : ""}
            </div>
          </div>
        </Grid>
        {originalLines?.map((ol) => {
          return (
            <div
              style={{
                fontSize: "10px",
                color: "#9D9D9D",
                fontWeight: 400,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textTransform: "capitalize",
                  width: "130px",
                }}
              >
                {" "}
                <span style={{ marginTop: "4px", marginRight: "5px" }}>
                  <FilledCircleIcon color={ol.stroke} />
                </span>
                <span>
                  {ol.dataKey?.split("_").slice(1).join(" ")}{" "}
                  {symbolMapper[ol.dataKey]
                    ? `(${symbolMapper[ol.dataKey]})`
                    : ""}{" "}
                </span>
              </div>
              <div style={{ width: "70px", textAlign: "end" }}>{ol.value}</div>

              <div
                style={{
                  width: compareLines?.length === 0 ? "0px" : "70px",
                  textAlign: "end",
                  marginLeft: "20px",
                }}
              >
                {compareLines?.find((cl) => {
                  const l = cl.dataKey?.split("compare")[1];
                  if (
                    originalLines?.find((ol) => ol.dataKey === l) &&
                    l === ol.dataKey
                  ) {
                    return true;
                  }
                })?.value ?? (
                  <span
                    style={{
                      width: compareLines?.length === 0 ? "0px" : "70px",
                      textAlign: "end",
                    }}
                  >
                    &nbsp;
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const getLeftMargin = () => {
    return originalLines?.length <= 2 ? -20 : 30;
  };
  const getRightMargin = () => {
    return originalLines?.length === 2 ? -20 : 30;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={"100%"}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: getRightMargin(),
          left: getLeftMargin(),
          bottom: 5,
        }}
      >
        {lines?.length <= 2 && <CartesianGrid vertical={false} />}
        <XAxis
          interval={0}
          style={{
            marginTop: "10px",
            fontSize: "8px",
            fontWeight: 400,
            fontFamily: "inter !important",
            color: "#C4C4C4",
          }}
          stroke="#C4C4C4"
          dataKey="name"
          tickLine={{ style: { transform: "rotateX(15deg)" } }}
        />
        {originalLines?.length <= 2 && (
          <YAxis
            style={{
              marginTop: "10px",
              width: "fit-content",
              fontSize: "8px",
              fontFamily: "inter !important",
              color: "#C4C4C4",
              fontWeight: 400,
            }}
            tickFormatter={formatCash}
            tickLine={false}
            axisLine={false}
            yAxisId="left"
            tick={{ fill: "#C4C4C4" }}
          />
        )}
        {originalLines?.length === 2 && (
          <YAxis
            axisLine={false}
            style={{
              marginTop: "10px",
              fontSize: "8px",
              fontFamily: "inter !important",
              color: "#C4C4C4 !important",
              fontWeight: 400,
            }}
            tickFormatter={formatCash}
            tickLine={false}
            tick={{ fill: "#C4C4C4" }}
            yAxisId="right"
            orientation="right"
          />
        )}
        <Tooltip content={<CustomTooltip />} />

        {originalLines.map((line, index) => {
          return (
            <Line
              yAxisId={index === 0 ? "left" : index === 1 ? "right" : ""}
              type="linear"
              dataKey={line}
              stroke={colors[index]}
              strokeWidth={2}
              dot={false}
            />
          );
        })}
        {compareLines.map((line, index) => {
          const l = line.split("compare")[1];
          console.log(l, "linee");
          const originalLineIndex = originalLines?.findIndex((ol) => ol === l);
          return (
            <Line
              yAxisId={
                originalLineIndex === 0
                  ? "left"
                  : originalLineIndex === 1
                  ? "right"
                  : ""
              }
              type="linear"
              dataKey={line}
              stroke={colorMapper[colors[originalLineIndex]]}
              dot={false}
              strokeDasharray={"3 3"}
              strokeWidth={2}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
export default BiAxialLineChart;
