// src/components/Utils/UngroupSelectionModal.jsx
import React from 'react';
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import './Modal.scss';

const UngroupSelectionModal = ({ open, onClose, onConfirm, loading, error }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Ungroup Hotels</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to ungroup the selected hotels? This will remove them from the group and reset their group association.
                </DialogContentText>

                {/* Error message in red, if any */}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </DialogContent>
            <DialogActions>
                {/* Show loader instead of buttons while loading */}
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Button onClick={onClose} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={onConfirm} color="primary">
                            Ungroup
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UngroupSelectionModal;
