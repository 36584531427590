import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./SingleSelect.scss";
export default function SingleSelect({
  value = undefined,
  options,
  onChange,
  sx,
  placeholder,
  valueKey = "value",
  labelKey = "label",
}) {
  console.log("option", options);

  return (
    <FormControl size="small">
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        placeholder={placeholder}
        value={value}
        displayempty="true"
        renderValue={(value) => {
          if (!value) {
            return (
              <span
                style={{
                  fontSize: "12px",
                  color: "#bab7b7",
                  marginLeft: "8px",
                }}
              >
                {placeholder}
              </span>
            );
          }
          return (
            <span
              style={{
                fontSize: "14px",
                marginLeft: "8px",
              }}
            >
              {options.find((opt) => opt[valueKey] === value)?.[labelKey]}
            </span>
          );
        }}
        sx={{
          border: "1px solid #C4C4C4",
          backgroundColor: "white",
          borderRadius: "4px",
          "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "3px 0 3px",
          },
          ...sx,
        }}
        onChange={(e) => {
          console.log(e);
          onChange(e.target.value);
        }}
      >
        <MenuItem value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options?.length
          ? options.map((option) => {
              return (
                <MenuItem value={option[valueKey]}>{option[labelKey]}</MenuItem>
              );
            })
          : []}
      </Select>
    </FormControl>
  );
}
