export const REPORT_TYPE_LABELS = {
  PERFORMANCE_GLOBAL: 'Performance Global',
  RESERVATION: 'Reservation',
  BILLING: 'Billing',
  PERFORMANCE_GOOGLE_FBL:'Google FBL',
  PERFORMANCE_METASEARCH:'Metasearch',
  PERFORMANCE_PMAX:'',
  SP_FUNNEL_TRIPADVISOR:'SP Funnel TripAdvisor',
  PRICE_COMPETITIVENESS:'Price Cometitiveness',
  PERFORMANCE_MARKET_PMAX:'Google PMax',
  PERFORMANCE_GOOGLE_MISSED_OPPORTUNITY:'Google Missed Opportunity',
};

export const DELIVERY_FREQUENCY_LABELS = {
  ONCE:'Once',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
};

export const STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PENDING: 'Paused',
  COMPLETED: 'Completed',
  RUNNING:'Active'
};
