import React from "react";
import { Box, Typography, TextField, Grid } from "@mui/material";
import {MONTHS} from "../../../constants/months";

const MonthlyBudgetCap = ({ monthlyBudget, setMonthlyBudget, targetValue, setTargetValue, errors }) => {
    const handleMonthlyBudgetChange = (month, value) => {
        if (/^\d*$/.test(value)) {
            setMonthlyBudget((prev) => ({
                ...prev,
                [month]: value,
            }));
        }
    };

    const handleTargetValueChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value)) {
            setTargetValue(value);
        }
    };

    return (
        <Box className="monthlyBudgetCap">
            <Box display="flex" alignItems="center">
                <Typography className="sectionTitle">Monthly Budget Cap</Typography>
                <Typography variant="body2" color="textSecondary">
                    Minimum budget required: €1700
                </Typography>
            </Box>
            <Box p={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2} display="flex" justifyContent="center">
                        <TextField
                            error={errors.targetValueError}
                            className="no-border-focus"
                            sx={{
                                margin: "-15px 15px 0 15px",
                                '& .MuiOutlinedInput-root': {
                                    borderColor: errors.targetValueError ? 'red !important' : 'inherit'
                                },
                            }}
                            placeholder="Enter a target value"
                            variant="outlined"
                            value={targetValue}
                            onChange={handleTargetValueChange}
                            type="number"
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <Box display="flex" flexWrap="wrap" gap={2} className="monthlyBudgetInputs">
                            {MONTHS.map((month) => (
                                <Box
                                    className="no-border-focus"
                                    key={month}
                                    display="flex"
                                    alignItems="center"
                                    gap={1}
                                    width="calc(16.66% - 16px)"
                                    mb={2}
                                >
                                    <Typography>{month}</Typography>
                                    <TextField
                                        variant="outlined"
                                        value={monthlyBudget?.[month] || ""}
                                        type="number"
                                        onChange={(e) => handleMonthlyBudgetChange(month, e.target.value)}
                                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                        size="small"
                                        sx={{
                                            backgroundColor: 'white'
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default MonthlyBudgetCap;
