import React, { useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { ReactComponent as IconSearch } from "../../assets/images/IconSearch.svg";

const ResSearch = ({ onSearch,resetPage }) => {
    const [nameHotel, setNameHotel] = useState('');
    const [codeResa, setCodeResa] = useState('');

    const handleSearchByNameHotel = (event) => {
        const value = event.target.value;
        setNameHotel(value);
        onSearch({nameHotel: value, codeResa});
        resetPage(0);
    };

    const handleSearchByCodeResa = (event) => {
        const value = event.target.value;
        setCodeResa(value);
        onSearch({ nameHotel, codeResa: value });
        resetPage(0);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '14px',
                height:'20px',
            }}
        >
            <TextField
                placeholder="Search by Name"
                variant="outlined"
                autoComplete="off"
                value={nameHotel} // Use nameSearch state
                onChange={handleSearchByNameHotel}
                size="small"
                style={{ flex: 1 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconSearch />
                        </InputAdornment>
                    ),
                    sx: {
                        bgcolor:"white",
                        borderRadius: '20px',
                        '& input': {
                            padding: '6px 0',
                            fontSize: '12px',
                            fontFamily:"inter",
                            fontWeight:'400'
                        },
                    },
                }}
            />

            <TextField
                placeholder="Search by Code"
                variant="outlined"
                autoComplete="off"
                value={codeResa} // Use codeResa state
                onChange={handleSearchByCodeResa}
                size="small"
                style={{ flex: 1 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconSearch />
                        </InputAdornment>
                    ),
                    sx: {
                        bgcolor:"white",
                        borderRadius: '20px',
                        '& input': {
                            padding: '6px 0',
                            fontSize: '12px',
                            fontFamily:"inter",
                            fontWeight:'400'
                        },
                    },
                }}
            />
        </Box>
    );
};

export default ResSearch;