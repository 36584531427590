import React, {useEffect, useState} from 'react';
import {Builder, Query, Utils as QbUtils} from '@react-awesome-query-builder/ui';
import {MuiConfig} from '@react-awesome-query-builder/mui';
import TextField from '@mui/material/TextField';
import Button from 'components/Button';
import { sqlToJsonLogic } from '../../Functions/index';
import "./form.scss";
import Tags from "@yaireo/tagify/dist/react.tagify";
import CustomFormControl from '../../Dropdown/CustomFormControl';
import { useCallback, useRef } from 'react'
import {FormControl, MenuItem, Radio, FormControlLabel, RadioGroup, Typography} from '@mui/material';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import trashcanIcon from '../../../../assets/images/BiddingCenter/trashcan.svg';
import Icons from "components/DmpIcons/icons";
import {useParams} from "react-router-dom";
import LessIcon from '../../../../assets/images/BiddingCenter/lessIcon.svg';
import {APP_BIDDING_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";

const BiddingRuleTripadvisor = ({ biddingRuleData, onClose, actionType }) => {
    const [biddingRuleName, setBiddingRuleName] = useState(biddingRuleData?.bidding_rule_name || '');
    const [biddingRuleShortName, setBiddingRuleShortName] = useState(biddingRuleData?.bidding_rule_shortname || []);
    const [metricComparison, setMetricComparison] = useState(biddingRuleData?.metrics_compare || '');
    const [biddingFrequencyType, setBiddingFrequencyType] = useState(biddingRuleData?.bidding_frequency_type || 'DAILY');
    const [biddingFrequencyDaily, setBiddingFrequencyDaily] = useState(biddingRuleData?.bidding_frequency_daily || '');
    const [biddingFrequencyWeek, setBiddingFrequencyWeek] = useState(biddingRuleData?.bidding_frequency_week || []);
    const [rulesTriggered, setRulesTriggered] = useState(biddingRuleData?.bid_change_type || null);
    const [rulesNotTriggered, setRulesNotTriggered] = useState(biddingRuleData?.bid_change_type_inv || null);
    const [alertRecipients, setAlertRecipients] = useState(biddingRuleData?.alert_recipient || []);
    const [bidMax, setBidMax] = useState(biddingRuleData?.bid_max || "");
    const [bidMin, setBidMin] = useState(biddingRuleData?.bid_min || "");
    const [bidChange, setBidChange] = useState(biddingRuleData?.bid_change ?? "");
    const [bidChangeInv, setBidChangeInv] = useState(biddingRuleData?.bid_change_inv ?? "");
    const [alertNotif, setAlertNotif] = useState(biddingRuleData.alert_notif || 0);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [tree, setTree] = useState(null);

    const { idaccount } = useParams();
    const apiEndpoint=`${APP_BIDDING_CENTER_API_ENDPOINT}/api/tripadvisor-metrics-bidding-rules`;
    let apiActionEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/tripadvisor-add-bidding-rules`;
    const apiListTemplatesEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/list-template/1`;
    let method = 'POST';
    const jwtToken = sessionStorage.getItem('token');

    if(actionType === 'edit'){
        apiActionEndpoint = `${APP_BIDDING_CENTER_API_ENDPOINT}/api/tripadvisor-edit-bidding-rules`;
        method = 'PUT';
    }

    const tagifyRef = useRef(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [valueFromToLines, setValueFromToLines] = useState([]);
    const [valueFromToLinesNotTriggered, setValueFromToLinesNotTriggered] = useState([]);


    const rulesDropdownValue = [
        { title: "Increased Bid by %", abbr: "Incr by %", value: 0 },
        { title: "Increased Bid by fixed value", abbr: "Incr fixed value", value: 2 },
        { title: "Decreased Bid by %", abbr: "Decr by %", value: 1 },
        { title: "Decreased Bid by fixed value", abbr: "Decr by fixed value", value: 3 },
    ];

    const circleStyle = {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        border: '0.5px solid #BAB7B7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
    };

    const daysOfWeek = [
        { title: 'Monday', abbr: 'Mo', value: 1 },
        { title: 'Tuesday', abbr: 'Tu', value: 2 },
        { title: 'Wednesday', abbr: 'We', value: 3 },
        { title: 'Thursday', abbr: 'Th', value: 4 },
        { title: 'Friday', abbr: 'Fr', value: 5 },
        { title: 'Saturday', abbr: 'Sa', value: 6 },
        { title: 'Sunday', abbr: 'Su', value: 7 },
    ];
    const metricComparisonOptions = Array.from({ length: 10 }, (_, i) => ({ title: `${i + 1}` }));


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setBiddingFrequencyWeek(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {

        if (biddingRuleData) {
            console.log("Bidding Rule Data:", biddingRuleData);
            setBiddingRuleName(biddingRuleData.bidding_rule_name || '');
            setBiddingRuleShortName(biddingRuleData.bidding_rule_shortname || []);
            setMetricComparison(biddingRuleData.metrics_compare || '');
            if (biddingRuleData.bidding_frequency_type === "0") {
                setBiddingFrequencyType("DAILY");
                setBiddingFrequencyDaily(biddingRuleData.bidding_frequency_daily.toString() || '');
                setBiddingFrequencyWeek([]); // Réinitialiser la fréquence hebdomadaire si type est DAILY
            } else if (biddingRuleData.bidding_frequency_type === "1") {
                setBiddingFrequencyType("DAY_OF_WEEK");
                setBiddingFrequencyDaily(''); // Réinitialiser la fréquence journalière si type est DAY_OF_WEEK
                const weekDays = biddingRuleData.bidding_frequency_week[0]
                    .split(',')
                    .map(Number);
                setBiddingFrequencyWeek(weekDays);
            }
            setRulesTriggered(biddingRuleData.bid_change_type ?? null);
            setRulesNotTriggered(biddingRuleData.bid_change_type_inv ?? null);

            const recipients = biddingRuleData.alert_recipient || [];
            const splitRecipients = recipients.flatMap(recipient => recipient.split(',').map(email => email.trim()));
            setAlertRecipients(splitRecipients);
            setBidMax(biddingRuleData.bid_max || "");
            setBidMin(biddingRuleData.bid_min || "");
            setBidChange(biddingRuleData.bid_change ?? "");
            setBidChangeInv(biddingRuleData.bid_change_inv ?? "");
            setAlertNotif(biddingRuleData.alert_notif || 0);

            if (biddingRuleData.steps_rule_triggered) {
                const updatedLinesTriggered = biddingRuleData.steps_rule_triggered.map((step, index) => ({
                    id: index + 1,
                    valueToApply: step.value_to_apply,
                    stepStart: step.step_start,
                    stepEnd: step.step_end,
                }));
                setValueFromToLines(updatedLinesTriggered);
            }

            // Update valueFromToLinesNotTriggered
            if (biddingRuleData.steps_rule_not_triggered) {
                const updatedLinesNotTriggered = biddingRuleData.steps_rule_not_triggered.map((step, index) => ({
                    id: index + 1,
                    valueToApply: step.value_to_apply,
                    stepStart: step.step_start,
                    stepEnd: step.step_end,
                }));
                setValueFromToLinesNotTriggered(updatedLinesNotTriggered);
            }
        }
    }, [biddingRuleData]);


    const isGroupSelected = (ruleValue, groupValues) => {
        return groupValues.includes(ruleValue);
    };

    const availableRules = (selectedRule, otherRule) => {
        const increaseRulesValues = [0, 2]; // Increased by %, Increased by fixed value
        const decreaseRulesValues = [1, 3]; // Decreased by %, Decreased by fixed value

        if (isGroupSelected(otherRule, increaseRulesValues)) {
            return rulesDropdownValue.filter((rule) =>
                decreaseRulesValues.includes(rule.value)
            );
        }

        if (isGroupSelected(otherRule, decreaseRulesValues)) {
            return rulesDropdownValue.filter((rule) =>
                increaseRulesValues.includes(rule.value)
            );
        }

        return rulesDropdownValue;
    };

    const availableRulesTriggered = availableRules(rulesTriggered, rulesNotTriggered);
    const availableRulesNotTriggered = availableRules(rulesNotTriggered, rulesTriggered);

    const addLine = () => {
        setValueFromToLines([...valueFromToLines, { id: valueFromToLines.length + 1, valueToApply: '', stepStart: '', stepEnd: '' }]);
    };

    const removeLine = (id) => {
        setValueFromToLines(valueFromToLines.filter(line => line.id !== id));
    };

    const addLineNotTriggered = () => {
        setValueFromToLinesNotTriggered([...valueFromToLinesNotTriggered, { id: valueFromToLinesNotTriggered.length + 1, valueToApply: '', stepStart: '', stepEnd: '' }]);
    };

    const removeLineNotTriggered = (id) => {
        setValueFromToLinesNotTriggered(valueFromToLinesNotTriggered.filter(line => line.id !== id));
    };

    const handleValueFromToLineChangeNotTriggered = (index, field, value) => {
        const updatedLines = [...valueFromToLinesNotTriggered];
        updatedLines[index][field] = value;
        setValueFromToLinesNotTriggered(updatedLines);
    };

    const handleValueFromToLineChange = (index, field, value) => {
        const updatedLines = [...valueFromToLines];
        updatedLines[index][field] = value;
        setValueFromToLines(updatedLines);
    };

    const dailyFrequencyOptions = Array.from({ length: 10 }, (_, i) => ({
        title: `${i + 1}`,
        abbr: `${i + 1}`,
    }));


    const [config, setConfig] = useState({
        ...MuiConfig,
        settings: {
            ...MuiConfig.settings,
            renderIcon: (icon) => {
                console.log("Icons:", icon); // Accéder à la propriété `type` de l'objet `icon`

                switch (icon.type) {
                    case 'addRule':
                        return (
                            <div style={circleStyle}>
                                <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Rule" style={{ width: '8px', height: '8px' }} />
                            </div>
                        );
                    case 'addGroup':
                        return (
                            <div style={circleStyle}>
                                <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Add Rule" style={{ width: '8px', height: '8px' }} />
                            </div>
                        );
                    case 'delGroup':
                        return <img src={trashcanIcon} alt="Delete Group" style={{width: '30px', height: '30px'}}/>;
                    case 'delRule':
                        return <img src={trashcanIcon} alt="Delete Rule" style={{width: '30px', height: '30px'}}/>;
                    default:
                        return <span>{icon.type}</span>;
                }
            },

        },
    });

    const loadQueryBuilderFromSQL = (sqlRule) => {
        const tree = QbUtils.loadFromJsonLogic(sqlToJsonLogic(sqlRule), config);
        setTree(tree);
    }

    const handleBiddingFrequencyTypeChange = (e) => {
        const value = e.target.value;
        setBiddingFrequencyType(value);
        // Reset the other field's state
        setBiddingFrequencyDaily('');
        setBiddingFrequencyWeek([]);
    };

    useEffect(() => {
        console.log("biddingRuleData : " + JSON.stringify(biddingRuleData))
        if (biddingRuleData.rules && config.fields && Object.keys(config.fields).length > 0) {
            const generatedTree = QbUtils.loadFromJsonLogic(sqlToJsonLogic(biddingRuleData.rules), config);
            setTree(generatedTree);
        } else {
            console.log("tree initialization")
            const emptyTree = QbUtils.checkTree(QbUtils.loadTree({id: QbUtils.uuid(), type: 'group'}), config)
            console.log("emptyTree : " + emptyTree)
            setTree(emptyTree); // Initialiser un arbre vide pour un ajout
        }
    }, [biddingRuleData, config]);

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const response = await fetch(apiEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                const fields = generateFieldsFromMetrics(result);
                console.log('Metrics:', JSON.stringify(result.label));
                setConfig(prevConfig => ({
                    ...config,
                    fields: fields,
                    settings: {
                        ...prevConfig.settings,
                        showNot: false,
                        canReorder: false,
                        groupOperators: ['AND', 'OR'],
                    },
                }));

            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchMetrics();
    }, [apiEndpoint, jwtToken]);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch(apiListTemplatesEndpoint, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = await response.json();
                const templatesData = data.map(template => ({
                    name: template.template_name,
                    rule: template.rules_sql
                }));
                setTemplates(templatesData);
                console.log('Templates and Rules:', templatesData);

            } catch (error) {
                console.error('Error fetching metrics:', error);
            }
        };

        fetchTemplates();
    }, [apiEndpoint, jwtToken]);


    const generateFieldsFromMetrics = (metrics) => {
        const fields = {};
        metrics.forEach((metric) => {
            fields[metric.id] = {
                label: metric.label,
                type: 'number',
                fieldSettings: {
                    min: 0,
                },
                valueSources: ['value'],
                preferWidgets: ['number'],
                operators: metric.operators,
                widgets: {
                    number: {
                        operators: metric.operators,
                    }
                }
            };
        });
        console.log('Fields:', fields);
        return fields;
    };

    const handleRecipientsChange = useCallback((e) => {
        let parsedValue;
        try {
            parsedValue = JSON.parse(e.detail.value); // Parser la chaîne JSON
        } catch (error) {
            console.error("Parsing error:", error);
            parsedValue = [];
        }

        const updatedRecipients = parsedValue.map(tag => tag.value.split(',').map(email => email.trim())).flat();
        setAlertRecipients(updatedRecipients);
    }, []);

    const submitTripadvisorBiddingRule = (requestBody) => {
        console.log('Alert data: ', JSON.stringify(requestBody));
        fetch(apiActionEndpoint, {
            method: method,
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        })
            .then((response) => {
                if (!response.ok) { // Check if the response is not OK (status code outside 200-299)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((result) => {
                setSnackbarMessage('Success!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Error saving alert:', error); // Log the error for debugging
                setSnackbarMessage('Error saving alert: ' + error.message);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSubmit = async () => {
        console.log("submit triggered")
        const sqlFormat = QbUtils.sqlFormat(tree, config);

        const stepsRuleTriggered = valueFromToLines.map(line => ({
            step_start: line.stepStart,
            step_end: line.stepEnd,
            value_to_apply: line.valueToApply,
        }));

        const stepsRulesNotTriggered = valueFromToLinesNotTriggered.map(line => ({
            step_start: line.stepStart,
            step_end: line.stepEnd,
            value_to_apply: line.valueToApply,
        }));

        if(biddingFrequencyType==="DAY_OF_WEEK"){
            setBiddingFrequencyDaily(1);
        }
        if(biddingFrequencyType==="DAILY"){
            setBiddingFrequencyWeek([]);
        }

        const body = {
            id_account: idaccount,
            status: "ENABLED",
            bidding_rule_name: biddingRuleName,
            bidding_rule_shortname: biddingRuleShortName,
            alert_notif: alertNotif,
            alert_recipient: alertRecipients,
            bidding_frequency_type: biddingFrequencyType,
            bidding_frequency_daily: biddingFrequencyDaily,
            bidding_frequency_week: biddingFrequencyWeek,
            bid_max: bidMax,
            bid_min: bidMin,
            max_bid: 1,
            max_bid_inv: 1,
            bid_change: bidChange,
            bid_change_type: rulesTriggered,
            bid_change_inv: bidChangeInv,
            bid_change_type_inv: rulesNotTriggered,
            rules: sqlFormat,
            metrics_compare: metricComparison,
            steps_rule_triggered: stepsRuleTriggered,
            steps_rule_not_triggered: stepsRulesNotTriggered,
        };

        if(actionType==="edit"){
            body.id = biddingRuleData.id;
        }

        console.log(body)
        if(!body.bidding_rule_name || !body.bidding_rule_shortname || body.alert_recipient.length === 0 || !body.metrics_compare){
            setSnackbarMessage("Please fill all required fields (*)");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            console.log("Please fill all required fields (*)");
        }else {
            console.log('Bidding Rule data : ', JSON.stringify(body));
            submitTripadvisorBiddingRule(body);
        }
    };


    const onChange = (immutableTree, config) => {
        setTree(immutableTree);
        console.log("Updated Tree:", JSON.stringify(QbUtils.getTree(immutableTree), null, 2));
    };

    useEffect(() => {
        console.log("Bidding Rule Data:", {
            biddingRuleName,
            biddingRuleShortName,
            metricComparison,
            biddingFrequencyType,
            biddingFrequencyDaily,
            biddingFrequencyWeek,
            rulesTriggered,
            rulesNotTriggered,
            alertRecipients,
            bidChange,
            bidChangeInv,
            bidMin,
            bidMax,
            alertNotif
        });
    }, [biddingRuleName, biddingRuleShortName, rulesTriggered, rulesNotTriggered, biddingFrequencyType, bidChange, bidChangeInv, biddingFrequencyDaily, biddingFrequencyWeek, metricComparison, alertRecipients, bidMin, bidMax, alertNotif]);

    const handleTemplatesChange = (event, value) => {
        setSelectedTemplate(value);
        if (value) {
            const sqlRule = value.rule;
            loadQueryBuilderFromSQL(sqlRule);
        }
    };

    return (
        <div className="bidding-rule-form">
            {/* Snackbar */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {/* Bidding Rule Name and Short Name */}
            <div className="bidding-rule-name-container">
                <div className="bidding-rule-full-name">
                    <div>
                        <label >BIDDING RULE NAME</label>
                    </div>
                    <TextField
                        placeholder="Rule Name:"
                        variant="standard"
                        value={biddingRuleName}
                        required
                        onChange={(e) => setBiddingRuleName(e.target.value)}
                        sx={{ '& ::placeholder': { fontSize: '12px' } }}
                    />
                </div>
                <div className="bidding-rule-short-name">
                    <div>
                        <label >BIDDING RULE SHORT NAME</label>
                    </div>
                    <TextField
                        placeholder="Short Name:"
                        variant="standard"
                        value={biddingRuleShortName}
                        required
                        onChange={(e) => setBiddingRuleShortName(e.target.value)}
                        sx={{ '& ::placeholder': { fontSize: '12px' } }}
                    />
                </div>
            </div>

            {/* Metric Analysis and Bidding Frequency */}
            <div className="bidding-rule-metric-analysis-container">
                <div className="bidding-frequency-container">
                    <div className="metric-analysis">
                        <Typography sx={{
                            fontSize: "10px",
                            fontWeight: 400,
                            fontFamily: "Inter, Serif",
                            lineHeight: "12.1px",
                        }}>
                            METRIC ANALYSIS
                        </Typography>

                        <div className="bidding-auto-complete">
                            {/* Utilisation de CustomFormControl */}
                            <CustomFormControl
                                value={metricComparison || ''}
                                onChange={(event) => setMetricComparison(event.target.value)}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return (
                                            <span style={{
                                                fontSize: "small",
                                                color: "#bab7b7",
                                                marginLeft: "4px"
                                            }}># of days</span>
                                        );
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem disabled value="">
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}># of days</span>
                                </MenuItem>
                                {metricComparisonOptions.map((option) => (
                                    <MenuItem key={option.title} value={option.title} sx={{ fontSize: '0.75rem' }}>
                                        {option.title}
                                    </MenuItem>
                                ))}
                            </CustomFormControl>
                        </div>
                    </div>

                    <div className="bidding-frequency-checkboxes">
                        <div className="bidding-frequency-label">
                            <label>BIDDING FREQUENCY</label>
                        </div>
                        <div className="bidding-frequency-checkboxes-container">
                            <FormControl component="fieldset">
                                <RadioGroup
                                    name="biddingFrequencyType"
                                    value={biddingFrequencyType}
                                    onChange={handleBiddingFrequencyTypeChange}
                                >
                                    <FormControlLabel
                                        value="DAILY"
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 12,
                                                    },
                                                    padding: '0px',
                                                    margin: '0px',
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '12px', lineHeight: '1' }}
                                            >
                                                Daily frequency
                                            </Typography>
                                        }
                                        sx={{
                                            margin: '0px',
                                            padding: '0px',
                                            height: '24px',
                                        }}
                                    />
                                    <FormControlLabel
                                        value="DAY_OF_WEEK"
                                        control={
                                            <Radio
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 12,
                                                    },
                                                    padding: '0px',
                                                    margin: '0px',
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant="body2"
                                                sx={{ fontSize: '12px', lineHeight: '1' }}
                                            >
                                                Day of week
                                            </Typography>
                                        }
                                        sx={{
                                            margin: '0px',
                                            padding: '0px',
                                            height: '24px',
                                        }}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>

                    <div className="frequency-fields">
                        {biddingFrequencyType === 'DAY_OF_WEEK' && (
                            <div className="frequency-field">
                                {/* Utilisation de CustomFormControl pour les jours de la semaine */}
                                <CustomFormControl
                                    multiple
                                    value={biddingFrequencyWeek}
                                    onChange={handleChange}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <span style={{
                                                    fontSize: "small",
                                                    color: "#bab7b7",
                                                    marginLeft: "4px"
                                                }}>days</span>
                                            );
                                        }
                                        return selected
                                            .map((value) => {
                                                const day = daysOfWeek.find((day) => day.value === value);
                                                return day ? day.abbr : value;
                                            })
                                            .join(", ");
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{
                                            fontSize: "0.75rem",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>days</span>
                                    </MenuItem>
                                    {daysOfWeek.map((day) => (
                                        <MenuItem key={day.value} value={day.value} sx={{ fontSize: '0.75rem' }}>
                                            {day.title}
                                        </MenuItem>
                                    ))}
                                </CustomFormControl>
                            </div>
                        )}

                        {biddingFrequencyType === 'DAILY' && (
                            <div className="frequency-field">
                                {/* Utilisation de CustomFormControl pour la fréquence quotidienne */}
                                <CustomFormControl
                                    value={biddingFrequencyDaily || ''}
                                    onChange={(event) => setBiddingFrequencyDaily(event.target.value)}
                                    displayEmpty
                                    renderValue={(selected) => {
                                        if (!selected) {
                                            return (
                                                <span style={{
                                                    fontSize: "small",
                                                    color: "#bab7b7",
                                                    marginLeft: "4px"
                                                }}>Select Frequency</span>
                                            );
                                        }
                                        return selected;
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>Select Frequency</span>
                                    </MenuItem>
                                    {dailyFrequencyOptions.map((option) => (
                                        <MenuItem key={option.title} value={option.title} sx={{ fontSize: '0.75rem' }}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </CustomFormControl>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Bidding Rules Triggered */}
            <div className="bidding-rules-triggered-container">
                <label>RULES TRIGGERED</label>
                <div className="bidding-rules-triggered-container-fields">
                    <div className="bidding-auto-complete">
                        {/* Utilisation de CustomFormControl */}
                        <CustomFormControl
                            value={rulesTriggered !== null ? rulesTriggered : ''}
                            onChange={(event) => setRulesTriggered(event.target.value)}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === '') {
                                    return (
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>Select Rule</span>
                                    );
                                }
                                const selectedOption = availableRulesTriggered.find(option => option.value === selected);
                                return selectedOption ? selectedOption.title : '';
                            }}
                        >
                            <MenuItem disabled value="">
                                <span style={{
                                    fontSize: "small",
                                    color: "#bab7b7",
                                    marginLeft: "4px"
                                }}>Select Rule</span>
                            </MenuItem>
                            {availableRulesTriggered.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '0.75rem' }}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                    </div>

                    {/* ... (le reste du code pour les champs de valeur) */}
                    <div className="value-selector-container">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <TextField
                                placeholder="VALUE TO APPLY"
                                variant="standard"
                                value={bidChange ?? ''}
                                onChange={(e) => setBidChange(e.target.value)}
                                sx={{
                                    width: '145px',
                                    '& ::placeholder': { fontSize: '12px' }
                                }}
                                required
                            />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <button onClick={addLine} title={"When bid is between"}>
                                    <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Remove" style={{ width: '16px', height: '16px' }} />
                                </button>
                                {valueFromToLines.length > 0 && (
                                    <button onClick={() => removeLine(valueFromToLines[valueFromToLines.length - 1].id)}>
                                        <img src={LessIcon} alt="Remove" style={{ width: '16px', height: '16px' }} />
                                    </button>
                                )}
                            </div>
                        </div>
                        {valueFromToLines.map((line, index) => (
                            <div className="value-selector" key={line.id}>
                                <TextField
                                    placeholder="VALUE"
                                    variant="standard"
                                    value={line.valueToApply || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'valueToApply', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                                <TextField
                                    placeholder="FROM"
                                    variant="standard"
                                    value={line.stepStart || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'stepStart', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                                <TextField
                                    placeholder="TO"
                                    variant="standard"
                                    value={line.stepEnd || ''}
                                    onChange={(e) => handleValueFromToLineChange(index, 'stepEnd', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Bidding Rules Not Triggered */}
            <div className="bidding-rules-not-triggered-container">
                <label>RULES NOT TRIGGERED</label>
                <div className="bidding-rules-triggered-container-fields">
                    <div className="bidding-auto-complete">
                        {/* Utilisation de CustomFormControl */}
                        <CustomFormControl
                            value={rulesNotTriggered !== null ? rulesNotTriggered : ''}
                            onChange={(event) => setRulesNotTriggered(event.target.value)}
                            displayEmpty
                            renderValue={(selected) => {
                                if (selected === '') {
                                    return (
                                        <span style={{
                                            fontSize: "small",
                                            color: "#bab7b7",
                                            marginLeft: "4px"
                                        }}>Select Rule</span>
                                    );
                                }
                                const selectedOption = availableRulesNotTriggered.find(option => option.value === selected);
                                return selectedOption ? selectedOption.title : '';
                            }}
                        >
                            <MenuItem disabled value="">
                                <span style={{
                                    fontSize: "small",
                                    color: "#bab7b7",
                                    marginLeft: "4px"
                                }}>Select Rule</span>
                            </MenuItem>
                            {availableRulesNotTriggered.map((option) => (
                                <MenuItem key={option.value} value={option.value} sx={{ fontSize: '0.75rem' }}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </CustomFormControl>
                    </div>

                    {/* ... (le reste du code pour les champs de valeur) */}
                    <div className="value-selector-container">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                            <TextField
                                placeholder="VALUE TO APPLY"
                                variant="standard"
                                value={bidChangeInv ?? ''}
                                onChange={(e) => setBidChangeInv(e.target.value)}
                                sx={{
                                    width: '145px',
                                    '& ::placeholder': { fontSize: '12px' }
                                }}
                                required
                            />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <button onClick={addLineNotTriggered} title={"When bid is between"}>
                                    <img src={Icons.ActionsIconsSVG.AddIconSVG} alt="Remove" style={{ width: '16px', height: '16px' }} />
                                </button>
                                {valueFromToLinesNotTriggered.length > 0 && (
                                    <button onClick={() => removeLineNotTriggered(valueFromToLinesNotTriggered[valueFromToLinesNotTriggered.length - 1].id)}>
                                        <img src={LessIcon} alt="Remove" style={{ width: '16px', height: '16px' }} />
                                    </button>
                                )}
                            </div>
                        </div>
                        {valueFromToLinesNotTriggered.map((line, index) => (
                            <div className="value-selector" key={line.id}>
                                <TextField
                                    placeholder="VALUE"
                                    variant="standard"
                                    value={line.valueToApply || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'valueToApply', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                                <TextField
                                    placeholder="FROM"
                                    variant="standard"
                                    value={line.stepStart || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'stepStart', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                                <TextField
                                    placeholder="TO"
                                    variant="standard"
                                    value={line.stepEnd || ''}
                                    onChange={(e) => handleValueFromToLineChangeNotTriggered(index, 'stepEnd', e.target.value)}
                                    sx={{ '& ::placeholder': { fontSize: '12px' } }}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Min and Max Values */}
            <div className="value-container">
                <div className="value-row">
                    <div>
                        <label>MIN BID VALUE</label>
                    </div>
                    <TextField
                        placeholder="MINIMUM BID VALUE"
                        variant="standard"
                        value={bidMin}
                        required
                        sx={{ '& ::placeholder': { fontSize: '12px' } }}
                        onChange={(e) => setBidMin(e.target.value)}
                    />
                </div>
                <div className="value-row">
                    <div>
                        <label>MAX BID VALUE </label>
                    </div>
                    <TextField
                        placeholder="MAXIMUM BID VALUE"
                        variant="standard"
                        value={bidMax}
                        required
                        sx={{ '& ::placeholder': { fontSize: '12px' } }}
                        onChange={(e) => setBidMax(e.target.value)}
                    />
                </div>
            </div>

            {/* Import Bidding Rules */}
            <div className="import-bidding-rule-container">
                <label>IMPORT BIDDING RULES</label>
                <div className="bidding-auto-complete">
                    {/* Utilisation de CustomFormControl */}
                    <CustomFormControl
                        value={selectedTemplate ? selectedTemplate.name : ''}
                        onChange={(event) => {
                            const newValue = templates.find(template => template.name === event.target.value);
                            handleTemplatesChange(null, newValue);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <span style={{
                                        fontSize: "small",
                                        color: "#bab7b7",
                                        marginLeft: "4px"
                                    }}>Bidding Rule</span>
                                );
                            }
                            return selected;
                        }}
                    >
                        <MenuItem disabled value="">
                            <span style={{
                                fontSize: "small",
                                color: "#bab7b7",
                                marginLeft: "4px"
                            }}>Bidding Rule</span>
                        </MenuItem>
                        {templates.map((option) => (
                            <MenuItem key={option.name} value={option.name} sx={{ fontSize: '0.75rem' }}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </CustomFormControl>
                </div>
            </div>

            {/* Query Builder */}
            <div className="query-builder-container">
                <label>METRIC ALERT RULES</label>
                <div className="query-builder">
                    {tree && (
                        <Query
                            {...config}
                            value={tree || null}
                            onChange={onChange}
                            renderBuilder={(props) => <Builder {...props} />}
                        />
                    )}
                </div>
            </div>

            <div className="email-container">
                <label>RECIPIENTS</label>
                <div className="email-container-fields">
                    <Tags
                        ref={tagifyRef}
                        whitelist={alertRecipients}
                        placeholder='Recipients'
                        settings={{
                            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        }}
                        onChange={handleRecipientsChange}
                        defaultValue={Array.isArray(alertRecipients) ? alertRecipients.join(',') : ""}
                    />

                    <div className="email-checkbox-container">
                        <input
                            type="checkbox"
                            value=""
                            checked={alertNotif === 1}
                            onChange={(e) => setAlertNotif(e.target.checked ? 1 : 0)}
                        />
                        <label className="email-checkbox-label" >Send me an email when this bidding rule triggers</label>
                    </div>

                </div>

            </div>

            {/* Buttons */}
            <div className="button-container">
                <Button
                    title="Save Rule"
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                        background: "#12794F",
                        color: "white",
                        borderRadius: "12px",
                        height: "24px"
                    }}
                />
                <Button title="Cancel"
                        variant="contained"
                        onClick={onClose}
                        sx={{
                            background: "#BAB7B7",
                            color: "black",
                            borderRadius: "12px",
                            height: "24px"
                        }}
                />
            </div>
        </div>
    );
};

export default BiddingRuleTripadvisor;
