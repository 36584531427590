import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ColumnsChangeIcon from "assets/images/ColumnsChangeIcon.svg";
import { useRef, useState } from "react";
const SimpleTable = ({ rows, columns, maxHeight }) => {
  const tableContainerRef = useRef();
  const stickyDivRef = useRef();
  const [segmentSelection, setSegmentSelection] = useState([]);
  const table = useMaterialReactTable({
    columns: columns,
    data: rows,
    getSubRows: (row) => row.subRows,
    initialState: {
      expanded: true,

      density: "compact",
    },
    muiTableBodyProps: () => {
      return {
        sx: {
          '& .MuiTableRow-root td[data-pinned="true"]:before': {
            boxShadow: "unset",
            opacity: 1,
          },
          "& .MuiTableRow-root td:after": {
            backgroundColor: "white",
          },
        },
      };
    },

    muiTableHeadCellProps: {
      sx: {
        textAlign: "center",
        paddingBlock: "10px",
        backgroundColor: "#EBEBEB",

        "&:hover": {
          "& .MuiTableSortLabel-icon": {
            opacity: "1 !important",
          },
        },
        "& .Mui-TableHeadCell-Content": {
          justifyContent: "flex-end",
        },
        "& .Mui-TableHeadCell-Content-Labels": {
          flexDirection: "row-reverse",

          display: "inline-flex",
          width: "max-width",
        },
        "& .Mui-TableHeadCell-Content-Wrapper": {
          fontSize: "12px",
          fontWeight: "500",
          fontFamily: "inter",
          color: "#545454",
          display: "inline-block",
          whiteSpace: "nowrap",
          lineHeight: "15px",
        },
        "& .MuiTableSortLabel-icon": {
          flex: 1,
          //  opacity: "0 !important",
        },
      },
    },

    muiTableBodyCellProps: ({ cell }) => {
      return {
        sx: {
          fontSize: cell.row.depth === 0 ? "11px" : "10px",
          fontFamily: "inter",
          fontWeight: cell.row.depth === 0 ? "700" : "400",
          color: "#545454",
          textAlign: "end",
        },
      };
    },

    muiTableHeadProps: {
      ref: stickyDivRef,
      sx: {
        backgroundColor: "#EBEBEB",

        '& .MuiTableCell-root[data-pinned="true"]:before': {
          boxShadow: "unset",
          opacity: 1,
          backgroundColor: "#EBEBEB",
        },
      },
    },

    muiTableBodyRowProps: ({ row }) => {
      return {
        hover: false,
      };
    },
    muiExpandAllButtonProps: {
      sx: {
        display: "none",
      },
    },
    muiTopToolbarProps: {
      sx: {
        alignItems: "center",
        "& .MuiBox-root": {
          paddingBlock: "0px !important",
        },
      },
    },
    enableTopToolbar: false,
    muiBottomToolbarProps: {
      sx: {
        display: "none",
      },
    },

    enableColumnActions: false,
    getRowId: (row) => row.id,
    enableExpanding: true,
    enableExpandAll: true,
    positionExpandColumn: "last",
    enableColumnPinning: true,
    enableRowPinning: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilters: false,
    enableGlobalFilter: false,
    enableStickyHeader: true,
    rowPinningDisplayMode: "top-and-bottom",

    icons: {
      ExpandMoreIcon: () => (
        <ArrowDropDownIcon
          sx={{
            fontSize: 20,
            paddingInline: "0px",
            margin: "0px",
            alignItems: "center",
          }}
        />
      ),
      ViewColumnIcon: () => (
        <div
          style={{ display: "flex", flexDirection: "column", marginRight: 30 }}
        >
          {" "}
          <span style={{ fontSize: "10px", color: "black" }}>Columns</span>{" "}
          <img style={{ maxHeight: "27px" }} src={ColumnsChangeIcon} alt="" />{" "}
        </div>
      ),
    },
    defaultColumn: { maxSize: 60 },
    displayColumnDefOptions: {
      "mrt-row-pin": {
        muiTableHeadCellProps: {
          sx: {
            display: "none",
          },
        },
        muiTableBodyCellProps: {
          sx: {
            display: "none",
          },
        },
      },
      "mrt-row-expand": {
        maxSize: 15,
        muiTableBodyCellProps: ({ cell }) => {
          console.log(cell.row.depth, cell.row.id);

          return {
            sx: {
              '& td[data-pinned="true"]:before': {
                //  backgroundColor: cell.row.depth === 0 ? "#f5f5f5" : "#ffff",
              },
              "& button": {
                padding: "4px",
                display:
                  cell.row.depth !== 0 || cell.row.id === "0" ? "none" : "none",
              },
            },
          };
        },
      },
    },

    enableTableFooter: false,
    enablePagination: false,
    muiTableContainerProps: {
      sx: {
        width: "100%",
        height: "100%",
        maxHeight: maxHeight,

        "&::-webkit-scrollbar": {
          width: "3px",
          height: "3px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },

        "&::-webkit-scrollbar-thumb": {
          background: "#acabab",
          borderRadius: "4px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
          height: "10px",
        },
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
      },
    },
  });
  const handleSegmentDelete = (id) => {
    const tempData = [...segmentSelection];
    const index = tempData.findIndex((i) => i.id === id);
    tempData.splice(index, 1);
    if (index !== -1) {
      setSegmentSelection(tempData);
    }
  };
  return (
    <>
      <div ref={tableContainerRef}>
        <MaterialReactTable table={table} />
      </div>
    </>
  );
};
export default SimpleTable;
