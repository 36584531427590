import React, { useState } from 'react';
import './AlertTitle.scss';
import AlertSelectionForm from '../AlertSelectionForm';
import CustomButtonWithIcon from "../../../components/CustomButtonWithIcon/Buttons";
import DmpIcons from "components/DmpIcons/icons";
import PageTitles from "../../../components/PageTitles";

const AlertTitle = () => {
    const [isAlertFormVisible, setIsAlertFormVisible] = useState(false);

    const handleToggleAlertSelectionForm = () => {
        setIsAlertFormVisible((prev) => !prev);
    };

    const handleHideAlertForm = () => {
        setIsAlertFormVisible(false);
    };

    return (
        <div className="header-container">
            <div className="header">
                <PageTitles pageTitle="Metasearch" pageSubTitle="Alert Center" />
                <div className="right-section">
                    <div className="date-button-container">
                        <div className="new-alert-button">
                            <CustomButtonWithIcon
                                color="#b0acac"
                                hoverColor="#61D5A9"
                                width="120px"
                                height="30px"
                                titleColor="#373F41"
                                borderRadius="20px"
                                title="Click to create a new alert"
                                icon={DmpIcons.ActionsIcons.AddIconBlack}
                                iconBorder="0.5px solid #373F41"
                                textStyle={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '14.52px',
                                    textAlign: 'center',
                                }}
                                onClick={() => {
                                    handleToggleAlertSelectionForm();
                                }}
                            >
                                New Alert
                            </CustomButtonWithIcon>
                        </div>
                    </div>
                </div>
            </div>
            <AlertSelectionForm
                isOpen={isAlertFormVisible}
                onClose={handleToggleAlertSelectionForm}
                onAlertTypeSelect={handleHideAlertForm}
            />
        </div>
    );
};

export default AlertTitle;
