import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, IconButton, Grid } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';

const SiteLinkExtensionSection = ({ title, items, addItem, handleInputChange, handleRemove }) => {
    const [isAdded, setIsAdded] = useState(false);

    useEffect(() => {
        setIsAdded(items.length > 0);
    }, [items.length]);

    const handleAddClick = () => {
        addItem();
    };

    return (
        <Grid item md={6} xs={6} spacing={2}>
            <Box className={title.toLowerCase().replace(/ /g, "-")} mt={4}>
                <Box display="flex" alignItems="center" justifyContent="space-between" className="header-box">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                        {isAdded && (
                            <IconButton onClick={addItem} color="primary" className="add-button">
                                <AddIcon />
                            </IconButton>
                        )}
                        <IconButton className="outlined-info-button">
                            <InfoIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box className="content-box" mt={2}>
                    {!isAdded ? (
                        <Box display="flex" alignItems="center" gap={0} onClick={handleAddClick} style={{ cursor: 'pointer' }}>
                            <IconButton className="add-button-text">
                                <AddIcon fontSize="small" />
                            </IconButton>
                            <Typography style={{ textTransform: 'uppercase', color: '#61D5A9', fontSize: '14px', fontWeight: 500 }}>
                                Add {title}
                            </Typography>
                        </Box>
                    ) : (
                        items.map((item, index) => (
                            <Box key={item.id} mt={2} position="relative" style={{ margin: "15px" }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="subtitle2">SITE LINK {index + 1}</Typography>
                                    <IconButton onClick={() => handleRemove(item.id)} color="primary" className="remove-button">
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>

                                <Box display="flex" flexDirection="column" gap={2} mt={1}>
                                    <TextField
                                        style={{ marginLeft: "15px" }}
                                        label="Site Link Text"
                                        className="no-border-focus"
                                        variant="outlined"
                                        value={item.sitelinkText}
                                        onChange={(e) => handleInputChange(item.id, 'sitelinkText', e.target.value)}
                                        fullWidth
                                    />
                                    <TextField
                                        style={{ marginLeft: "15px" }}
                                        label="Description 1"
                                        className="no-border-focus"
                                        variant="outlined"
                                        value={item.description1}
                                        onChange={(e) => handleInputChange(item.id, 'description1', e.target.value)}
                                        fullWidth
                                    />
                                    <TextField
                                        style={{ marginLeft: "15px" }}
                                        label="Description 2"
                                        className="no-border-focus"
                                        variant="outlined"
                                        value={item.description2}
                                        onChange={(e) => handleInputChange(item.id, 'description2', e.target.value)}
                                        fullWidth
                                    />
                                    <TextField
                                        style={{ marginLeft: "15px" }}
                                        label="Final URL"
                                        className="no-border-focus"
                                        variant="outlined"
                                        value={item.finalURL}
                                        onChange={(e) => handleInputChange(item.id, 'finalURL', e.target.value)}
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        ))
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default SiteLinkExtensionSection;
