export const trivagoMultiplierFormFields = [
  {
    id: "multiplier",
    label: "Multiplier",
    type: "select",
    placeholder: "-- SELECT A MULTIPLIER",
    options: [
      {
        id: "deactive multiplier",
        label: "Deactive Multiplier",
        value: "NO_MULTIPLIERS",
      },
      {
        id: "active booster",
        label: "Active Booster",
        value: "BOOSTER",
      },
    ],
  },
];
