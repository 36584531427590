import { FormControl, Select } from '@mui/material';
import '@react-awesome-query-builder/ui/css/styles.css';
import '@react-awesome-query-builder/ui/css/compact_styles.css';

const CustomFormControl = ({ value, onChange, displayEmpty, renderValue, multiple, children, ...props }) => {
    return (
        <FormControl sx={{ minWidth: '180px' }} size="small" {...props}>
            <Select
                value={value}
                onChange={onChange}
                displayEmpty={displayEmpty}
                renderValue={renderValue}
                multiple={multiple}
                sx={{
                    backgroundColor: "white",
                    '& .MuiSelect-icon': {
                        color: '#BAB7B7',
                    },
                    "& .MuiSelect-select": {
                        padding: "3px 2px 3px 2px",
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#C4C4C4',
                    },

                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#61D5A9',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#61D5A9',
                    },
                }}
            >
                {children}
            </Select>
        </FormControl>
    );
};

export default CustomFormControl;
