  import React, { useState, useEffect } from 'react';
  import { Grid, TextField, Select, MenuItem, Button, Typography, Checkbox,Snackbar,Alert,  FormControl, FormHelperText,  Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, ListItemText  } from '@mui/material';
  import { validateReportForm } from '../../validation/reportValidation';
  import { sanitizeString } from '../../../../utils/functions/sanitize';
  import SettingsIcon from '@mui/icons-material/Settings';
  import { fetchService } from '../../Services/fetchService';
  import ERROR_MESSAGES from '../../Constant/errorMessages';
  import TagifyInput from '../../TagifyInput';
  import {APP_REPORT_CENTER_API_ENDPOINT} from "../../../../constants/api_urls";
  import { useParams } from "react-router-dom";
  import '../../reportCenter.scss'

  const SpFunnelTripAdvisor = ({ formData,  setShowForm, onClose, isEdit= false }) => {

    const [snackbarOpen, setSnackbarOpen] = useState(false);  
    const [snackbarMessage, setSnackbarMessage] = useState('');  
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [timezones, setTimezones] = useState([]);
    const [ftp, setftp] = useState([]);
    const [whiteLabelEmail, setWhiteLabelEmail] = useState([]); 
    const [errors, setErrors] = useState({});
    const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    const [subjectTags, setSubjectTags] = useState('');
    const token = sessionStorage.getItem('token');
    const { idaccount } = useParams();

    const [reportData, setReportData] = useState({
      name: formData?.name || "",
      report_id: formData?.report_id || "",
      account: idaccount,
      date_range: formData?.date_range || "",
      period_length: formData?.period_length || "",
      delivery_frequency: formData?.delivery_frequency || "",
      day_month: formData?.day_month || "",
      day : formData?.day || [],
      time_of_day: formData?.time_of_day || "",
      time_zone: formData?.time_zone || "",
      export_method: formData?.export_method || "",
      delevery_method_ftp:formData?.delevery_method_ftp || "",
      folder: formData?.folder || "",
      recipients :formData?.recipients || [],
      subject: formData?.subject || "",
      export_name: formData?.export_name || "",
      export_file_type: formData?.export_file_type || "",
      attached_file: formData?.attached_file || "",
      whitelabel_email : formData?.whitelabel_email || "",
    });

    const handleCreateReport = async () => {
      const sanitizedData = {
        report_id: reportData.id,
        account: parseInt(idaccount, 10),
        name: sanitizeString(reportData.name),
        date_range: reportData.date_range,
        delivery_frequency: reportData.delivery_frequency,
        period_length:  reportData.period_length,
        custom_start_date:reportData.custom_start_date,
        custom_end_date: reportData.custom_end_date,
        day_month: reportData.day_month,
        day : reportData.day,
        time_of_day: reportData.time_of_day,
        time_zone: reportData.time_zone,
        export_method: reportData.export_method,
        delevery_method_ftp:reportData.delevery_method_ftp,
        folder: reportData.folder,
        recipients :reportData.recipients,
        subject: reportData.subject,
        export_name: reportData.export_name,
        export_file_type: reportData.export_file_type,
        attached_file: reportData.attached_file,
        whitelabel_email : reportData.whitelabel_email, 
      };
     
      const validationErrors = validateReportForm(sanitizedData);
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);  
      }

      try {
        const API_URL = isEdit ? APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/edit" : APP_REPORT_CENTER_API_ENDPOINT+"/api/reports/sp-funnel-trip-advisor/add";
        const API_METHOD = isEdit ? "PATCH" : "POST";
        const API_RESPONSE = isEdit ? "RESOURCE_UPDATED" : "RESOURCE_CREATED";
        const API_MESSAGE = isEdit ? "updated" : "created";
        

        const response = await fetch(API_URL, {
          method: API_METHOD,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sanitizedData),
        });

        const data = await response.json();
        if (data.status_code === 200 && data.data.message === API_RESPONSE) {
          setSnackbarSeverity('success');
          setSnackbarMessage(`Report ${API_MESSAGE} successfully! Report ID: ${data.data.report_id}`);
          setSnackbarOpen(true);
        } else if (data.error && data.error.length > 0) {
          const fieldErrors = {};
          data.error.forEach((err) => {
            const translatedMessage = ERROR_MESSAGES[err.message] || err.message;
            if(err.property_path) {
              fieldErrors[err.property_path] = translatedMessage;
              if (err.property_path === 'level_choices') {
                fieldErrors['level'] = translatedMessage;
              }
            } else {
              fieldErrors["unspecified"] = translatedMessage;
            }
              
          });
          setErrors(fieldErrors);
          setSnackbarOpen(false);
        }
      } catch (error) {
        console.error('Error creating report:', error);
        setSnackbarSeverity('error');
        setSnackbarMessage('Something went wrong while creating the report.');
        setSnackbarOpen(true);
      }
    };

    useEffect(() => {
      const handleError = (errMessage) => {
        setErrors(errMessage);
      };
    
      const fetchTimezones = async () => {
        try {
          const response = await fetchService(`${APP_REPORT_CENTER_API_ENDPOINT}/api/timezone/list`);
          if (response.success) {
            setTimezones(response.data);
          } else {
            handleError('Failed to load timezones');
          }
        } catch (err) {
          handleError('Failed to load timezones');
        }
      };
    
      const fetchFtp = async () => {
        const url = `${APP_REPORT_CENTER_API_ENDPOINT}/api/ftp/list?account=${idaccount}`
        try {
          const response = await fetchService(url);
          if (response.success) {
            setftp(response.data);
          } else {
            handleError('Failed to load FTP data');
          }
        } catch (err) {
          handleError('Failed to load FTP data');
        }
      };

      const fetchWhiteLabelEmail = async () => {
        const url = `${APP_REPORT_CENTER_API_ENDPOINT}/api/whitelable-email/list?account=${idaccount}`;
        try {
          const response = await fetchService(url);
          if (response.success) {
            setWhiteLabelEmail(response.data);
          } else {
            handleError('Failed to load Hotels data');
          }
        } catch (err) {
          handleError('Failed to load Hotels data');
        }
      };
    
      fetchTimezones();
      fetchFtp();
      fetchWhiteLabelEmail();
    }, []);

    useEffect(() => {
      setReportData({
        ...reportData,
        ...formData,
      });
    }, [formData]);

    useEffect(() => {
      if (!reportData.level) {
        setReportData((prevData) => ({ ...prevData, level: 'ACCOUNT' }));  
      }
      if (!reportData.export_method) {
        setReportData((prevData) => ({ ...prevData, export_method: "EMAIL" })); 
      }
      if (!reportData.export_file_type) {
        setReportData((prevData) => ({ ...prevData, export_file_type: "CSV" })); 
      }
      if (!reportData.attached_file) {
        setReportData((prevData) => ({ ...prevData, attached_file: "ZIP_COMPRESSION" })); 
      }
      if (!reportData.delivery_frequency) {
        setReportData((prevData) => ({ ...prevData, delivery_frequency: "ONCE" })); 
      }
      
    }, [reportData.level, setReportData]);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setReportData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    
    };


    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

   const handleToggleSettings = () => {
      setShowAdditionalSettings(!showAdditionalSettings);
    };

    useEffect(() => {
      if (isEdit && reportData.subject) {
        setSubjectTags(reportData.subject);
      }
    }, [isEdit, reportData]);

    const handleTagsChange = (newTagsString) => {
      setSubjectTags(newTagsString);
  
      setReportData(prevData => ({
        ...prevData,
        subject: newTagsString,
      }));
    };

    return (
      <div className="report-form-container">
        <Grid item xs={12}>
        <Grid item xs={10} container spacing={4}>
          <Grid item xs={12}>
            <Grid item xs={8}>
              <TextField
                  fullWidth
                  name="name"
                  className="form-input"
                  placeholder='Report Name:'
                  value={reportData.name}
                  onChange={handleInputChange}
                  error={!!errors.name} 
                  helperText={errors.name || ''}
                />
              </Grid>
          </Grid>
        
          <Grid container spacing={2} item xs={12}>
          <Grid item xs={4}>
            <FormControl fullWidth error={!!errors.date_range}>
              <Typography variant="body2">Date Range</Typography>
              <Select
                value={reportData.date_range}
                name="date_range"
                className="form-select"
                onChange={(e) => {
                  handleInputChange(e);
                  setReportData({ ...reportData, date_range: e.target.value });
                }}
              >
                <MenuItem value="CURRENT_MONTH">Current Month</MenuItem>
                <MenuItem value="LAST_MONTH">Last Month</MenuItem>
                <MenuItem value="LAST_7_DAYS">Last 7 Days</MenuItem>
                <MenuItem value="ONE_DAY">One Day</MenuItem>
                <MenuItem value="CURRENT_YEAR">Current Year</MenuItem>
                <MenuItem value="LAST_YEAR">Last Year</MenuItem>
                <MenuItem value="CUSTOM">Custom</MenuItem>
              </Select>
              {errors.date_range && <FormHelperText>{errors.date_range}</FormHelperText>}
            </FormControl>
          </Grid>
          {reportData.date_range === 'CUSTOM' && ( 
            <>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Typography variant="body2">Date from</Typography>
                  <input
                    variant="body1"
                    className="form-select"
                    type="date"
                    value={reportData.custom_start_date || ''}
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, custom_start_date: e.target.value });
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Typography variant="body2">Date to</Typography>
                  <input
                    variant="body1"
                    className="form-select"
                    type="date"
                    value={reportData.custom_end_date || ''}
                    onChange={(e) => {
                      handleInputChange(e);
                      setReportData({ ...reportData, custom_end_date: e.target.value });
                    }}
                  />
                </FormControl>
              </Grid>
            </>
          )}
            {reportData.date_range === 'ONE_DAY' && (
              <>
              <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Typography variant="body2">Day</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.period_length || ""}
                      onChange={(e) => setReportData({ ...reportData, period_length: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select Day
                      </MenuItem>
                      {Array.from({ length: 31 }, (_, index) => (
                        <MenuItem key={index + 1} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
          {/* Row 2 */}
          <Grid item xs={4} container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.export_method}>
                <Typography variant="body2">Choose Delivery Method</Typography>
                <Select
                  fullWidth
                  value={reportData.export_method}
                  name="export_method"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_method: e.target.value });
                  }}
                >
                  <MenuItem value="EMAIL">Email</MenuItem>
                  <MenuItem value="FTP">FTP</MenuItem>
                </Select>
                {errors.export_method && <FormHelperText>{errors.export_method}</FormHelperText>}
              </FormControl>
            </Grid>
            {reportData.export_method === 'FTP' && (
                <>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <Typography variant="body2">Choose FTP</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.delevery_method_ftp || ''}
                      onChange={(e) => setReportData({ ...reportData, delevery_method_ftp: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select FTP
                      </MenuItem>
                      {ftp.length > 0 ? (
                        ftp.map((ftp) => (
                          <MenuItem key={ftp.id} value={ftp.id}>
                            {ftp.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No ftp Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2">Folder</Typography>
                      <TextField
                          name="folder"
                          fullWidth
                          className="form-input"
                          value={reportData.folder || ''}
                          onChange={(e) => setReportData({ ...reportData, folder: String(e.target.value) })}
                          displayempty="true"
                        />
                    </FormControl>
                  </Grid>
                </>
              )}
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.delivery_frequency}>
                <Typography variant="body2">Delivery Frequency</Typography>
                <Select
                  fullWidth
                  value={reportData.delivery_frequency}
                  name="delivery_frequency"
                  className="form-select"
                 onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, delivery_frequency: e.target.value });
                  }}
                  error={!!errors.delivery_frequency}
                >
                  <MenuItem value=""><em>Select Frequency</em></MenuItem>
                  <MenuItem value="ONCE">Once</MenuItem>
                  <MenuItem value="DAILY">Daily</MenuItem>
                  <MenuItem value="WEEKLY">Weekly</MenuItem>
                  <MenuItem value="MONTHLY">Monthly</MenuItem>
                </Select>
                {errors.delivery_frequency && <FormHelperText>{errors.delivery_frequency}</FormHelperText>}
              </FormControl>
            </Grid>
            {reportData.delivery_frequency === 'DAILY' && (
                <>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Typography variant="body2">Time of Day</Typography>
                      <Select
                        fullWidth
                        className="form-select"
                        value={reportData.time_of_day || ''}
                        onChange={(e) => setReportData({ ...reportData, time_of_day: String(e.target.value) })}
                        displayempty="true"
                      >
                        <MenuItem value="" disabled>
                          Select Day
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                          <MenuItem key="{index + 1}" value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                    <Typography variant="body2">Time zone</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.time_zone || ''}
                      onChange={(e) => setReportData({ ...reportData, time_zone: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select Time Zone
                      </MenuItem>
                      {timezones.length > 0 ? (
                        timezones.map((timezone) => (
                          <MenuItem key={timezone.id} value={timezone.id}>
                            {timezone.label}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No Timezones Available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  </Grid>
                </>
              )}
              {reportData.delivery_frequency === 'WEEKLY' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="body2">Day of Week</Typography>
                    <Select
                      multiple
                      fullWidth
                      className="form-select"
                      value={reportData.day || []}
                      onChange={(e) =>
                        setReportData({ ...reportData, day: e.target.value })
                      }
                      displayempty="true"
                      renderValue={(selected) => selected.join(', ')}
                    >
                      <MenuItem value="" disabled>
                        Select Days
                      </MenuItem>
                      {['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THRUSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'].map((day) => (
                        <MenuItem key={day} value={day}>
                          <Checkbox checked={reportData.day?.indexOf(day) > -1} />
                          <ListItemText primary={day} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {reportData.delivery_frequency === 'MONTHLY' && (
                  <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Typography variant="body2">Day of Month</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.day_month || ''}
                      onChange={(e) => setReportData({ ...reportData, day_month: String(e.target.value) })}
                      displayempty="true"
                    >
                      <MenuItem value="" disabled>
                        Select Day
                      </MenuItem>
                      {Array.from({ length: 31 }, (_, index) => (
                        <MenuItem key="{index + 1}" value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>

          {/* Row 3 */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid item xs={8}>
              <FormControl fullWidth error={!!errors.recipients}>
                <Typography variant="body2">Recipients</Typography>
                <TextField
                  name="recipients"
                  fullWidth
                  placeholder="Recipients:"
                  className="form-input"
                  value={Array.isArray(reportData.recipients) ? reportData.recipients.join(', ') : ''}
                  onChange={(e) => {
                    const emailString = e.target.value;
                    const emailArray = emailString
                      .split(',')
                      .map((email) => email.trim())
                      .filter((email) => email.length > 0);

                    handleInputChange(e);
                    setReportData({ ...reportData, recipients: emailArray });
                  }}
                  
                />
                {errors.recipients && <FormHelperText>{errors.recipients}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Subject */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid  item xs={8}>
              <FormControl fullWidth error={!!errors.subject}>
                <Typography variant="body2">Subject</Typography>
                  <TagifyInput
                    value={subjectTags} 
                    onChange={handleTagsChange}
                    settings={{
                      placeholder: 'Add tags...',
                      delimiters: ', ',
                      whitelist: ['TYPE_REPORT', 'ACCOUNT', 'REPORT_NAME'],
                    }}
                  />
                {errors.subject && <FormHelperText>{errors.subject}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
                
          {/* Export File name */}
          <Grid item xs={12} sm={12} md={12}>
            <Grid  item xs={8}>
              <FormControl fullWidth error={!!errors.export_name}>
                <Typography variant="body2">Export File Name</Typography>
                <TextField
                  name="export_name"
                  fullWidth
                  placeholder="[TYPE_REPORT]-[ID_REPORT]-[ACCOUNT]-[DATETIME]"
                  className="form-input"
                  value={reportData.export_name}
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_name: e.target.value });
                  }}
                />
                {errors.export_name && <FormHelperText>{errors.export_name}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>

           {/* Export File Type */}
           <Grid container item xs={12}>
            <Grid item xs={4}>
              <FormControl fullWidth error={!!errors.export_file_type}>
                <Typography variant="body2">Export File Type</Typography>
                <Select
                  fullWidth
                  value={reportData.export_file_type}
                  name="export_file_type"
                  className="form-select"
                  onChange={(e) => {
                    handleInputChange(e);
                    setReportData({ ...reportData, export_file_type: e.target.value });
                  }}
                  error={!!errors.export_file_type}
                >
                  <MenuItem value="CSV">CSV</MenuItem>
                  <MenuItem value="XLSV">Excel</MenuItem>
                </Select>
                {errors.export_file_type && (
                  <FormHelperText>{errors.export_file_type}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>  
  
          <Grid container sx={{ alignItems: 'flex-end' }}  item xs={12}>
              {showAdditionalSettings && (
              <>
                <Grid item xs={4}>
                  <FormControl fullWidth error={!!errors.attached_file}>
                    <Typography variant="body2">Attached File</Typography>
                    <Select
                      fullWidth
                      value={reportData.attached_file}
                      name="attached_file"
                      className="form-select"
                      onChange={(e) => {
                        handleInputChange(e);
                        setReportData({ ...reportData, attached_file: e.target.value });
                      }}
                      error={!!errors.attached_file}
                    >
                        <MenuItem value="ZIP_COMPRESSION">Zip file</MenuItem>
                        <MenuItem value="NO_COMPRESSION">Uncompressed file</MenuItem>
                        <MenuItem value="NO_ATTACHMENT">No attachment</MenuItem>
                    </Select>
                    {errors.attached_file && (
                      <FormHelperText>{errors.attached_file}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ marginLeft: '2rem' }}>
                  <FormControl fullWidth>
                    <Typography variant="body2">WhiteLabel Email</Typography>
                    <Select
                      fullWidth
                      className="form-select"
                      value={reportData.whitelabel_email || ''}
                      onChange={(e) => setReportData({ ...reportData, whitelabel_email: String(e.target.value) })}
                      displayempty="true"
                    >
                    <MenuItem value="" disabled>
                      None
                    </MenuItem>
                    {whiteLabelEmail.length > 0 ? (
                      whiteLabelEmail.map((whiteLabelEmail) => (
                        <MenuItem key={whiteLabelEmail.id} value={whiteLabelEmail.id}>
                          {whiteLabelEmail.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Whitelabel Email Available</MenuItem>
                    )}
                  </Select>
                  </FormControl>
                </Grid>
              </>
              )}
          </Grid>
          </Grid>
          <Grid className="report-form-footer-section">
          <Grid container  spacing={2} item xs={12}>
            <Grid item xs={3}>
              <Button fullWidth
                variant="contained"
                color="primary"
                onClick={handleCreateReport}
                sx={{ marginRight: 2 }}
              >
               {isEdit ? 'Update Report' : 'Create Report'}
              </Button>
            </Grid>
            <Grid item xs={2}> 
              <Button fullWidth variant="outlined" onClick={onClose}> Cancel </Button>
            </Grid>
            <Grid item xs={7} sx={{ justifyItems: 'flex-end' }}  >
              <Grid item xs={12}>
                <Button className="additional-settings" onClick={handleToggleSettings}  sx={{ marginRight: 2 }} >   <SettingsIcon   sx={{ marginRight: 2 }} /> Additional Settings</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <FormControl className="error-message" fullWidth error={!!errors.export_method}>
                {errors.unspecified && <FormHelperText>{errors.unspecified}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          {/* Snackbar for displaying success or error messages */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Grid>
        </Grid>
      </div>
    );
  };

  export default SpFunnelTripAdvisor;
