import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import './CampaignAccordion.scss';

const CampaignAccordion = ({
    title,
    children,
    expanded,
    onChange,
    onNext,
    disabled,
    visited,
    isCreateButton = false,
}) => {
    return (
        <Accordion
            className="search-ads-accordion"
            expanded={expanded}
            onChange={!disabled ? onChange : null}
            sx={{
                pointerEvents: disabled ? 'none' : 'auto',
                opacity: disabled ? 0.6 : 1
            }}
        >
            <AccordionSummary
                className="accordion-summary"
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    backgroundColor: visited && !expanded ? '#61D5A9' : 'inherit',
                    borderBottom: expanded ? '1px solid #61D5A9' : 'none',
                    cursor: disabled ? 'default' : 'pointer',
                    color: visited && !expanded ? 'black' : 'inherit',
                }}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails
                className="accordion-details"
                sx={{
                    backgroundColor: '#F2F2F2'
                }}
            >
                {children}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onNext}
                    className="nextButton"
                >
                    {isCreateButton ? "Create" : "Next"}
                </Button>
            </AccordionDetails>
        </Accordion>
    );
};

export default CampaignAccordion;
