export const HISTORY_COLUMN_DEFS = [
  {
    accessorKey: "count",
    header: <span> &nbsp; </span>,
    enableColumnActions: false,
    enableSorting: false,
    enableHiding: false,
    size: 40,
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "date",
    header: "Date",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "status",
    header: "Status",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "type",
    header: "Type",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "metaSearch",
    header: "Meta search",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "level",
    header: "Level",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
  {
    accessorKey: "download",
    header: "Download",
    Cell: ({ cell }) => <span>{cell.getValue()}</span>,
  },
];
